import {Log} from 'src/app/core/api-models/logs';
import {Address, Customer, Order} from 'src/app/core/api-models/order';
import {SearchParam, Transaction} from 'src/app/core/api-models/transaction';
import {
  AddTransactionItemParam, UpdateCustomizationItem, UpdateOrderProperty, UpdateTierInfo,
  UpdateTransactionItemParam,
} from 'src/app/core/api-models/transaction-item';
import {TransactionPayment} from 'src/app/core/api-models/transaction.payment';
import {
  ChangeTransactionParams,
  CreateCustomersParams, CreateNotificationInputParams,
  CreateNotificationParams, GetCustomerParams, GetProductInfoParams, GetSelectedOrderParams,
  NavigationTabId, NotificationInputParams,
  NotificationParams, SaveTransactionParams,
  SearchCustomersParams,
  SearchProductParams, SearchTransactionsParams, UpdateCustomerParams, UpdateSearchedOrderParams, UpdateTransactionToServerParams
} from 'src/app/core/models/common.model';
import {TabAction} from 'src/app/store/models/tab-action';
import {Product} from 'src/app/core/api-models/menu.model';

export enum SaleActionTypes {
  SelectMenu = '[Sale] Select Menu',
  UpdateTransaction = '[Sale] Update Transaction',
  UpdateTransactionOrder = '[Sale] Update Transaction Order',
  UpdateTransactionOrderSuccess = '[Sale] Update Transaction Order Success',
  UpdateTransactionOrderError = '[Sale] Update Transaction Order Error',
  UpdateServerTransactionOrder = '[Sale] Update Transaction Server Order',
  BackupTransactionOrder = '[Sale] Backup Transaction Order',
  RestoreTransactionOrder = '[Sale] Restore Transaction Order',

  UpdateOrderCustomerAddress = '[Sale] Update Order Customer Address',
  DeleteCustomerAddress = '[Sale] Delete Order Customer Address',
  RemoveOrderItem = '[Sale] Remove Order Item',
  AddCustomization = '[Sale] Add Customization',

  UpdateTransactionCustomization = '[Sale] Update Transaction Customization',
  BackupTransactionCustomization = '[Sale] Backup Transaction Customization',
  RestoreTransactionCustomization = '[Sale] Restore Transaction Customization',
  UpdateCustomizationTierInfo = '[Sale] Update Customization Tier Info',
  AddOrderTransaction = '[Sale] Add Order transaction',

  AddTransactionItem = '[Sale] Add Transaction Item',
  RemoveTransactionItem = '[Sale] Remove Transaction Item',
  UpdateTransactionItem = '[Sale] Update Transaction Item',

  AddLogToTransaction = '[Sale] Add Log to Transaction',
  AddTransactionPayment = '[Sale] Add Transaction Payment',

  SaveTransaction = '[Sale] Save Transaction',
  SaveTransactionSuccess = '[Sale] Save Transaction Success',
  SaveTransactionError = '[Sale] Save Transaction Error',

  AddPaymentToSelectedTransaction = '[Sale] Add Payment To Selected Transaction',

  UpdateTransactionToServer = '[Sale] Update Transaction To Server',
  UpdateTransactionToServerSuccess = '[Sale] Update Transaction Success To Server',
  UpdateTransactionToServerError = '[Sale] Update Transaction Error To Server',

  SearchProducts = '[Sale] Search Products',
  SearchProductsSuccess = '[Sale] Search Products Success',
  SearchProductsError = '[Sale] Search Products Error',

  SearchCustomers = '[Sale] Search Customers',
  SearchCustomersSuccess = '[Sale] Search Customers Success',
  SearchCustomersError = '[Sale] Search Customers Error',

  CreateCustomer = '[Sale] Create Customer',
  CreateCustomerSuccess = '[Sale] Create Customer Success',
  CreateCustomerError = '[Sale] Create Customer Error',

  GetProductInfo = '[Sale] Get Product Info',
  GetProductInfoSuccess = '[Sale] Get Product Info Success',
  GetProductInfoError = '[Sale] Get Product Info Error',

  UpdateCustomer = '[Sale] Update Customer',
  UpdateCustomerSuccess = '[Sale] Update Customer Success',
  UpdateCustomerError = '[Sale] Update Customer Error',

  GetCustomer = '[Sale] Get Customer Details',

  SearchTransactions = '[Sale] Search Transactions',
  SearchTransactionsSuccess = '[Sale] Search Transactions Success',
  SearchTransactionsError = '[Sale] Search Transactions Error',

  UpdateSearchedOrder = '[Sale] Update Searched Order',
  UpdateSearchedOrderSuccess = '[Sale] Update Searched Order Success',
  UpdateSearchedOrderError = '[Sale] Update Searched Order Error',
  UpdateSelectedOrder = '[Sale] Update Selected Order',

  UpdateSelectedOrderTransaction = '[Sale] Update Selected Order Transaction',

  GetSelectedOrder = '[Sale] Get Selected Order',
  GetSelectedOrderSuccess = '[Sale] Get Selected Order Success',
  GetSelectedOrderError = '[Sale] Get Selected Order Error',

  ChangeTransaction = '[Sale] Change Order',
  ChangeOrderSuccess = '[Sale] Change Order Success',
  ChangeOrderError = '[Sale] Change Order Error',

  SendTransactionNotification = '[Sale] Send Notification',
  SendTransactionNotificationSuccess = '[Sale] Send Notification Success',
  SendTransactionNotificationError = '[Sale] Send Notification Error',

  CreateTransactionNotification = '[Sale] Create Notification',
  CreateTransactionNotificationSuccess = '[Sale] Create Notification Success',
  CreateTransactionNotificationError = '[Sale] Create Notification Error',
}

// tslint:disable-next-line:no-namespace
export namespace SaleActions {

  export class SelectMenu extends TabAction<number> {
    readonly type = SaleActionTypes.SelectMenu;
  }

  export class UpdateTransaction extends TabAction<Partial<Transaction>> {
    readonly type = SaleActionTypes.UpdateTransaction;
  }

  export class UpdateTransactionOrder extends TabAction<Partial<Order>> {
    readonly type = SaleActionTypes.UpdateTransactionOrder;
  }

  export class UpdateTransactionOrderSuccess extends TabAction<Partial<Order>> {
    readonly type = SaleActionTypes.UpdateTransactionOrderSuccess;
  }

  export class UpdateTransactionOrderError extends TabAction<string> {
    readonly type = SaleActionTypes.UpdateTransactionOrderError;
  }

  export class UpdateServerTransactionOrder extends TabAction<UpdateSearchedOrderParams> {
    readonly type = SaleActionTypes.UpdateServerTransactionOrder;
  }

  export class BackupTransactionOrder extends TabAction<null> {
    readonly type = SaleActionTypes.BackupTransactionOrder;
  }

  export class RestoreTransactionOrder extends TabAction<null> {
    readonly type = SaleActionTypes.RestoreTransactionOrder;
  }

  export class UpdateOrderCustomerAddress extends TabAction<Address> {
    readonly type = SaleActionTypes.UpdateOrderCustomerAddress;
  }

  export class DeleteCustomerAddress extends TabAction<Address> {
    readonly type = SaleActionTypes.DeleteCustomerAddress;
  }

  export class RemoveOrderItem extends TabAction<string[]> {
    readonly type = SaleActionTypes.RemoveOrderItem;
  }

  export class AddTransactionItem extends TabAction<AddTransactionItemParam> {
    readonly type = SaleActionTypes.AddTransactionItem;
  }

  export class RemoveTransactionItem extends TabAction<string | number> {
    readonly type = SaleActionTypes.RemoveTransactionItem;
  }

  export class UpdateTransactionItem extends TabAction<UpdateTransactionItemParam> {
    readonly type = SaleActionTypes.UpdateTransactionItem;
  }

  export class AddLogToTransaction extends TabAction<Log[]> {
    readonly type = SaleActionTypes.AddLogToTransaction;
  }

  export class SaveTransaction extends TabAction<SaveTransactionParams> {
    readonly type = SaleActionTypes.SaveTransaction;
  }

  export class SaveTransactionSuccess extends TabAction<Partial<Transaction>> {
    readonly type = SaleActionTypes.SaveTransactionSuccess;
  }

  export class SaveTransactionError extends TabAction<string> {
    readonly type = SaleActionTypes.SaveTransactionError;
  }

  export class AddPaymentToSelectedTransaction extends TabAction<TransactionPayment> {
    readonly type = SaleActionTypes.AddPaymentToSelectedTransaction;
  }

  export class SearchProducts extends TabAction<SearchProductParams> {
    readonly type = SaleActionTypes.SearchProducts;
  }

  export class SearchProductsSuccess extends TabAction<Product[]> {
    readonly type = SaleActionTypes.SearchProductsSuccess;
  }

  export class SearchProductsError extends TabAction<string> {
    readonly type = SaleActionTypes.SearchProductsError;
  }

  export class AddTransactionPayment extends TabAction<Partial<TransactionPayment>> {
    readonly type = SaleActionTypes.AddTransactionPayment;
  }

  export class SearchCustomers extends TabAction<SearchCustomersParams> {
    readonly type = SaleActionTypes.SearchCustomers;
  }

  export class SearchCustomersSuccess extends TabAction<Customer[]> {
    readonly type = SaleActionTypes.SearchCustomersSuccess;
  }

  export class SearchCustomersError extends TabAction<string> {
    readonly type = SaleActionTypes.SearchCustomersError;
  }

  export class CreateCustomer extends TabAction<CreateCustomersParams> {
    readonly type = SaleActionTypes.CreateCustomer;
  }

  export class CreateCustomerSuccess extends TabAction<Customer> {
    readonly type = SaleActionTypes.CreateCustomerSuccess;
  }

  export class CreateCustomerError extends TabAction<string> {
    readonly type = SaleActionTypes.CreateCustomerError;
  }

  export class UpdateCustomer extends TabAction<UpdateCustomerParams> {
    readonly type = SaleActionTypes.UpdateCustomer;
  }

  export class UpdateCustomerSuccess extends TabAction<Customer> {
    readonly type = SaleActionTypes.UpdateCustomerSuccess;
  }

  export class UpdateCustomerError extends TabAction<string> {
    readonly type = SaleActionTypes.UpdateCustomerError;
  }

  export class GetCustomer extends TabAction<GetCustomerParams> {
    readonly type = SaleActionTypes.GetCustomer;
  }

  export class AddCustomization extends TabAction<number> {
    readonly type = SaleActionTypes.AddCustomization;
  }

  export class UpdateTransactionCustomization extends TabAction<UpdateCustomizationItem> {
    readonly type = SaleActionTypes.UpdateTransactionCustomization;
  }

  export class BackupTransactionCustomization extends TabAction<number> {
    readonly type = SaleActionTypes.BackupTransactionCustomization;
  }

  export class RestoreTransactionCustomization extends TabAction<number> {
    readonly type = SaleActionTypes.RestoreTransactionCustomization;
  }

  export class UpdateCustomizationTierInfo extends TabAction<UpdateTierInfo> {
    readonly type = SaleActionTypes.UpdateCustomizationTierInfo;
  }

  export class GetProductInfo extends TabAction<GetProductInfoParams> {
    readonly type = SaleActionTypes.GetProductInfo;
  }

  export class GetProductInfoSuccess extends TabAction<Product> {
    readonly type = SaleActionTypes.GetProductInfoSuccess;
  }

  export class GetProductInfoError extends TabAction<string> {
    readonly type = SaleActionTypes.GetProductInfoError;
  }

  export class SearchTransactions extends TabAction<SearchTransactionsParams> {
    readonly type = SaleActionTypes.SearchTransactions;
  }

  export class SearchTransactionsSuccess extends TabAction<Transaction[]> {
    readonly type = SaleActionTypes.SearchTransactionsSuccess;
  }

  export class SearchTransactionsError extends TabAction<string> {
    readonly type = SaleActionTypes.SearchTransactionsError;
  }

  export class AddOrderTransaction extends TabAction<Transaction> {
    readonly type = SaleActionTypes.AddOrderTransaction;
  }

  export class UpdateSearchedOrder extends TabAction<UpdateSearchedOrderParams> {
    readonly type = SaleActionTypes.UpdateSearchedOrder;
  }

  export class UpdateSearchedOrderSuccess extends TabAction<Order> {
    readonly type = SaleActionTypes.UpdateSearchedOrderSuccess;
  }

  export class UpdateSearchedOrderError extends TabAction<string> {
    readonly type = SaleActionTypes.UpdateSearchedOrderError;
  }

  export class UpdateTransactionToServer extends TabAction<UpdateTransactionToServerParams> {
    readonly type = SaleActionTypes.UpdateTransactionToServer;
  }

  export class UpdateTransactionToServerSuccess extends TabAction<Partial<Transaction>> {
    readonly type = SaleActionTypes.UpdateTransactionToServerSuccess;
  }

  export class UpdateTransactionToServerError extends TabAction<string> {
    readonly type = SaleActionTypes.UpdateTransactionToServerError;
  }

  export class UpdateSelectedOrder extends TabAction<Order> {
    readonly type = SaleActionTypes.UpdateSelectedOrder;
  }

  export class UpdateSelectedOrderTransaction extends TabAction<Partial<Transaction>> {
    readonly type = SaleActionTypes.UpdateSelectedOrderTransaction;
  }

  export class GetSelectedOrder extends TabAction<GetSelectedOrderParams> {
    readonly type = SaleActionTypes.GetSelectedOrder;
  }

  export class GetSelectedOrderSuccess extends TabAction<Transaction> {
    readonly type = SaleActionTypes.GetSelectedOrderSuccess;
  }

  export class GetSelectedOrderError extends TabAction<string> {
    readonly type = SaleActionTypes.GetSelectedOrderError;
  }

  export class ChangeTransaction extends TabAction<ChangeTransactionParams> {
    readonly type = SaleActionTypes.ChangeTransaction;
  }

  export class ChangeOrderSuccess extends TabAction<Transaction> {
    readonly type = SaleActionTypes.ChangeOrderSuccess;
  }

  export class ChangeOrderError extends TabAction<string> {
    readonly type = SaleActionTypes.ChangeOrderSuccess;
  }

  export class SendTransactionNotification extends TabAction<NotificationInputParams> {
    readonly type = SaleActionTypes.SendTransactionNotification;
  }

  export class SendTransactionNotificationSuccess extends TabAction<boolean> {
    readonly type = SaleActionTypes.SendTransactionNotificationSuccess;
  }

  export class SendTransactionNotificationError extends TabAction<boolean> {
    readonly type = SaleActionTypes.SendTransactionNotificationError;
  }

  export class CreateTransactionNotification extends TabAction<CreateNotificationInputParams> {
    readonly type = SaleActionTypes.CreateTransactionNotification;
  }

  export class CreateTransactionNotificationSuccess extends TabAction<boolean> {
    readonly type = SaleActionTypes.CreateTransactionNotificationSuccess;
  }

  export class CreateTransactionNotificationError extends TabAction<boolean> {
    readonly type = SaleActionTypes.CreateTransactionNotificationError;
  }

}
