<div #dialog class="rb-dialog"
     [ngClass]="modalData?.type"
     [style.width]="modalData?.width && modalData.width + (modalData.widthPercentage ? '%' : 'px')"
     [style.minWidth]="modalData?.width && modalData.width + (modalData.widthPercentage ? '%' : 'px')"
     [style.maxWidth]="modalData?.maxWidth && modalData.maxWidth + (modalData.maxWidthPercentage ? '%' : 'px')"
>
  <div class="rb-modal">
    <div class="rb-modal-header">
      <i class="rb-icon" aria-hidden="true"></i>
      <div class="rb-dialog-btn-close" (click)="xClicked()">✕</div>
      <div class="modal-header-copy"
           [innerHTML]="modalData?.title | interpolate : modalData?.titleParams"></div>
    </div>
    <div class="rb-modal-body">
      <h3 class="text-md" [innerHTML]="modalData?.header | interpolate : modalData?.headerParams"></h3>
      <div class="space-top-10"
           [innerHTML]="modalData?.message | interpolate : modalData?.messageParams"></div>
    </div>
    <div class="rb-modal-footer">
      <div [innerHTML]="modalData?.footer | interpolate : modalData?.footerParams"></div>
    </div>
  </div>
  <div class="action-btn-group">
    <button *ngIf="modalData?.needCancel !== false"
            (click)="cancel()"
            class="-cancel"
            [innerHTML]="modalData?.secondaryButtonText || 'Cancel'"></button>
    <button *ngIf="modalData?.needOk !== false"
            (click)="ok()"
            class="-ok"
            [innerHTML]="modalData?.primaryButtonText || 'Ok'"></button>
  </div>
</div>
