import {ErrorHandler, NgModule} from '@angular/core';
import {AngularFireModule} from '@angular/fire/compat';
// import {AngularFirestoreModule} from '@angular/fire/firestore';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxMaskModule} from 'ngx-mask';
import {RbCommonModule} from 'src/app/common/rb-common.module';
import {AppRoutingModule} from 'src/app/app-routing.module';
import {AppComponent} from 'src/app/app.component';
import {RefreshTokenInterceptor} from 'src/app/core/api/refresh-token.interceptor';
import {CoreModule} from 'src/app/core/core.module';
import {GlobalErrorHandler} from 'src/app/core/global.error-handler';
import {effects, metaReducers, reducers} from 'src/app/store/index.reducer';
import { SentryService } from 'src/app/core/services/sentry.service';
import {environment} from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true
    }),
    CoreModule,
    RbCommonModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({
      maxAge: 50, // Retains last 25 states
    }),
    AngularFireModule.initializeApp(environment.firebase),
    // AngularFirestoreModule,
    // provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideFirestore(() => getFirestore()),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor(private sentry: SentryService) {
    this.sentry.init();
  }

}
