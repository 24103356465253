import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {environment} from 'src/environments/environment';
import {PageNotFoundComponent} from 'src/app/common/components/page-not-found/page-not-found.component';
import {
  RegisterSetupPageComponent
} from 'src/app/common/components/pages/register-setup-page/register-setup-page.component';
import {EnvironmentName} from 'src/app/core/constants/common.enum';
import {Constants} from 'src/app/core/constants/constants';
import {PosLoadGuard} from 'src/app/core/guards/pos-load.guard';
import {PosResolveService} from 'src/app/core/services/pos-resolve.service';
import {DeliveryResolveService} from 'src/app/delivery/services/delivery-resolve.service';
import {PublicResolveService} from 'src/app/public/services/public-resolve.service';

const routes: Routes = [
  {
    path: Constants.routes.setupRegister.self,
    component: RegisterSetupPageComponent
  },
  {
    path: Constants.routes.pos.self,
    loadChildren: () => import('src/app/pos/pos.module').then(m => m.PosModule),
    canLoad: [PosLoadGuard],
    resolve: {resolver: PosResolveService}
  },
  {
    path: Constants.routes.customer.self,
    loadChildren: () => import('src/app/public/public.module').then(m => m.PublicModule),
    resolve: {resolver: PublicResolveService}
  },
  {
    path: Constants.routes.delivery.self,
    loadChildren: () => import('src/app/delivery/delivery.module').then(m => m.DeliveryModule),
    resolve: {resolver: DeliveryResolveService}
  },
  {
    path: Constants.routes.dashboard.self,
    loadChildren: () => import('src/app/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: Constants.routes.developer.self,
    loadChildren: () => import('../developer/developer.module').then(m => m.DeveloperModule)
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: environment.name === EnvironmentName.local ? Constants.routes.pos.self : ''
  },
  {
    path: Constants.routes.wildCard,
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
