import { Injectable } from '@angular/core';
import { ApplicationStorage } from './storage.interface';

@Injectable({
  providedIn: 'root'
})
export class ApplicationStorageService implements ApplicationStorage {
  private storage: any = {};

  public get(key: string): any {
    return this.storage[key] ? this.storage[key] : null;
  }

  public set(key: string, value: any) {
    return (this.storage[key] = value);
  }

  public remove(key: string) {
    delete this.storage[key];
  }

  public exists(key: string): boolean {
    return !!this.storage[key];
  }

  public canUse() {
    return true;
  }
}
