import {pageConfig} from 'src/app/core/constants/constants';
import {NavigationTab, NavigationTabId, OpenTabData} from 'src/app/core/models/common.model';
import {NavigationActions, NavigationActionTypes} from 'src/app/store/navigation/navigation.actions';
import {BaseAction} from 'src/app/store/models/base-action';
import {PayloadAction} from 'src/app/store/models/payload-action';
import {NavigationSelectors} from 'src/app/store/navigation/navigation.selectors';

export const navigationStateKey = 'navigation';

export const navigationCacheKeys: (keyof NavigationState)[] = [];

export interface NavigationState {
  homeTabId: NavigationTabId;
  activeTabId: NavigationTabId;
  tabIds: NavigationTabId[];
  tabs: { [type: string]: NavigationTab };
}

export const initialNavBarState: NavigationState = {
  homeTabId: null,
  activeTabId: null,
  tabIds: [],
  tabs: {}
};

export const homePageTabName = 'homeTabId';

const createTab = (details: OpenTabData): NavigationTab => {
  const config = pageConfig[details.type];
  return {
    ...config,
    ...details
  };
};

export function navigationReducer(state: NavigationState = initialNavBarState, action: BaseAction | PayloadAction<any>): NavigationState {
  switch (action.type) {

    case NavigationActionTypes.OpenTab: {
      const openTabData = (action as NavigationActions.OpenTab).payload;

      const newId = state.homeTabId ? openTabData.id : homePageTabName;
      const newTab = createTab(openTabData);
      newTab.id = newId;
      newTab.title = newTab.title.replace(
        '{{enum}}', newTab.details && newTab.details.employee && newTab.details.employee.nickName);

      return {
        ...state,
        tabIds: [
          ...state.tabIds,
          newId
        ],
        tabs: {
          ...state.tabs,
          [newId.toString()]: newTab
        },
        activeTabId: newId,
        homeTabId: state.homeTabId || newId
      };
    }
    case NavigationActionTypes.SetActiveTab: {
      return {
        ...state,
        activeTabId: (action as NavigationActions.SetActiveTab).payload
      };
    }

    case NavigationActionTypes.RemoveTab: {
      const tabToClose = (action as NavigationActions.RemoveTab).payload;
      let newActiveTab = state.activeTabId;

      if (tabToClose === state.activeTabId) {
        newActiveTab = state.homeTabId;
      }

      const newTabIds = [...state.tabIds];
      const newTabs = {...state.tabs};
      const tabIndex = state.tabIds.indexOf(tabToClose);

      if (tabIndex) {
        newTabIds.splice(tabIndex, 1);
        delete newTabs[tabToClose];
      }

      return {
        ...state,
        activeTabId: newActiveTab,
        tabIds: newTabIds,
        tabs: newTabs
      };
    }

    case NavigationActionTypes.UpdateTabType: {
      const {id, type} = (action as NavigationActions.UpdateTabType).payload;
      const tab = state.tabs[id.toString()];
      if (tab) {
        const newTab = {
          ...tab,
          type
        };
        return {
          ...state,
          tabs: {
            ...state.tabs,
            [id.toString()]: newTab
          }
        };
      }
      return {
        ...state
      };
    }

    case NavigationActionTypes.UpdateTabEmployee: {
      const {id, employee} = (action as NavigationActions.UpdateTabEmployee).payload;
      const tab = state.tabs[id.toString()];
      if (tab) {
        const newTab = {
          ...tab,
          employee
        };
        return {
          ...state,
          tabs: {
            ...state.tabs,
            [id.toString()]: newTab
          }
        };
      }
      return {
        ...state
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
}
