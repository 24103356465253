import {BaseAction} from 'src/app/store/models/base-action';
import {PayloadAction} from 'src/app/store/models/payload-action';
import {
  CashReport,
  Closing, ClosingReq,
  CreditReport, ExistingClosingReq,
  OtherReport,
  PosReport,
  SubmitClosingReq
} from '../../core/api-models/closing';
import {ClosingState} from './closing.reducer';
import {ClosingReportMode} from 'src/app/core/models/common.model';

export enum ClosingActionTypes {
  LoadClosingPosInfo = '[Closing] Load Closing Info',
  LoadClosingInfoPosSuccess = '[Closing] Load Stores Success',
  LoadClosingInfoPosError = '[Closing] Load Stores Error',

  LoadExistingClosing = '[Closing] Load Existing Closing Info',
  LoadExistingClosingSuccess = '[Closing] Load Existing Closing Info Success',
  LoadExistingClosingError = '[Closing] Load Existing Closing Info Error',

  UpdateCashReport = '[Closing] Update Cash Report',
  UpdateCreditReport = '[Closing] Update Credit Report',
  UpdateOtherReport = '[Closing] Update Other Report',

  SubmitClosingReport = '[Closing] Submit Closing Report',
  SubmitClosingReportSuccess = '[Closing] Submit Closing Report Success',
  SubmitClosingReportError = '[Closing] Submit Closing Report Error',

  UpdateClosing = '[Closing] Update Closing',
  CancelClosing = '[Closing] cancel Closing',

  SetClosingReportMode = '[Closing] Set Closing Report Mode'
}

// tslint:disable-next-line:no-namespace
export namespace ClosingActions {

  export class LoadClosingPosInfo extends PayloadAction<ClosingReq> {
    readonly type = ClosingActionTypes.LoadClosingPosInfo;
  }

  export class LoadClosingInfoPosSuccess extends PayloadAction<PosReport> {
    readonly type = ClosingActionTypes.LoadClosingInfoPosSuccess;
  }

  export class LoadClosingInfoPosError extends PayloadAction<string> {
    readonly type = ClosingActionTypes.LoadClosingInfoPosError;
  }

  export class LoadExistingClosing extends PayloadAction<ExistingClosingReq> {
    readonly type = ClosingActionTypes.LoadExistingClosing;
  }

  export class LoadExistingClosingSuccess extends PayloadAction<Closing> {
    readonly type = ClosingActionTypes.LoadExistingClosingSuccess;
  }

  export class LoadExistingClosingError extends PayloadAction<string> {
    readonly type = ClosingActionTypes.LoadExistingClosingError;
  }

  export class UpdateCashReport extends PayloadAction<Partial<CashReport>> {
    readonly type = ClosingActionTypes.UpdateCashReport;
  }

  export class UpdateCreditReport extends PayloadAction<Partial<CreditReport>> {
    readonly type = ClosingActionTypes.UpdateCreditReport;
  }

  export class UpdateOtherReport extends PayloadAction<Partial<OtherReport>> {
    readonly type = ClosingActionTypes.UpdateOtherReport;
  }

  export class SubmitClosingReport extends PayloadAction<SubmitClosingReq> {
    readonly type = ClosingActionTypes.SubmitClosingReport;
  }

  export class SubmitClosingReportSuccess extends PayloadAction<boolean> {
    readonly type = ClosingActionTypes.SubmitClosingReportSuccess;
  }

  export class SubmitClosingReportError extends PayloadAction<string> {
    readonly type = ClosingActionTypes.SubmitClosingReportError;
  }

  export class UpdateClosing extends PayloadAction<Partial<ClosingState>> {
    readonly type = ClosingActionTypes.UpdateClosing;
  }

  export class CancelClosing {
    readonly type = ClosingActionTypes.CancelClosing;
  }

  export class SetClosingReportMode extends PayloadAction<ClosingReportMode>{
    readonly type = ClosingActionTypes.SetClosingReportMode;
  }

}
