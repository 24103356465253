import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {Params} from 'src/app/core/constants/constants';
import {LocalStorageService} from 'src/app/core/services/storage/local-storage.service';
import {CustomerStateKey} from 'src/app/store/customer/customer.reducer';
import {employeeStateKey} from 'src/app/store/employee/employee.reducer';
import {masterDataStateKey} from 'src/app/store/master-data/master-data.reducer';
import {navigationStateKey} from 'src/app/store/navigation/navigation.reducer';
import {saleStateKey} from 'src/app/store/sale/sale.reducer';

@Injectable({
  providedIn: 'root'
})
export class AppLoadService {
  loading: true;

  constructor(
    private localStorage: LocalStorageService
  ) {
  }

  initializeApp(): Promise<any> {
    // If clear querystring is available then remove local storage
    const params = new URLSearchParams(window.location.search);
    const clear = params.get('clear');

    if (clear === Params.Clear) {
      this.localStorage.removeBase(navigationStateKey);
      this.localStorage.removeBase(saleStateKey);
      this.localStorage.removeBase(masterDataStateKey);
      this.localStorage.removeBase(CustomerStateKey);
      this.localStorage.removeBase(employeeStateKey);
      window.location.href = window.location.href.split('?')[0];
    }

    return of(true).toPromise();
  }
}
