import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {ModalComponentInterface} from 'src/app/core/models/modal-component.interface';
import {PopupData, PopupReturnValue} from 'src/app/core/models/popup.model';

@Component({
  selector: 'rb-loader-modal',
  templateUrl: './loader-modal.component.html',
  styleUrls: ['./loader-modal.component.scss']
})
export class LoaderModalComponent implements ModalComponentInterface<PopupReturnValue, PopupData>, OnInit {
  modalResult: Subject<PopupReturnValue> = new Subject<PopupReturnValue>();
  message: string;

  constructor() { }

  ngOnInit(): void {
  }

  setupData(modalParam: PopupData) {
    this.message = modalParam.message;
  }

}
