import {
  Injectable,
  ApplicationRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  Injector, ComponentRef, Type
} from '@angular/core';
import { catchError, first, flatMap, tap } from 'rxjs/operators';
import { NavigationTab } from 'src/app/core/models/common.model';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { Observable, throwError } from 'rxjs';
import { ModalComponentInterface } from 'src/app/core/models/modal-component.interface';
import {homePageTabName} from 'src/app/store/navigation/navigation.reducer';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private currentModalIndex = 0;
  private tab = [{modalIndex: 0}];
  private selectedTabId = homePageTabName;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private navigation: NavigationService,
  ) {
  }

  open<T, U>(modalComponent: Type<ModalComponentInterface<T, U>>, data: U): Observable<T> {
    return this.navigation.activeTab$.pipe(
      first(),
      flatMap((activeTab: NavigationTab) => {
        this.selectedTabId = activeTab?.id || homePageTabName;
        const index = this.tab[this.selectedTabId]?.modalIndex || 0;
        this.tab[this.selectedTabId] = {modalIndex: index + 1};

        let div: HTMLElement;
        let isBody = false;
        const componentRef: ComponentRef<ModalComponentInterface<T, U>>[] = [];
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(modalComponent);
        componentRef[this.currentModalIndex] = componentFactory.create(this.injector);
        this.appRef.attachView(componentRef[this.currentModalIndex].hostView);
        const domElem = (componentRef[this.currentModalIndex].hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        const querySelectorChild = document.querySelector(`.${activeTab?.type || 'test'}-${this.selectedTabId}`);
        let querySelector = querySelectorChild && querySelectorChild.parentElement;
        if (!querySelector) {
          querySelector = document.body;
          isBody = true;
        }
        if (querySelector) {
          querySelector.append(domElem);
          div = querySelector.appendChild(document.createElement('div'));
          div.classList.add(isBody ? 'modal-overlay-body' : 'modal-overlay');
          div.setAttribute('style', `height: ${document.documentElement.scrollHeight - 62}px`);
          div.style.zIndex = `${1000 + (this.tab[this.selectedTabId].modalIndex * 2)}`;
          const newIndex = `${1000 + this.tab[this.selectedTabId].modalIndex * 2 + 1}`;
          domElem.firstElementChild.setAttribute('style', 'z-index:' + newIndex);
        }
        const modalInstance: ModalComponentInterface<T, U> = componentRef[this.currentModalIndex].instance;

        if (modalInstance.setupData && typeof modalInstance.setupData === 'function') {
          if (!data) {
            data = {key: this.currentModalIndex} as any;
          }
          modalInstance.setupData(data);
          (data as any)['currentModalIndex-storage'] = this.currentModalIndex;
        }
        return modalInstance.modalResult.pipe(
          tap(() => this.tab[this.selectedTabId].modalIndex = this.tab[this.selectedTabId].modalIndex - 1),
          tap(() => this.close(componentRef[(data as any)['currentModalIndex-storage']], div)),
          catchError((err) => {
            this.close(componentRef[(data as any)['currentModalIndex-storage']], div);
            return throwError(err);
          })
        );
      })
    );
  }

  close<T>(componentRef: ComponentRef<T>, div: HTMLElement) {
    div.remove();
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
    this.currentModalIndex++;
  }

}
