<div class="loader">
  <div class="loader-body">
    <div class="image">
      <img src="assets/images/loading.gif">
      <div class="message">
        <h2>{{message || 'Please Wait. Loading...'}}</h2>
      </div>
    </div>
  </div>
  <div class="rb-modal-footer">
  </div>
</div>
