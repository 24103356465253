import {Transaction} from 'src/app/core/api-models/transaction';
import {PublicSearchTransactionParams, PublicTransactionParams} from 'src/app/core/models/common.model';
import {PayloadAction} from 'src/app/store/models/payload-action';

export enum PublicActionTypes {
  LoadTransaction = '[Transaction] Load Public Transaction',
  SearchTransactionByIdPhone = '[Transaction] Search Public Transaction Id Phone',
  LoadTransactionSuccess = '[Transaction] Load Public Transaction Success',
  LoadTransactionError = '[Transaction] Load Public Transaction Error',
}

// tslint:disable-next-line:no-namespace
export namespace PublicActions {
  export class LoadTransaction extends PayloadAction<PublicTransactionParams> {
    readonly type = PublicActionTypes.LoadTransaction;
  }
  export class SearchTransactionByIdPhone extends PayloadAction<PublicSearchTransactionParams> {
    readonly type = PublicActionTypes.SearchTransactionByIdPhone;
  }

  export class LoadTransactionSuccess extends PayloadAction<Transaction> {
    readonly type = PublicActionTypes.LoadTransactionSuccess;
  }

  export class LoadTransactionError extends PayloadAction<string> {
    readonly type = PublicActionTypes.LoadTransactionError;
  }
}
