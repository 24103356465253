import {Injectable} from '@angular/core';
import {ApplicationStorageService} from './application-storage.service';
import {LocalStorageService} from './local-storage.service';
import {SessionStorageService} from './session-storage.service';
import {ApplicationStorage} from './storage.interface';
import {CookiesStorageService} from './cookies-storage.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  defaultStore: StoreType = StoreType.LocalStorage;

  constructor(
    private appStore: ApplicationStorageService,
    private localStore: LocalStorageService,
    private sessionStore: SessionStorageService,
    private cookiesStore: CookiesStorageService,
  ) {
  }

  set(key: string, value: any, config?: StorageConfig) {
    const expiry: Date = config && config.expiry ? config.expiry : null;
    const storeData: StoredData = {
      saveDate: new Date(),
      expiry,
      data: value,
    };
    this.getStore(
      config && config.storeType ? config.storeType : this.defaultStore
    ).set(key, JSON.stringify(storeData));
  }

  get(key: string, storeType?: StoreType): any {
    storeType = storeType ? storeType : this.defaultStore;
    const data: StoredData = JSON.parse(this.getStore(storeType).get(key));
    if (data && data.expiry && new Date(data.expiry) < new Date()) {
      this.remove(key, storeType);
      return null;
    }
    return data ? data.data : null;
  }

  remove(key: string, storeType?: StoreType) {
    storeType = storeType ? storeType : this.defaultStore;
    this.getStore(storeType).remove(key);
  }

  exists(key, storeType?: StoreType): boolean {
    return this.getStore(storeType).get(key) !== null;
  }

  getStore(storeType?: StoreType): ApplicationStorage {
    if (!storeType) {
      storeType = this.defaultStore;
    }
    switch (storeType) {
      case StoreType.LocalStorage:
        if (this.localStore.canUse()) {
          return this.localStore;
        }
        break;
      case StoreType.SessionStorage:
        if (this.sessionStore.canUse()) {
          return this.sessionStore;
        }
        break;
      case StoreType.CookiesStorage:
        if (this.cookiesStore.canUse()) {
          return this.cookiesStore;
        }
        break;
      case StoreType.ApplicationStorage:
        return this.appStore;
    }
    return this.appStore;
  }
}

interface StoredData {
  saveDate: Date;
  expiry?: Date;
  data: any;
}

export interface StorageConfig {
  expiry?: Date;
  storeType?: StoreType;
}

export enum StoreType {
  LocalStorage,
  SessionStorage,
  ApplicationStorage,
  CookiesStorage,
}
