import {Address, Customer, FulfillmentMethod, Order} from 'src/app/core/api-models/order';
import {UpdateOrderProperty} from 'src/app/core/api-models/transaction-item';
import {DiscountLevel, DiscountType, OrderStatusEnum, PageType} from 'src/app/core/constants/common.enum';
import {DateRange, NotificationEvent, NotificationType, SearchParam, Transaction} from 'src/app/core/api-models/transaction';

export interface Employee {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  nickName: string;
  role: EmployeeRole[];
  store: number[];
  active: boolean;
  password?: string;
}

export enum EmployeeRole {
  employee = 'employee',
  csr = 'csr',
  manager = 'manager',
  admin = 'admin'
}

export interface VerifyPasswordParams {
  employee: Employee;
  password: string;
}

export enum Medium {
  store = 'store',
  phone = 'phone',
  web = 'web',
  email = 'email'
}

export interface DateWithName {
  date: Date;
  name: string;
}

export enum EmployeeModalConfig {
  EmployeeOnly = 1,
  EmployeeWithPassword = 2,
  EmployeeAndMedium = 3
}

export interface EmployeeModalParam {
  modalConfig: EmployeeModalConfig;
  employee: Employee;
  medium: Medium;
  store: number;
  role: EmployeeRole;
  hideX: boolean;
  autoClose: boolean;
}

export enum SelectClosingReportModalButtons {
  MODIFY,
  OPEN,
  CREATE,
  CLOSE
}

export enum ClosingReportMode {
  VIEW,
  MODIFY,
  CREATE
}

export interface DiscountModel {
  tabId: NavigationTabId;
  level: DiscountLevel;
  uuid?: string;
  type?: DiscountType;
  value?: number;
  reason?: string;
  details?: string;
}

export interface TabDetails {
  employee: Employee;
  medium: Medium;
  extra?: any;
}

export interface RequestTabData {
  type: PageType;
  initialData?: any;
}

export interface OpenTabData extends RequestTabData {
  details?: TabDetails;
  id: NavigationTabId;
}

export interface NavigationTabMetadata extends RequestTabData {
  title: string;
  allowMultiple?: boolean;
  allowClosing?: boolean;
  employeeModalConfig?: EmployeeModalConfig;
  role?: EmployeeRole;
}

export type NavigationTabId = string;

export interface NavigationTab extends NavigationTabMetadata, OpenTabData {
}

export interface CakeCustomizationModalParams {
  id?: number;
  edit?: boolean;
  index: number;
  tabId: NavigationTabId;
}

export interface Country {
  countryName: string;
  code: number;
}

export enum PhoneType {
  mobile = 'mobile',
  other = 'other'
}

export interface PhoneNumber {
  number: number;
  type: PhoneType;
  countryCode: number;
  extension: number;
}

export interface CustomerModalParams {
  tabId: NavigationTabId;
  id?: number;
  firstName?: string;
  lastName?: string;
  searchText?: string;
  phones?: PhoneNumber[];
  emails?: string[];
  notes?: string;
  isEdit?: boolean;
}

export enum InOutStatus {
  In = 'in',
  Out = 'out',
  None = ''
}

export enum InOutReason {
  Lunch = 'lunch',
  Break = 'break',
  DayOff = 'day-off',
  None = ''
}

export interface TimeInOutReport {
  employeeTimeInOut: EmployeeTimeInOut[];
}

export interface EmployeeTimeInOut {
  id?: number;
  employeeId: number;
  storeNumber: number;
  timeInDate: string;
  timeIn1?: string;
  timeOut1?: string;
  reason1?: InOutReason;
  timeIn2?: string;
  timeOut2?: string;
  reason2?: InOutReason;
  timeIn3?: string;
  timeOut3?: string;
  reason3?: InOutReason;
  timeIn4?: string;
  timeOut4?: string;
  reason4?: InOutReason;
  log?: TimeLogs;
}

export interface EmployeeModal {
  title: string;
  employee?: Employee;
  isEdit: boolean;
}

export interface TimeInModal {
  title: string;
  employeeTimeInData: EmployeeTimeInOut;
}

export interface EmployeeTimeLogModal {
  title: string;
  logs: TimeLogs;
}

export interface EmployeeSearchParam {
  name?: string;
  store?: string[];
  role?: string[];
  active?: boolean;
}

export interface EmployeeTimeInSearchParam {
  name?: string;
  store?: string;
  dateRange: DateRange;
}

export interface ChangeDateParam {
  tabId: NavigationTabId;
  id: number;
  fulfillmentDate: string;
  transactionId: number;
  storeNumber: number;
}

export interface NotificationParams {
  employee: Employee;
  transactionId: number;
  event: NotificationEvent;
  templateType: NotificationType;
}

export interface CreateNotificationParams {
  employee: string;
  notificationType: NotificationType;
  sender: string;
  recipient: string;
  content: string;
  notificationTime: string;
  transactionId: number;
  transactionEvent: NotificationEvent;
  subject: string;
}

export interface ReminderResponse {
  transactionId: number;
  orderStatus: OrderStatusEnum;
}

export interface PublicTransactionParams {
  transactionId: number;
  shortCode: string;
}

export interface PublicSearchTransactionParams {
  transactionId: number;
  phone: string;
}

export interface DeliveryTransactionParams {
  transactionId: number;
  randomId: string;
}

export interface NotificationModalResult {
  print: boolean;
  order: boolean;
  email: {
    send: boolean;
    address: string;
  };
  sms: {
    send: boolean;
    phone: PhoneNumber;
  };
}

export enum CreateEmployeeType {
  store = 'store',
  role = 'role'
}

export enum ActiveStateTypes {
  TimeIn = 'TimeIn',
  TimeOut = 'TimeOut',
  Reason = 'Reason',
}

export interface SearchProductParams {
  tabId: NavigationTabId;
  searchTerm: string;
}

export interface SearchCustomersParams {
  tabId: NavigationTabId;
  searchTerm: string;
}

export interface CreateCustomersParams {
  tabId: NavigationTabId;
  customer: Partial<Customer>;
}

export interface SaveTransactionParams {
  tabId: NavigationTabId;
  transaction: Transaction;
}

export interface UpdateTransactionToServerParams {
  tabId: NavigationTabId;
  transaction: Partial<Transaction<Partial<Order>>>;
}

export interface GetProductInfoParams {
  tabId: NavigationTabId;
  sku: number;
}

export interface UpdateCustomerParams {
  tabId: NavigationTabId;
  customer: Partial<Customer>;
}

export interface GetCustomerParams {
  tabId: NavigationTabId;
  customerId: number;
}

export interface SearchTransactionsParams {
  tabId: NavigationTabId;
  searchParam: Partial<SearchParam>;
}

export interface UpdateSearchedOrderParams {
  tabId: NavigationTabId;
  updateOrderProperty: UpdateOrderProperty;
}

export interface GetSelectedOrderParams {
  tabId: NavigationTabId;
  id: number;
}

export interface ChangeTransactionParams {
  tabId: NavigationTabId;
  id: number;
}

export interface NotificationInputParams {
  tabId: NavigationTabId;
  notification: NotificationParams;
}

export interface CreateNotificationInputParams {
  tabId: NavigationTabId;
  notification: CreateNotificationParams[];
}

export interface AddressParams {
  tabId: NavigationTabId;
  address: Address;
}

export enum QuickPickupMessage {
  valid = '',
  pickup = 'Success!! Pickup Completed',
  delivery = 'Success!! Delivery Started',
  status = 'Failed!! Wrong Status',
  completed = 'Failed!! Already Completed',
  balance = 'Failed!! Balance Due',
  date = 'Failed!! Wrong Date',
  store = 'Failed!! Wrong Store',
  invalid = 'Failed!! Invalid order'
}

export enum QuickPickupValidationStatus {
  Valid = 'valid',
  Balance = 'balance',
  Invalid = 'invalid',
  Blank = 'blank'
}

export interface QuickPickupOrderStatus {
  transactionId: number;
  offline: boolean;
  storeId: number;
  orderId: number;
  fulfillmentMethod: FulfillmentMethod;
  name: string;
  phone: PhoneNumber;
  balance: number;
  location: string;
  orderStatus: OrderStatusEnum;
  isOrder: boolean;
  isShopifyOrder: boolean;
  status: QuickPickupValidationStatus;
  message: QuickPickupMessage | string;
  errorMessage: string;
  createDate: string;
}

export interface TimeLogs {
  employeeId: number;
  employeeName: string;
  date: string;
  timeTracking: TimeInOutTracking[];
}

export interface TimeInOutTracking {
  lineId: number;
  by: string;
  timeIn?: string | null;
  timeOut?: string | null;
  reason?: string | null;
  timeStamp: Date
}
