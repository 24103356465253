import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {filter, first, map, takeWhile} from 'rxjs/operators';
import {
  Employee,
  TabDetails,
  EmployeeModalConfig,
  EmployeeModalParam,
  Medium, EmployeeRole
} from 'src/app/core/models/common.model';
import {ModalComponentInterface} from 'src/app/core/models/modal-component.interface';
import {AppStore} from 'src/app/store/index.reducer';
import {MasterDataActions} from 'src/app/store/master-data/master-data.actions';
import {MasterDataState} from 'src/app/store/master-data/master-data.reducer';
import {MasterDataSelectors} from 'src/app/store/master-data/master-data.selectors';
import {PhysicalStoreService} from 'src/app/store/master-data/physical-store.service';

@Component({
  selector: 'rb-employee-modal',
  templateUrl: './employee-modal.component.html',
  styleUrls: ['./employee-modal.component.scss']
})
export class EmployeeModalComponent implements ModalComponentInterface<TabDetails, EmployeeModalParam>,
  OnInit, AfterViewInit, OnDestroy {
  @ViewChild('passwordField') passwordField: ElementRef;

  masterData$: Observable<MasterDataState> = this.store.select(MasterDataSelectors.masterData);

  password = new UntypedFormControl('');

  modalResult: Subject<TabDetails> = new Subject<TabDetails>();

  EmployeeModalConfig = EmployeeModalConfig;
  modalConfig: EmployeeModalConfig;

  alive = true;
  title: string;
  medium = Medium;
  employees: Employee[];
  initialEmployee: Employee;
  initialMedium: Medium;
  selectedEmployee: Employee;
  selectedMedium: Medium;
  employeeMissing: boolean;
  mediumMissing: boolean;
  passwordMissing: boolean;
  passwordInvalid: boolean;
  hideX: boolean;
  autoClose: boolean;
  storeNumber: number;
  employeeRole: EmployeeRole;

  constructor(
    private store: Store<AppStore>,
    private physicalStoreService: PhysicalStoreService
  ) {
  }

  ngOnInit() {
    const employees$: Observable<Employee[]> =
      this.store.select(
        this.employeeRole ?
          MasterDataSelectors.employeesByStoreAndRole(this.storeNumber, this.employeeRole) :
          MasterDataSelectors.employeesByStoreNumber(this.storeNumber));
    employees$.pipe(
      takeWhile(() => this.alive),
    )
      .subscribe(employees =>
        this.employees = employees.filter(employee => employee.active));
  }

  ngAfterViewInit(): void {
    this.password.valueChanges.subscribe((val) => {
      this.passwordMissing = val.length === 0;
      this.passwordInvalid = false;
    });
  }

  setupData(modalParam: EmployeeModalParam) {
    this.modalConfig = modalParam.modalConfig;
    this.selectedEmployee = this.initialEmployee = modalParam.employee;
    this.selectedMedium = this.initialMedium = modalParam.medium;
    this.storeNumber = modalParam.store || this.physicalStoreService.currentStore.storeNumber;
    this.employeeRole = modalParam.role;
    this.hideX = modalParam.hideX;
    this.title = this.getTitle();
    this.autoClose = modalParam.autoClose || false;
  }

  selectEmployee(employee: Employee) {
    this.selectedEmployee = employee;
    this.employeeMissing = false;
    if (this.modalConfig === EmployeeModalConfig.EmployeeWithPassword) {
      this.focusPassword();
    }

    if (this.autoClose) {
      this.closeModal(true);
    }
  }

  selectMedium(medium: Medium) {
    this.selectedMedium = medium;
    this.mediumMissing = false;
  }

  getTitle(): string {
    if (this.modalConfig === EmployeeModalConfig.EmployeeAndMedium) {
      return 'Select Name and Medium';
    } else if (this.modalConfig === EmployeeModalConfig.EmployeeWithPassword) {
      return 'Select Name and Password';
    }
    return 'Select Name';
  }

  okClicked() {
    if (!this.selectedEmployee) {
      this.employeeMissing = true;
      return;
    }

    if (this.modalConfig === EmployeeModalConfig.EmployeeWithPassword) {
      if (!this.password.value) {
        this.passwordMissing = true;
        return;
      }
      this.verifyPassword(this.password.value)
        .subscribe((passwordVerified) => {
          if (!passwordVerified) {
            this.passwordInvalid = true;
            return;
          }
          this.closeModal(true);
        });
    } else if (this.modalConfig === EmployeeModalConfig.EmployeeAndMedium) {
      if (!this.selectedMedium) {
        this.mediumMissing = true;
        return;
      }
      this.closeModal(true);
    } else {
      this.closeModal(true);
    }
  }

  closeModal(emitData: boolean = false) {
    let employeeMedium: TabDetails = null;
    if (this.modalConfig === EmployeeModalConfig.EmployeeAndMedium) {
      if (this.selectedMedium) {
        employeeMedium = {employee: this.selectedEmployee, medium: this.selectedMedium};
      }
    } else {
      employeeMedium = {employee: this.selectedEmployee, medium: null};
    }

    setTimeout(() => {
      this.selectedEmployee = null;
      this.selectedMedium = null;
      this.initialEmployee = null;
      this.initialMedium = null;
    });

    if (emitData) {
      this.modalResult.next(employeeMedium);
    } else {
      this.modalResult.error(null);
    }
  }

  private verifyPassword(password: string): Observable<boolean> {
    this.store.dispatch(new MasterDataActions.VerifyPassword({
      employee: this.selectedEmployee,
      password
    }));

    return this.masterData$.pipe(
      takeWhile(() => this.alive),
      filter((data: MasterDataState) => !data.VerifyPasswordLoading),
      first(),
      map((data: MasterDataState) => data.VerifyPasswordSuccess)
    );
  }

  private focusPassword() {
    (this.passwordField.nativeElement as HTMLInputElement).select();
  }

  refreshEmployees() {
    this.store.dispatch(new MasterDataActions.LoadEmployees());
  }

  @HostListener('document:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      this.okClicked();
    } else if (event.code === 'Escape') {
      this.closeModal();
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
