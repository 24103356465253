import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, of, throwError} from 'rxjs';
import {catchError, delay, first, map, mergeMap, retryWhen} from 'rxjs/operators';
import {Constants} from 'src/app/core/constants/constants';
import {StorageService, StoreType} from 'src/app/core/services/storage/storage.service';
import {UtilsService} from 'src/app/core/services/utils.service';
import {AppStore} from 'src/app/store/index.reducer';
import {MasterDataActions} from 'src/app/store/master-data/master-data.actions';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpClientWrapper {

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private utils: UtilsService,
    private store: Store<AppStore>
  ) {
  }

  public get<T>(apiRoute: string, params?: HttpParams): Observable<T> {
    const httpCall = this.httpClient.get<T>(environment.baseApiUrl + apiRoute, {
      params,
      headers: this.buildHeaders('')
    });

    return this.retryHttp(apiRoute, httpCall);
  }

  public post<I, T>(userInContext: string, apiRoute: string, body: I): Observable<T> {
    const httpCall = this.httpClient.post<T>(environment.baseApiUrl + apiRoute, body, {
      headers: this.buildHeaders(userInContext)
    });

    return this.retryHttp(apiRoute, httpCall);
  }

  public put<I, T>(userInContext: string, apiRoute: string, body: I): Observable<T> {
    const httpCall = this.httpClient.put<T>(environment.baseApiUrl + apiRoute, body, {
      headers: this.buildHeaders(userInContext)
    });

    return this.retryHttp(apiRoute, httpCall);
  }

  public delete<I, T>(userInContext: string, apiRoute: string): Observable<T> {
    const httpCall = this.httpClient.delete<T>(environment.baseApiUrl + apiRoute, {
      headers: this.buildHeaders(userInContext)
    });

    return this.retryHttp(apiRoute, httpCall);
  }

  private retryHttp<T>(apiRoute: string, response: Observable<T>): Observable<T> {
    // let retries = 1;

    // We removed the retry logic as this is causing issue with multiple transactions and multiple notifications
    return response.pipe(
      // retryWhen((errors => {
      //   return errors.pipe(
      //     delay(500),
      //     mergeMap(error => (retries-- > 0 && error.status !== 401) ? of(error) : throwError(error))
      //   );
      // })),
      map((value) => {
        if ((value as any).error) {
          throw new Error((value as any).error);
        }
        return value;
      }),
      catchError(this.handleError(apiRoute, null))
    );
  }

  private handleError<T>(apiRoute: string, result?: T) {
    return (error: any): Observable<T> => {

      if (error.status === 401) {
        if (apiRoute === Constants.apiPaths.setupRegister || apiRoute === Constants.apiPaths.refreshToken) {
          this.utils.redirectToSetupRegister()
            .pipe(first())
            .subscribe();
          throw new Error('Unauthenticated');
        } else {
          this.store.dispatch(new MasterDataActions.UpdateRefreshToken());
        }
      }

      throw new Error(error && error.error && error.error.message || error && error.message || error);
    };
  }

  private buildHeaders(userInContext: string): any {
    const security = this.storageService.get('security', StoreType.SessionStorage);
    const authorization = `${security && security.token || ''}`;

    const header = {
      'x-username': userInContext || '',
      'x-request-id': Math.random().toString(36).replace('0.', ''),
    };

    if (authorization) {
      return {
        ...header,
        Authorization: `Bearer ${authorization}`
      };
    }

    return header;
  }
}
