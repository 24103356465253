import {Transaction} from 'src/app/core/api-models/transaction';
import {BaseAction} from 'src/app/store/models/base-action';
import {PayloadAction} from 'src/app/store/models/payload-action';
import {PublicActions, PublicActionTypes} from 'src/app/store/public/public.actions';

export const PublicStateKey = 'public';

export const publicCacheKeys = [];

export interface PublicState {
  selectedTransaction: PublicTransactionState;
}

export interface TransactionState {
  loading: boolean;
  success: boolean;
  error: string;
}

export interface PublicTransactionState {
  transaction: Transaction;
  transactionState: TransactionState;
}

export const initialPublicTransactionState: PublicTransactionState = {
  transaction: null,
  transactionState: {
    loading: false,
    success: false,
    error: ''
  }
};

const initialPublicState: PublicState = {
  selectedTransaction: initialPublicTransactionState
};

export function publicReducer(state: PublicState = initialPublicState,
                              action: BaseAction | PayloadAction<any>): PublicState {
  switch (action.type) {
    case PublicActionTypes.LoadTransaction: {
      return {
        ...state,
        selectedTransaction: {
          ...state.selectedTransaction,
          transactionState: {
            loading: true,
            success: false,
            error: ''
          }
        }
      };
    }
    case PublicActionTypes.SearchTransactionByIdPhone: {
      return {
        ...state,
        selectedTransaction: {
          ...state.selectedTransaction,
          transactionState: {
            loading: true,
            success: false,
            error: ''
          }
        }
      };
    }
    case PublicActionTypes.LoadTransactionSuccess: {
      return {
        ...state,
        selectedTransaction: {
          ...state.selectedTransaction,
          transaction: (action as PublicActions.LoadTransactionSuccess).payload,
          transactionState: {
            loading: false,
            success: true,
            error: ''
          }
        }
      };
    }
    case PublicActionTypes.LoadTransactionError: {
      return {
        ...state,
        selectedTransaction: {
          ...state.selectedTransaction,
          transactionState: {
            loading: false,
            success: false,
            error: (action as PublicActions.LoadTransactionError).payload
          }
        }
      };
    }
  }
  return {
    ...state
  };
}


