import { Injectable } from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, filter, first, switchMap, tap} from 'rxjs/operators';
import {AppStore} from 'src/app/store/index.reducer';
import {MasterDataActions} from 'src/app/store/master-data/master-data.actions';
import {MasterDataState} from 'src/app/store/master-data/master-data.reducer';
import {MasterDataSelectors} from 'src/app/store/master-data/master-data.selectors';

@Injectable({
  providedIn: 'root'
})
export class PublicResolveService {

  masterData$: Observable<MasterDataState> = this.store.select(MasterDataSelectors.masterData);

  constructor(
    private store: Store<AppStore>,
  ) { }

  resolve(): Observable<boolean> {

    let firstTime = true;

    return this.masterData$.pipe(
      filter((masterData: MasterDataState) => !masterData.tokenLoading),
      tap(() => {
        if (firstTime) {
          this.store.dispatch(new MasterDataActions.LoadStores());
          firstTime = false;
        }
      }),
      filter((dataState: MasterDataState) => !(dataState.storesLoading)),
      filter((data: MasterDataState) => data.stores.length > 0),
      first(),
      switchMap(() => of(true)),
      catchError(error => {
        return of(true);
      })
    );
  }
}
