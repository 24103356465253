import {UtilsService} from 'src/app/core/services/utils.service';

export const Messages = {
  confirmation: 'Confirmation',
  success: 'Success',
  error: 'Error',
  masterDataLoading: {
    error: {
      message: `There is an error loading Master Data. Please restart application. if problem persists contact Technical Support.`
    }
  },
  deleteItem: {
    confirmation: {
      message: 'Are you sure you want to delete this item?',
    }
  },
  cancelTransaction: {
    confirmation: {
      message: 'Are you sure you want to cancel this transaction? All Data will be lost.',
    }
  },
  cancelOrder: {
    confirmation: {
      message: 'Are you sure you want to cancel this order? All Data will be lost.',
    }
  },
  holdTransaction: {
    confirmation: {
      message: 'Are you sure you want to hold the Transaction?'
    },
    success: {
      // tslint:disable-next-line:max-line-length
      message: `This transaction has been put on hold. You can retrieve this Transaction <strong>#{transactionId}</strong> by using Search Sale.`
    },
    successOrder: {
      message: `This order has been put on hold. You can retrieve this Order <strong>#{transactionId}</strong> by using Search Order.`
    },
    error: {
      message: 'There is an issue holding this transaction, please try again. If issue persists, please contact Technical Support.'
    }
  },
  saveTransaction: {
    success: {
      message: 'Receipt has been saved successfully #{Transaction}.'
    },
    error: {
      message: 'There is an issue saving this transaction, please try again. If issue persists, please contact Technical Support.'
    }
  },
  print: {
    error: {
      message: 'Error in printing receipt. Please try again.'
    },
    orderError: {
      message: 'Error in printing order form. Please try again.'
    }
  },
  tax: {
    addConfirmation: {
      title: 'Add Tax',
      message: 'Are you sure you want to add Tax to this item.',
    },
    removeConfirmation: {
      title: 'Remove Tax',
      message: 'Are you sure you want to remove Tax from this item.'
    }
  },
  setupRegister: {
    error: {
      title: 'Error in setting up register',
      message: 'Please try again. If the issue persists, contact Technical Support.'
    },
    idPasswordError: {
      message: 'Id and Password don\'t match, please try again!'
    },
    success: {
      title: 'Register setup successful',
      message: 'Please make sure this is the correct storeName and register number, otherwise contact Technical Support immediately. ' +
        '<br><br>Store: <strong>{{storeName}}</strong>, <br>Register Number: <strong>{{registerNumber}}</strong>'
    }
  },
  cancelEditingOrder: {
    confirmation: {
      message: 'Are you sure you want to cancel updating the Order?',
    }
  },
  cancelAddingProduct: {
    confirmation: {
      message: 'Are you sure you want to cancel adding this product?',
    }
  },
  cancelEditingCustomization: {
    confirmation: {
      message: 'Are you sure you want to cancel editing this product?',
    }
  },
  orderDeposit: {
    zeroDeposit: {
      message: 'There is <strong>No Deposit</strong> on the order. Are you sure you want to place this order?'
    },
    partialDeposit: {
      message: `Deposit is less than 100%.
        <br><br>Deposit: <strong>{{deposit}}</strong>, Balance Due: <strong>{{amountDue}}</strong>
        <br><br>Are you sure you want to place this order?`
    }
  },
  deliveryPickup: {
    deliveryConfirmation: {
      message: 'Are you sure you want Delivery from {{storeName}} storeName?',
    },
    pickupConfirmation: {
      message: 'Are you sure you want Pickup from {{storeName}} storeName?'
    }
  },
  changeFulfillmentDate: {
    confirmation: {
      message: 'Are you sure you want to change fulfillment Date from' +
        '<br><br><strong>{{prevFulfillmentDate}}</strong> to <strong>{{fulfillmentDate}}?</strong>',
    }
  },
  searchCustomers: {
    error: {
      title: 'Error in Searching Customers',
      message: 'There is an issue in searching Customers, please try again. If the issue persists, contact Technical Support.'
    }
  },
  searchTransaction: {
    error: {
      title: 'Error in Searching Transactions',
      message: 'There is an issue in searching Transactions, please try again. If the issue persists, contact Technical Support.'
    }
  },
  getSelectedOrder: {
    error: {
      title: 'Error in fetching selected Order transaction',
      message: 'There is an issue in fetching selected Order transaction, please try again.' +
        ' If the issue persists, contact Technical Support.'
    }
  },
  boxCountWarning: {
    info: {
      title: 'Warning about box count',
      message: 'There are <strong> {{boxCount}} </strong>' +
        'boxes in the order, please make sure you provide <strong> {{boxCount}} </strong>' +
        'boxes to <strong> {{personType}} </strong>'
    }
  },
  wrongOrderFulfillmentDate: {
    error: {
      title: 'Error in Order fulfillment',
      message: 'Order <strong>#{{orderId}}</strong> can\'t be fulfilled today.<br><br>' +
        ' Fulfillment date is <strong>{{orderDate}}</strong>.<br><br>' +
        ' If you want to fulfill now, please update the fulfillment date.'
    },
  },
  wrongMessageDate: {
    error: {
      title: 'Error in Sending Message',
      message: 'Reminder message can\'t be sent for Order <strong>#{{orderId}}</strong><br><br>' +
        ' Fulfillment date is <strong>{{orderDate}}</strong>.<br><br>' +
        ' If you want to send message now, please update the fulfillment date.'
    },
  },
};

export const inputWithNumberPadCurrencyErrorMessages: ErrorMessageConfig = {
  required: (payload: ErrorMessagePayload) => `The ${payload.controlName} is required.`,
  min: (payload: ErrorMessagePayload) =>
    `The ${payload.controlName} has to be at least ${UtilsService.convertToCurrency(payload.data.min)}.`,
  max: (payload: ErrorMessagePayload) =>
    `The ${payload.controlName} has to be less than ${UtilsService.convertToCurrency(payload.data.max)}.`,
};

export const inputWithNumberPadPercentageErrorMessages: ErrorMessageConfig = {
  required: (payload: ErrorMessagePayload) => `The ${payload.controlName} is required.`,
  max: (payload: ErrorMessagePayload) =>
    `The ${payload.controlName} has to be less than ${payload.data.max}%.`,
};

export const inputWithNumberPadCreditErrorMessages: ErrorMessageConfig = {
  required: (payload: ErrorMessagePayload) => `The ${payload.controlName} is required.`,
  min: (payload: ErrorMessagePayload) =>
    `The ${payload.controlName} should be equal to ${UtilsService.convertToCurrency(payload.data.min)}.`,
  max: (payload: ErrorMessagePayload) =>
    `The ${payload.controlName} should be equal to ${UtilsService.convertToCurrency(payload.data.max)}.`,
};

export const inputWithNumberPadErrorMessages: ErrorMessageConfig = {
  required: (payload: ErrorMessagePayload) => `The ${payload.controlName} is required.`,
  min: (payload: ErrorMessagePayload) => `The ${payload.controlName} has to be at least ${payload.data.min}.`,
  max: (payload: ErrorMessagePayload) => `The ${payload.controlName} has to be less than ${payload.data.max}.`,
};

export interface ErrorMessageConfig {
  [errorType: string]: (payload: ErrorMessagePayload) => string;
}

export interface ErrorMessagePayload {
  controlName: string;
  data?: any;
}
