import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {ModalComponentInterface} from 'src/app/core/models/modal-component.interface';
import {PopupData, PopupReturnValue} from 'src/app/core/models/popup.model';

@Component({
  selector: 'rb-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss']
})
export class PopupModalComponent implements ModalComponentInterface<PopupReturnValue, PopupData>, OnInit, OnDestroy {
  modalResult: Subject<PopupReturnValue> = new Subject<PopupReturnValue>();

  @ViewChild('dialog', {static: true}) dialog: ElementRef;

  modalData: PopupData;

  constructor() {
  }

  ngOnInit() {
  }

  setupData(modalParam: PopupData) {
    this.modalData = modalParam;
  }

  cancel() {
    this.modalResult.next(PopupReturnValue.SecondaryButton);
  }

  ok() {
    this.modalResult.next(PopupReturnValue.PrimaryButton);
  }

  xClicked() {
    this.modalResult.next(PopupReturnValue.XButton);
  }

  @HostListener('document:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    event.stopPropagation();
    event.cancelBubble = true;
    if (event.code === 'Enter') {
      this.ok();
    } else if (event.code === 'Escape') {
      this.cancel();
    }
  }

  ngOnDestroy() {
    // this.dialog.nativeElement.setAttribute('style',
    //   `width: 300px, min-width: 300px`);
  }

}
