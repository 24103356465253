import { Injectable } from '@angular/core';

import * as Cookies from 'js-cookie';
import {storagePrefix} from 'src/app/core/constants/constants';
import { ApplicationStorage } from './storage.interface';

@Injectable({
  providedIn: 'root'
})
export class CookiesStorageService implements ApplicationStorage {
  private cookiesRef;

  constructor() {
    this.cookiesRef = Cookies;
  }

  prefix = storagePrefix;

  public get(key: string): any {
    return this.cookiesRef.get(`${this.prefix}${key}`) || null;
  }

  public set(key: string, value: any) {
    this.cookiesRef.set(`${this.prefix}${key}`, value);
  }

  public remove(key: string) {
    this.cookiesRef.remove(`${this.prefix}${key}`);
  }

  public exists(key: string): boolean {
    return typeof this.cookiesRef.get(`${this.prefix}${key}`) !== 'undefined';
  }

  public canUse(): boolean {
    return navigator.cookieEnabled;
  }
}
