import {EnvironmentName} from 'src/app/core/constants/common.enum';

export const environment = {
  production: true,
  name: EnvironmentName.prod,
  baseApiUrl: 'https://api.app.rashmisbakery.com',
  socketUrl: 'wss://api.app.rashmisbakery.com:9000',
  posUrl: 'https://app.rashmisbakery.com',
  customerUrl: 'https://rashm.is',
  currentOrderLength: 6,
  minOrderNumber: 100000,
  logrocketApiKey: 'h7twdq/pos',
  analyticsApiKey: '8197afe7905d0d76a87ac6767fc4fab3',
  pos: {
    registernumber: 1
  },
  autoPrint: true,
  customerAutoPrint: true,
  firebase: {
    apiKey: 'AIzaSyBJp_mXSNTx4PkgXJgLxyZ4Z8ZUaYQX-hE',
    authDomain: 'rashmis-bakery-website.firebaseapp.com',
    databaseURL: 'https://rashmis-bakery-website.firebaseio.com',
    projectId: 'rashmis-bakery-website',
    storageBucket: 'rashmis-bakery-website.appspot.com',
    messagingSenderId: '798531703531',
    appId: '1:798531703531:web:17bb20aec5b9e3430ae4ae',
    measurementId: 'G-HPS1WSC25P'
  },
  // firebaseApiUrl: 'https://firebase.rashmisbakery.com/api'
  firebaseApiUrl: 'https://us-central1-rashmis-bakery-website.cloudfunctions.net/api',
  timeZone: 'America/New_York'
};
