import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as moment from 'moment';
import {Observable, of} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {Holiday, PhysicalStore, SpecialDay, StoreHours, StoreInfo} from 'src/app/core/api-models/physical-store';
import {Register} from 'src/app/core/api-models/register';
import {AppStore} from 'src/app/store/index.reducer';
import {MasterDataSelectors} from 'src/app/store/master-data/master-data.selectors';

@Injectable({
  providedIn: 'root'
})
export class PhysicalStoreService {
  private stores$: Observable<PhysicalStore[]> = this.store.select(MasterDataSelectors.stores);
  private register$: Observable<Register> = this.store.select(MasterDataSelectors.register);

  stores: PhysicalStore[];
  private myStore: PhysicalStore;

  constructor(
    private store: Store<AppStore>
  ) {
    this.stores$.pipe(
      switchMap(stores => {
        this.stores = stores;
        return this.register$;
      }),
      tap(register => this.myStore = this.storeByNumber(register.storeNumber))
    ).subscribe();
  }

  public get currentStore() {
    return this.myStore;
  }

  public getHolidays(store?: PhysicalStore, year?: number, month?: number): Holiday[] {
    return store.holidays.filter(holiday => {
      const date = holiday.date.split('-');
      if (year && year > 0) {
        if (month && month > 0) {
          return +date[0] === year && +date[1] === month;
        } else {
          return +date[0] === year;
        }
      } else {
        return true;
      }
    });
  }

  public getCurrentStoreHours(): StoreHours {
    return this.getStoreHours(this.currentStore.storeNumber, new Date());
  }

  public getStoreHours(storeNumber: number, date: Date): StoreHours {
    const store = this.stores.find(s => s.storeNumber === storeNumber);

    if (!store || !date) {
      return null;
    }

    const holidayHours = this.checkHolidayHours(store, date);
    if (holidayHours) {
      return holidayHours;
    }

    const specialDayHours = this.getSpecialDayHours(store, date);
    if (specialDayHours != null) {
      return specialDayHours;
    }

    const storeHours = {
      date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      storeClosed: false
    };

    switch (date.getDay()) {
      case 0:
        return {...storeHours, openHours: store.sundayOpenHours, closeHours: store.sundayCloseHours};
      case 1:
        return {...storeHours, openHours: store.mondayOpenHours, closeHours: store.mondayCloseHours};
      case 2:
        return {...storeHours, openHours: store.tuesdayOpenHours, closeHours: store.tuesdayCloseHours};
      case 3:
        return {...storeHours, openHours: store.wednesdayOpenHours, closeHours: store.wednesdayCloseHours};
      case 4:
        return {...storeHours, openHours: store.thursdayOpenHours, closeHours: store.thursdayCloseHours};
      case 5:
        return {...storeHours, openHours: store.fridayOpenHours, closeHours: store.fridayCloseHours};
      case 6:
        return {...storeHours, openHours: store.saturdayOpenHours, closeHours: store.saturdayCloseHours};
      default:
        return null;
    }
  }

  public formatStoreHour(storeHour) {
    if (!storeHour?.date) {
      return storeHour;
    }
    const dateWithOpen = new Date(`${this.formatDateForSafari(storeHour?.date)} ${storeHour?.openHours}`);
    const dateWithClose = new Date(`${this.formatDateForSafari(storeHour?.date)} ${storeHour?.closeHours}`);
    const hourList = [];
    for (const i = dateWithOpen; i < dateWithClose; i.setMinutes(i.getMinutes() + 30)) {
      const dateObj = {
        date: new Date(i),
        time: moment(i).format('LT')
      };
      hourList.push(dateObj);
    }
    return hourList;
  }

  private formatDateForSafari(date: string) {
    const dateArray = date.split('-');
    return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
  }

  public storeByNumber(storeNumber: number): PhysicalStore {
    return this.stores.find(store => store.storeNumber === storeNumber);
  }

  public getStoreAbbreviatedName(storeNumber: number): string {
    const store = this.storeByNumber(storeNumber);
    return store ? store.city?.substr(0, 1).toLowerCase() : '';
  }

  public getStoreFullName(storeNumber: number): string {
    const store = this.storeByNumber(storeNumber);
    return store ? store.city : '';
  }

  public getOtherStoreName(storeNumber: number): string {
    if (storeNumber === 1000) {
     return 'Brampton';
    } else {
      return 'Mississauga';
    }
  }

  public getStoreList(): StoreInfo[] {
    return this.stores.map(store => {
      return {
        storeNumber: store.storeNumber, city: store.city
      };
    });
  }

  private checkHolidayHours(store: PhysicalStore, date: Date): StoreHours {
    if (!store || !date) {
      return null;
    }

    const holidayHours = store.holidays.filter(holiday => {
      const holidayDate = holiday.date.split('-');
      return +holidayDate[0] === date.getFullYear()
        && +holidayDate[1] === date.getMonth() + 1
        && +holidayDate[2] === date.getDate();
    });

    if (holidayHours.length > 0) {
      return {
        ...holidayHours[0],
        openHours: null,
        closeHours: null,
        storeClosed: true
      };
    }

    return null;
  }

  private getSpecialDayHours(store: PhysicalStore, date: Date): StoreHours {
    const specialDays: SpecialDay[] = store?.specialDayHours?.days;

    let specialDay = null;
    if (specialDays != null) {
      specialDays.forEach(day => {
        const specialDateValues: string[] = day.date.split('-');
        if (date.getFullYear() === +specialDateValues[0]
          && (date.getMonth() + 1) === +specialDateValues[1]
          && date.getDate() === +specialDateValues[2]) {
          specialDay = day;
          specialDay.closeHours = false;
        }
      });
    }
    return specialDay;
  }

}
