import {createFeatureSelector, createSelector} from '@ngrx/store';
import {Employee, EmployeeRole} from 'src/app/core/models/common.model';
import {MasterDataState, masterDataStateKey} from 'src/app/store/master-data/master-data.reducer';

// tslint:disable-next-line:no-namespace
export namespace MasterDataSelectors {

  export const masterDataState = createFeatureSelector(masterDataStateKey);

  export const masterData = createSelector(
    masterDataState,
    (state: MasterDataState) => state);

  // Stores
  export const stores = createSelector(
    masterDataState,
    (state: MasterDataState) => state.stores);

  // Current Register
  export const register = createSelector(
    masterDataState,
    (state: MasterDataState) => state.register);

  // Menus
  export const menus = createSelector(
    masterDataState,
    (state: MasterDataState) => state.menus);

  // Employees
  export const employees = createSelector(
    masterDataState,
    (state: MasterDataState) => state.employees);

  export const employeesByStoreNumber = (storeNumber: number) => createSelector(
    employees,
    (allEmployees: Employee[]) => {
      return allEmployees.filter(employee => employee.store.indexOf(storeNumber) > -1);
    });

  export const employeesByStoreAndRole = (storeNumber: number, role: EmployeeRole) => createSelector(
    employees,
    (allEmployees: Employee[]) =>
      allEmployees.filter(employee =>
        employee.store.indexOf(storeNumber) > -1
        && employee.role.indexOf(role) > -1));

  export const searchEmployees =  createSelector(
    masterDataState,
    (state: MasterDataState) => state?.employees);

  // Country Code
  export const countryCodes = createSelector(
    masterDataState,
    (state: MasterDataState) => state.countryCodes);

  export const selectedEmployee = (employeeId: number) => createSelector(
    masterDataState,
    (state: MasterDataState) => state.employees.find(obj => {
      return employeeId === obj.id;
    })
  );

}
