import {Injectable} from '@angular/core';
import {PhysicalStore} from 'src/app/core/api-models/physical-store';
import {Employee, TabDetails, EmployeeModalConfig, Medium, EmployeeRole} from 'src/app/core/models/common.model';
import {Observable, of} from 'rxjs';
import {EmployeeModalComponent} from 'src/app/common/popups/employee-modal/employee-modal.component';
import {PopupData, PopupIcon, PopupReturnValue, PopupType} from 'src/app/core/models/popup.model';
import {ModalService} from 'src/app/core/services/modals/modal.service';
import {catchError, first, map, switchMap, tap} from 'rxjs/operators';
import {PopupModalService} from 'src/app/core/services/modals/popup-modal.service';
import {PhysicalStoreService} from 'src/app/store/master-data/physical-store.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeModalService {

  constructor(
    private modalService: ModalService,
    private popModalService: PopupModalService,
    private physicalStore: PhysicalStoreService
  ) {
  }

  public getDummyEmployee(): Employee {
    return {
      id: 0,
      firstName: 'No',
      lastName: 'Name',
      fullName: 'No Name',
      nickName: 'No Name',
      role: [EmployeeRole.employee],
      store: [this.physicalStore.currentStore.storeNumber],
      active: true
    };
  }

  public getEmployeeMedium(initialEmployee: Employee = null,
                           initialMedium: Medium = null,
                           modalConfig: EmployeeModalConfig,
                           store?: number,
                           role?: EmployeeRole,
                           hideX?: boolean): Observable<TabDetails> {
    return this.modalService.open(EmployeeModalComponent, {
      modalConfig,
      employee: initialEmployee,
      medium: initialMedium,
      store,
      role,
      hideX,
      autoClose: modalConfig === EmployeeModalConfig.EmployeeOnly
    }).pipe(
      first(),
      map((result) => result as TabDetails)
    );
  }

  public getEmployee(initialEmployee: Employee = null,
                     store?: number,
                     autoClose?: boolean,
                     role?: EmployeeRole): Observable<Employee> {
    return this.modalService.open(EmployeeModalComponent, {
      modalConfig: EmployeeModalConfig.EmployeeOnly,
      employee: initialEmployee,
      medium: null,
      role: null,
      hideX: false,
      store,
      autoClose: autoClose || true
    }).pipe(
      first(),
      map((result) => result?.employee || null)
    );
  }

  public getEmployeeWithPassword(initialEmployee: Employee = null,
                                 store?: number,
                                 autoClose?: boolean,
                                 role?: EmployeeRole): Observable<Employee> {
    return this.modalService.open(EmployeeModalComponent, {
      modalConfig: EmployeeModalConfig.EmployeeWithPassword,
      employee: initialEmployee,
      medium: null,
      role: role || null,
      hideX: false,
      store,
      autoClose
    }).pipe(
      first(),
      map((result) => result?.employee)
    );
  }

  public getEmployeeWithManagerApproval(): Observable<{ employee: Employee, manager: Employee }> {
    let employee: Employee = null;
    let manager: Employee = null;
    return this.getEmployee()
      .pipe(
        first(),
        switchMap(selectedEmployee => {
          employee = selectedEmployee;

          const cancelWarning: PopupData = {
            title: 'Second Approval Required',
            message: `Are you sure, you want to Proceed?
                <br><br>You will need Another Person's Approval to Proceed.`,
            icon: PopupIcon.warning,
            type: PopupType.warning,
            needCancel: true
          };

          return this.popModalService.openPopup(cancelWarning);
        }),
        switchMap(result => {
          if (result === PopupReturnValue.PrimaryButton) {
            return this.getEmployeeMedium(
              null, null, EmployeeModalConfig.EmployeeWithPassword);
          }
          return of(null);
        }),
        switchMap(employeeMedium => {
          if (employeeMedium) {
            manager = employeeMedium.employee;
            if (manager.id === employee.id) {
              const sameEmployee: PopupData = {
                title: 'Need Two People',
                message: 'Same Person Can\'t provide approval?' +
                  '<br><br>You will need another person to approve this.',
                icon: PopupIcon.warning,
                type: PopupType.warning,
                needCancel: false
              };

              return this.popModalService.openPopup(sameEmployee).pipe(map(() => {
                return null;
              }));
            }
          }
          if (employee && manager) {
            return of({employee, manager});
          }
          return of(null);
        }),
        catchError(() => of(null))
      );
  }
}
