import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, first, flatMap, map} from 'rxjs/operators';
import {Transaction} from 'src/app/core/api-models/transaction';
import {HttpClientWrapper} from 'src/app/core/api/http-wrapper.service';
import {Constants} from 'src/app/core/constants/constants';
import {AppStore} from 'src/app/store/index.reducer';
import {PublicActions, PublicActionTypes} from 'src/app/store/public/public.actions';


@Injectable()
export class PublicEffects {

  constructor(private actions$: Actions,
              private store: Store<AppStore>,
              private httpClientWrapper: HttpClientWrapper) {
  }

  @Effect()
  loadPublicTransaction = this.actions$.pipe(
    ofType(PublicActionTypes.LoadTransaction),
    flatMap((action: PublicActions.LoadTransaction) => {
      const {transactionId, shortCode} = action.payload;
      return this.getTransaction(shortCode, transactionId).pipe(
        first(),
        map((data: Transaction | { error: string }) => {
            if ((data as any).error) {
              return new PublicActions.LoadTransactionError((data as any).error);
            } else {
              return new PublicActions.LoadTransactionSuccess(data as Transaction);
            }
          }
        ),
        catchError((error: Error) => {
            return of(new PublicActions.LoadTransactionError(error?.message || error.toString()));
          }
        )
      );
    }));

  @Effect()
  SearchPublicTransactionIdPhone = this.actions$.pipe(
    ofType(PublicActionTypes.SearchTransactionByIdPhone),
    flatMap((action: PublicActions.SearchTransactionByIdPhone) => {
      const {transactionId, phone} = action.payload;
      return this.getTransactionByPhone(transactionId, phone).pipe(
        first(),
        map((data: Transaction | { error: string }) => {
            if ((data as any).error) {
              return new PublicActions.LoadTransactionError((data as any).error);
            } else {
              return new PublicActions.LoadTransactionSuccess(data as Transaction);
            }
          }
        ),
        catchError((error: Error) => {
            return of(new PublicActions.LoadTransactionError(error?.message || error.toString()));
          }
        )
      );
    }));

  getTransaction(shortCode: string, transactionId: number): Observable<Transaction | { error: string }> {
    const query = `shortCode=${shortCode}&transactionId=${transactionId}`;
    return this.httpClientWrapper.get<Transaction>(`${Constants.apiPaths.publicTransaction}?${query}`);
  }

  getTransactionByPhone(transactionId: number, phone: string): Observable<Transaction | { error: string }> {
    return this.httpClientWrapper.get<Transaction>(
      `${Constants.apiPaths.publicTransactionIdPhone}/${transactionId}/${phone}`
    );
  }

}
