import {AddressParams} from 'src/app/core/models/common.model';
import {BaseAction} from 'src/app/store/models/base-action';
import {PayloadAction} from 'src/app/store/models/payload-action';
import {Address, Customer} from 'src/app/core/api-models/order';

export enum CustomerActionTypes {
  LoadCustomer = '[Customer] Load Customer',
  LoadCustomerSuccess = '[Customer] Load Stores Success',
  LoadCustomerError = '[Customer] Load Stores Error',
  AddNewAddress = '[Customer - Address] Add New Address',
  UpdateAddress = '[Customer - Address] Update Address',
  UpdateAddressError = '[Customer - Address] Update Address Error',
  UpdateAddressSuccess = '[Customer - Address] Update Address Success',
  DeleteAddress = '[Customer - Address] Delete Address',
}

// tslint:disable-next-line:no-namespace
export namespace CustomerActions {

  export class LoadCustomer extends BaseAction {
    readonly type = CustomerActionTypes.LoadCustomer;
  }

  export class LoadCustomerSuccess extends PayloadAction<Customer[]> {
    readonly type = CustomerActionTypes.LoadCustomerSuccess;
  }

  export class LoadCustomerError extends PayloadAction<string> {
    readonly type = CustomerActionTypes.LoadCustomerError;
  }
  export class AddNewAddress extends PayloadAction<AddressParams> {
    readonly type = CustomerActionTypes.AddNewAddress;
  }
  export class UpdateAddress extends PayloadAction<AddressParams> {
    readonly type = CustomerActionTypes.UpdateAddress;
  }
  export class UpdateAddressError extends PayloadAction<string> {
    readonly type = CustomerActionTypes.UpdateAddressError;
  }
  export class UpdateAddressSuccess extends PayloadAction<string> {
    readonly type = CustomerActionTypes.UpdateAddressSuccess;
  }
  export class DeleteAddress extends PayloadAction<AddressParams> {
    readonly type = CustomerActionTypes.DeleteAddress;
  }

}
