import {Pipe, PipeTransform} from '@angular/core';
import {PhoneNumber} from 'src/app/core/models/common.model';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(phone: string | PhoneNumber): string {
    let countryCode = '';
    let phoneNumber = '';
    let extension = '';
    let result = '';

    if (!phone) {
      return '';
    }

    if (typeof phone === 'object') {
      countryCode = phone.countryCode.toString();
      phoneNumber = phone.number.toString();
      extension = phone.extension.toString();
    }

    if (typeof phone === 'string') {
      phoneNumber = phone;
    }

    if (phoneNumber && phoneNumber.length > 10) {
      countryCode = phoneNumber?.substr(0, phoneNumber.length - 10);
      phoneNumber = phoneNumber?.substr(phoneNumber.length - 10, phoneNumber.length);
    }

    if (phoneNumber.length > 8 && countryCode) {
      result = `+${countryCode} `;
      result = `${result}(${phoneNumber.substr(0, 3)}) ${phoneNumber.substr(3, 3)}-${phoneNumber.substr(6, 20)}`;
      if (extension && extension !== '0') {
        result = `${result} x${extension}`;
      }
    }

    return result;
  }

}
