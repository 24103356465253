import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {ToastrService} from 'ngx-toastr';
import {filter, first, switchMap, takeWhile} from 'rxjs/operators';
import CryptoJS from 'crypto-js';

import {Register, SetupRegisterParams} from 'src/app/core/api-models/register';
import {StoreName} from 'src/app/core/constants/common.enum';
import {Messages} from 'src/app/core/constants/messages.constants';
import {Routes} from 'src/app/core/constants/route-constants';
import {PopupData, PopupIcon, PopupType} from 'src/app/core/models/popup.model';
import {PopupModalService} from 'src/app/core/services/modals/popup-modal.service';
import {StorageService} from 'src/app/core/services/storage/storage.service';
import {UtilsService} from 'src/app/core/services/utils.service';
import {AppStore} from 'src/app/store/index.reducer';
import {MasterDataActions} from 'src/app/store/master-data/master-data.actions';
import {MasterDataState} from 'src/app/store/master-data/master-data.reducer';
import {MasterDataSelectors} from 'src/app/store/master-data/master-data.selectors';
import {from, Observable} from 'rxjs';

@Component({
  selector: 'rb-register-setup-page',
  templateUrl: './register-setup-page.component.html',
  styleUrls: ['./register-setup-page.component.scss']
})
export class RegisterSetupPageComponent implements OnInit, OnDestroy {
  masterData$ = this.store.select(MasterDataSelectors.masterData);
  register$ = this.store.select(MasterDataSelectors.register);

  formGroup: UntypedFormGroup;
  submitted = false;
  register: Register;
  alive = true;
  mismatchError = false;
  secretPassPhrase = '12383NAD#30343kddjhfje';
  referrer: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppStore>,
    private popModalService: PopupModalService,
    private toastr: ToastrService,
    private utils: UtilsService,
    private router: Router,
    private storageService: StorageService
  ) {
    this.register$.pipe(
      takeWhile(() => this.alive)
    )
      .subscribe(register => this.register = register);

    this.referrer = this.router.getCurrentNavigation().extras?.state?.referrer;
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      id: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required)
    });

    setTimeout(() => {
      this.getRegisterKeyAndPassword();
    });
  }

  setup() {
    this.submitted = true;

    if (this.formGroup.get('id').errors || this.formGroup.get('password').errors) {
      return;
    }

    const setupRegisterParams: SetupRegisterParams = {
      id: this.formGroup.controls.id.value.toString().trim(),
      password: this.formGroup.controls.password.value.toString().trim()
    };
    this.store.dispatch(new MasterDataActions.SetupRegister(setupRegisterParams));

    const setupError: PopupData = {
      title: Messages.setupRegister.error.title,
      message: Messages.setupRegister.error.message,
      icon: PopupIcon.alert,
      type: PopupType.alert,
      needCancel: false
    };

    const idPasswordError: PopupData = {
      ...setupError,
      message: Messages.setupRegister.idPasswordError.message
    };

    const setupSuccess: PopupData = {
      title: Messages.setupRegister.success.title,
      message: Messages.setupRegister.success.message,
      icon: PopupIcon.success,
      type: PopupType.success,
      needCancel: false
    };

    this.masterData$.pipe(
      filter((masterData: MasterDataState) => !masterData.registerLoading),
      first()
    ).subscribe((masterData: MasterDataState) => {
      if (masterData.registerError) {
        if (masterData.registerError === 'ID_PASSWORD_MISMATCH') {
          this.mismatchError = true;
        } else {
          this.popModalService.openPopup(setupError).pipe(first()).subscribe();
        }
      } else if (masterData.registerSuccess) {
        const {storeNumber, registerNumber} = this.register;
        const storeName = StoreName[storeNumber];

        // Save the key and password in local storage
        this.saveRegisterKeyAndPassword(this.formGroup.controls.id.value.toString().trim(),
          this.formGroup.controls.password.value.toString().trim());

        setupSuccess.messageParams = {storeName, registerNumber: registerNumber.toString()};
        this.popModalService.openPopup(setupSuccess).pipe(
          first(),
          switchMap(() => this.continueFlow())
        ).subscribe();
      }
    });
  }

  saveRegisterKeyAndPassword(id: string, password: string) {
    const encryptedId = CryptoJS.AES.encrypt(id, this.secretPassPhrase).toString();
    const encryptedPassword = CryptoJS.AES.encrypt(password, this.secretPassPhrase).toString();

    this.storageService.set('registerId', encryptedId);
    this.storageService.set('registerPass', encryptedPassword);
  }

  getRegisterKeyAndPassword() {
    const encryptedId = this.storageService.get('registerId');
    const encryptedPassword = this.storageService.get('registerPass');

    const id = CryptoJS.AES.decrypt(encryptedId, this.secretPassPhrase).toString(CryptoJS.enc.Utf8);
    const password = CryptoJS.AES.decrypt(encryptedPassword, this.secretPassPhrase).toString(CryptoJS.enc.Utf8);

    this.formGroup.controls.id.setValue(id);
    this.formGroup.controls.password.setValue(password);
  }

  goToPos() {
    this.continueFlow().subscribe();
  }
  
  continueFlow(): Observable<boolean> {
    if (this.referrer) {
      return from(this.router.navigateByUrl(this.referrer));
    } else {
      return this.utils.redirectToPos();
    }
  }

  @HostListener('document:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      this.setup();
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
