import {OpenTabData} from 'src/app/core/models/common.model';
import {TabAction} from 'src/app/store/models/tab-action';
import {NavigationActions} from 'src/app/store/navigation/navigation.actions';

export interface TabSegmentedState<T> {
  [tabId: string]: T;
}

export function generateTabSegmentState<T, U>(state: TabSegmentedState<T>,
                                              action: TabAction<U>,
                                              func: (segment: T, data: U) => T): TabSegmentedState<T> {
  const {tabId, data} = (action as TabAction<U>).payload;
  return {
    ...state,
    [tabId]: {
      ...func(state[tabId], data)
    }
  };
}

export function generateInitialTabSegmentState<T>(state: TabSegmentedState<T>,
                                                  action: NavigationActions.OpenTab,
                                                  initialSegment: T): TabSegmentedState<T> {
  const {id} = action.payload;

  return {
    ...state,
    [id]: {
      ...initialSegment
    }
  };
}

export function generateRemoveTabSegmentState<T>(state: TabSegmentedState<T>,
                                                 action: NavigationActions.RemoveTab): TabSegmentedState<T> {
  const newState = {...state};
  delete newState[action.payload];
  return newState;
}
