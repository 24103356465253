export enum PopupIcon {
  info = 'info',
  success = 'success',
  alert = 'alert',
  warning = 'warning'
}

export enum PopupType {
  info = 'info',
  success = 'success',
  alert = 'alert',
  warning = 'warning'
}

export enum PopupReturnValue {
  PrimaryButton = 'P',
  SecondaryButton = 'S',
  XButton = 'X'
}

export interface PopupData {
  title?: string;
  titleParams?: { [key: string]: string };
  header?: string;
  headerParams?: { [key: string]: string };
  message?: string;
  messageParams?: { [key: string]: string };
  footer?: string;
  footerParams?: { [key: string]: string };
  primaryButtonText?: string;
  secondaryButtonText?: string;
  returnValue?: PopupReturnValue;
  needOk?: boolean; // default is true
  needCancel?: boolean; // default is true
  icon?: PopupIcon;
  type?: PopupType;
  closable?: boolean; // Show X button, default is true
  escapable?: boolean; // Show X button, default is false
  dismissible?: boolean; // Show X button, default is false
  width?: number | string;
  widthPercentage?: boolean;
  maxWidth?: number | string;
  maxWidthPercentage?: boolean;
}
