import {ComponentFactoryResolver, ComponentRef, Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {LoaderModalComponent} from 'src/app/common/popups/loader-modal/loader-modal.component';
import {PopupModalComponent} from 'src/app/common/popups/popup-modal/popup-modal.component';

import {PopupData, PopupReturnValue} from 'src/app/core/models/popup.model';
import {ModalService} from 'src/app/core/services/modals/modal.service';

@Injectable({
  providedIn: 'root'
})
export class PopupModalService {
  returnValue = PopupReturnValue.XButton;
  loaderModalComponent = LoaderModalComponent;

  constructor(
    private modalService: ModalService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
  ) {
  }

  openPopup(popupData: PopupData): Observable<PopupReturnValue> {
    this.returnValue = PopupReturnValue.XButton;

    return this.modalService.open(PopupModalComponent, popupData)
      .pipe(map((result) => (result || this.returnValue) as PopupReturnValue));
  }

  showLoader(message: string) {
    // const popupData: PopupData = {message};
    // this.modalService.open(this.loaderModalComponent, popupData)
    //   .pipe(first())
    //   .subscribe();
  }

  hideLoader() {
    // const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.loaderModalComponent);
    // const componentRef = componentFactory.create(this.injector);
    // const modalInstance = componentRef.instance;
    // modalInstance.modalResult.next(null);
  }

}
