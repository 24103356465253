import {Injectable} from '@angular/core';
import {CanLoad, Route, UrlSegment} from '@angular/router';
import {securityKey} from 'src/app/core/constants/constants';
import {StorageService} from 'src/app/core/services/storage/storage.service';
import {UtilsService} from 'src/app/core/services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class PosLoadGuard implements CanLoad {
  constructor(
    private storageService: StorageService,
    private utils: UtilsService,
  ) {
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): boolean {
    if (this.storageService.get(securityKey)
      && this.storageService.get(securityKey).refreshToken
      && this.storageService.get(securityKey).registerNumber
      && this.storageService.get(securityKey).storeNumber) {
      return true;
    } else {
      let referrerLink = segments.join('/') > route.path ? segments.join('/') : '';
      this.utils.redirectToSetupRegister(referrerLink)
        .subscribe();
      return false;
    }
  }
}
