import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, first, flatMap, map, tap} from 'rxjs/operators';
import {Transaction} from 'src/app/core/api-models/transaction';
import {HttpClientWrapper} from 'src/app/core/api/http-wrapper.service';
import {Constants} from 'src/app/core/constants/constants';
import {DeliveryActions, DeliveryActionTypes} from 'src/app/store/delivery/delivery.actions';
import {AppStore} from 'src/app/store/index.reducer';
import {PublicActions} from 'src/app/store/public/public.actions';


@Injectable()
export class DeliveryEffects {

  constructor(private actions$: Actions,
              private store: Store<AppStore>,
              private httpClientWrapper: HttpClientWrapper) {
  }

  @Effect()
  loadDeliveryTransaction = this.actions$.pipe(
    ofType(DeliveryActionTypes.LoadTransaction),
    flatMap((action: DeliveryActions.LoadTransaction) => {
      const {transactionId, randomId} = action.payload;
      return this.getTransaction(randomId, transactionId).pipe(
        first(),
        map((data: Transaction | { error: string }) => {
            if ((data as any).error) {
              return new DeliveryActions.LoadTransactionError((data as any).error);
            } else {
              return new DeliveryActions.LoadTransactionSuccess(data as Transaction);
            }
          }
        ),
        catchError((error: Error) => {
            return of(new DeliveryActions.LoadTransactionError(error?.message || error.toString()));
          }
        )
      );
    }));

  getTransaction(randomId: string, transactionId: number): Observable<Transaction | { error: string }> {
    const query = `randomId=${randomId}&transactionId=${transactionId}`;
    return this.httpClientWrapper.get<Transaction>(`${Constants.apiPaths.deliveryTransaction}?${query}`);
  }

}
