/* tslint:disable:max-classes-per-file */
/* tslint:disable:import-blacklist */
import { PayloadAction } from 'src/app/store/models/payload-action';
import {Employee, NavigationTabId, OpenTabData, RequestTabData} from 'src/app/core/models/common.model';
import { PageType } from 'src/app/core/constants/common.enum';

export enum NavigationActionTypes {
  RequestTab = '[Navigation] Request Tab',
  OpenTab = '[Navigation] Open Tab',
  SetActiveTab = '[Navigation] Set Active Tab',
  RemoveTab = '[Navigation] Remove Tab',
  UpdateTabType = '[Navigation] Update Tab Type',
  UpdateTabEmployee = '[Navigation] Update Tab Employee'
}

// tslint:disable-next-line:no-namespace
export namespace NavigationActions {

  export class RequestTab extends PayloadAction<RequestTabData> {
    readonly type = NavigationActionTypes.RequestTab;
  }

  export class OpenTab extends PayloadAction<OpenTabData> {
    readonly type = NavigationActionTypes.OpenTab;
  }

  export class SetActiveTab extends PayloadAction<NavigationTabId> {
    readonly type = NavigationActionTypes.SetActiveTab;
  }

  export class RemoveTab extends PayloadAction<NavigationTabId> {
    readonly type = NavigationActionTypes.RemoveTab;
  }

  export class UpdateTabType extends PayloadAction<{id: NavigationTabId, type: PageType}> {
    readonly type = NavigationActionTypes.UpdateTabType;
  }

  export class UpdateTabEmployee extends PayloadAction<{id: NavigationTabId, employee: Employee}> {
    readonly type = NavigationActionTypes.UpdateTabEmployee;
  }
}
