import {Menu} from 'src/app/core/api-models/menu.model';
import {PhysicalStore} from 'src/app/core/api-models/physical-store';
import {Register, SetupRegisterParams, SetupRegisterResponse} from 'src/app/core/api-models/register';
import {Country, Employee, EmployeeSearchParam, VerifyPasswordParams} from 'src/app/core/models/common.model';
import {BaseAction} from 'src/app/store/models/base-action';
import {PayloadAction} from 'src/app/store/models/payload-action';

export enum MasterDataActionTypes {
  LoadMenus = '[Master Data - Menu] Load Menus',
  LoadMenusSuccess = '[Master Data - Menu] Load Menus Success',
  LoadMenusError = '[Master Data - Menu] Load Menus Error',

  SetupRegister = '[Master Data - Register] Setup Register',
  SetupRegisterSuccess = '[Master Data - Register] Setup Register Success',
  SetupRegisterError = '[Master Data - Register] Setup Register Error',

  StoreToken = '[Master Data - Token] Store Token',
  UpdateRefreshToken = '[Master Data - Token] Update Refresh Token',
  RefreshTokenSuccess = '[Master Data - Token] Refresh Token Success',
  RefreshTokenError = '[Master Data - Token] Refresh Token Error',

  LoadRegisterInfo = '[Master Data - Register] Load Register Info',

  LoadStores = '[Master Data - Store] Load Stores',
  LoadStoresSuccess = '[Master Data - Store] Load Stores Success',
  LoadStoresError = '[Master Data - Store] Load Stores Error',

  LoadEmployees = '[Master Data - Employee] Load Employees',
  LoadEmployeesSuccess = '[Master Data - Employee] Load Employees Success',
  LoadEmployeesError = '[Master Data - Employee] Load Employees Error',

  VerifyPassword = '[Master Data - Employee] Verify Password',
  VerifyPasswordSuccess = '[Master Data - Employee] Verify Password Success',
  VerifyPasswordError = '[Master Data - Employee] Verify Password Error',

  LoadCountryCodes = '[Master Data - Country Codes] Load Country Codes',
  LoadCountryCodesSuccess = '[Master Data - Country Codes] Load Country Codes Success',
  LoadCountryCodesError = '[Master Data - Country Codes] Load Country Codes Error',
}

// tslint:disable-next-line:no-namespace
export namespace MasterDataActions {

  export class LoadMenus extends BaseAction {
    readonly type = MasterDataActionTypes.LoadMenus;
  }

  export class LoadMenusSuccess extends PayloadAction<Menu[]> {
    readonly type = MasterDataActionTypes.LoadMenusSuccess;
  }

  export class LoadMenusError extends PayloadAction<string> {
    readonly type = MasterDataActionTypes.LoadMenusError;
  }

  export class SetupRegister extends PayloadAction<SetupRegisterParams> {
    readonly type = MasterDataActionTypes.SetupRegister;
  }

  export class SetupRegisterSuccess extends PayloadAction<Register> {
    readonly type = MasterDataActionTypes.SetupRegisterSuccess;
  }

  export class SetupRegisterError extends PayloadAction<string> {
    readonly type = MasterDataActionTypes.SetupRegisterError;
  }

  export class StoreToken extends PayloadAction<SetupRegisterResponse> {
    readonly type = MasterDataActionTypes.StoreToken;
  }

  export class UpdateRefreshToken extends BaseAction {
    readonly type = MasterDataActionTypes.UpdateRefreshToken;
  }

  export class RefreshTokenSuccess extends PayloadAction<boolean> {
    readonly type = MasterDataActionTypes.RefreshTokenSuccess;
  }

  export class RefreshTokenError extends PayloadAction<boolean> {
    readonly type = MasterDataActionTypes.RefreshTokenError;
  }

  export class LoadStores extends BaseAction {
    readonly type = MasterDataActionTypes.LoadStores;
  }

  export class LoadStoresSuccess extends PayloadAction<PhysicalStore[]> {
    readonly type = MasterDataActionTypes.LoadStoresSuccess;
  }

  export class LoadStoresError extends PayloadAction<string> {
    readonly type = MasterDataActionTypes.LoadStoresError;
  }

  export class LoadEmployees extends BaseAction {
    readonly type = MasterDataActionTypes.LoadEmployees;
  }

  export class LoadEmployeesSuccess extends PayloadAction<Employee[]> {
    readonly type = MasterDataActionTypes.LoadEmployeesSuccess;
  }

  export class LoadEmployeesError extends PayloadAction<string> {
    readonly type = MasterDataActionTypes.LoadEmployeesError;
  }

  export class VerifyPassword extends PayloadAction<VerifyPasswordParams> {
    readonly type = MasterDataActionTypes.VerifyPassword;
  }

  export class VerifyPasswordSuccess extends PayloadAction<boolean> {
    readonly type = MasterDataActionTypes.VerifyPasswordSuccess;
  }

  export class VerifyPasswordError extends PayloadAction<string> {
    readonly type = MasterDataActionTypes.VerifyPasswordError;
  }

  export class LoadCountryCodes extends BaseAction {
    readonly type = MasterDataActionTypes.LoadCountryCodes;
  }

  export class LoadCountryCodesSuccess extends PayloadAction<Country[]> {
    readonly type = MasterDataActionTypes.LoadCountryCodesSuccess;
  }

  export class LoadCountryCodesError extends PayloadAction<string> {
    readonly type = MasterDataActionTypes.LoadCountryCodesError;
  }
}
