import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {HttpClientWrapper} from 'src/app/core/api/http-wrapper.service';
import {NavigationTabId} from 'src/app/core/models/common.model';
import {AppStore} from 'src/app/store/index.reducer';
import {catchError, first, flatMap, map, tap} from 'rxjs/operators';
import {Constants} from 'src/app/core/constants/constants';
import {Observable, of} from 'rxjs';
import {NavigationService} from 'src/app/core/services/navigation.service';
import {CustomerActions, CustomerActionTypes} from 'src/app/store/customer/customer.actions';
import {Address, Customer} from 'src/app/core/api-models/order';
import {SaleActions} from 'src/app/store/sale/sale.actions';

@Injectable()
export class CustomerEffects {

  constructor(private actions$: Actions,
              private store: Store<AppStore>,
              private navigation: NavigationService,
              private httpClientWrapper: HttpClientWrapper) {
  }

  @Effect()
  loadCustomer = this.actions$.pipe(
    ofType(CustomerActionTypes.LoadCustomer),
    flatMap((action: CustomerActions.LoadCustomer) => {
      return this.getCustomers().pipe(
        first(),
        map((data: Customer[]) =>
          new CustomerActions.LoadCustomerSuccess(data)
        ),
        catchError((error: string) =>
          of(new CustomerActions.LoadCustomerError(error))
        )
      );
    }));

  @Effect({dispatch: false})
  updateAddresses = this.actions$.pipe(
    ofType(CustomerActionTypes.UpdateAddress),
    flatMap((action: CustomerActions.UpdateAddress) => {
      const address: Address = action.payload.address;
      const tabId: NavigationTabId = action.payload.tabId;
      return this.updateAddress(address).pipe(
        first(),
        tap((data: Address) =>
            this.store.dispatch(
              new SaleActions.UpdateOrderCustomerAddress({tabId, data}))
        ),
        map((data: Address) =>
          this.store.dispatch(
            new SaleActions.UpdateTransactionOrder({tabId, data: {deliveryAddress : data}}))
        ),
        catchError((error: string) =>
          of(new CustomerActions.UpdateAddressError(error))
        )
      );
    }));

  @Effect({dispatch: false})
  addNewAddresses = this.actions$.pipe(
    ofType(CustomerActionTypes.AddNewAddress),
    flatMap((action: CustomerActions.AddNewAddress) => {
      const address: Address = action.payload.address;
      const tabId: NavigationTabId = action.payload.tabId;
      return this.addAddress(address).pipe(
        first(),
        tap((data: Address) =>
          this.store.dispatch(
            new SaleActions.UpdateOrderCustomerAddress({tabId, data}))
        ),
        map((data: Address) =>
          this.store.dispatch(
            new SaleActions.UpdateTransactionOrder({tabId, data: {deliveryAddress : data}}))
        ),
        catchError((error: string) =>
          of(new CustomerActions.UpdateAddressError(error))
        )
      );
    }));

  @Effect({dispatch: false})
  deleteAddress = this.actions$.pipe(
    ofType(CustomerActionTypes.DeleteAddress),
    flatMap((action: CustomerActions.DeleteAddress) => {
      const address: Address = action.payload.address;
      const tabId: NavigationTabId = action.payload.tabId;
      return this.deleteSelectedAddress(address).pipe(
        first(),
        tap((data: Address) =>
          this.store.dispatch(
            new SaleActions.DeleteCustomerAddress({tabId, data}))
        ),
        map((data: Address) =>
          this.store.dispatch(
            new SaleActions.RemoveOrderItem({tabId, data: ['deliveryAddress']}))
        ),
        catchError((error: string) =>
          of(new CustomerActions.UpdateAddressError(error))
        )
      );
    }));

  getCustomers(): Observable<Customer[]> {
    return this.httpClientWrapper.get<Customer[]>(Constants.apiPaths.getCustomers);
  }

  updateAddress(address: Address): Observable<Address> {
    const employee = this.navigation.currentEmployee?.fullName;
    return this.httpClientWrapper.put(employee, `${Constants.apiPaths.updateAddress}/${address.id}`, address);
  }

  deleteSelectedAddress(address: Address): Observable<Address> {
    const employee = this.navigation.currentEmployee?.fullName;
    return this.httpClientWrapper.delete(employee, `${Constants.apiPaths.updateAddress}/${address.id}`);
  }

  addAddress(address: Address): Observable<Address> {
    const employee = this.navigation.currentEmployee?.fullName;
    return this.httpClientWrapper.post(employee, Constants.apiPaths.updateAddress, address);
  }

}
