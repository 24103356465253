import * as cuid from 'cuid';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {NavigationService} from 'src/app/core/services/navigation.service';
import {AppStore} from 'src/app/store/index.reducer';
import {PhysicalStoreService} from 'src/app/store/master-data/physical-store.service';
import {NavigationActions, NavigationActionTypes} from 'src/app/store/navigation/navigation.actions';
import {catchError, first, flatMap, map, tap} from 'rxjs/operators';
import {Config, pageConfig} from 'src/app/core/constants/constants';
import {Employee, NavigationTab, NavigationTabId, OpenTabData, TabDetails} from 'src/app/core/models/common.model';
import {EmployeeModalService} from 'src/app/core/services/modals/employee-modal.service';
import {of, throwError} from 'rxjs';
import {homePageTabName, NavigationState, navigationStateKey} from 'src/app/store/navigation/navigation.reducer';
import {PopupData, PopupIcon} from 'src/app/core/models/popup.model';
import {PopupModalService} from 'src/app/core/services/modals/popup-modal.service';
import {NavigationSelectors} from 'src/app/store/navigation/navigation.selectors';
import {SaleActions} from 'src/app/store/sale/sale.actions';

@Injectable()
export class NavigationEffects {
  constructor(private actions$: Actions,
              private store: Store<AppStore>,
              private employeeModalService: EmployeeModalService,
              private modalService: PopupModalService,
              private physicalStoreService: PhysicalStoreService,
              private navigation: NavigationService) {
  }

  @Effect({dispatch: false})
  tabRequest$ = this.actions$.pipe(
    ofType(NavigationActionTypes.RequestTab),
    flatMap((action: NavigationActions.RequestTab) => {
      return this.store.select(navigationStateKey).pipe(
        first(),
        map((state: NavigationState) => state.tabIds.length),
        flatMap((tabs: number) => {
          if (tabs >= Config.navigation.maxTabs) {
            return this.showMaxModal().pipe(
              flatMap(() => throwError(null))
            );
          }
          return of(action);
        })
      );
    }),
    flatMap((initialAction: NavigationActions.RequestTab) => {
      return of(initialAction).pipe(
        flatMap((action: NavigationActions.RequestTab) => {
          const metadata = pageConfig[action.payload.type];
          if (initialAction.payload?.initialData?.changeTransaction) {
            return this.employeeModalService.getEmployeeWithPassword(null, null,
              false, metadata.role)
              .pipe(
                map((employee: Employee) => {
                  return {
                    ...action.payload,
                    details: {
                      employee,
                      medium: null,
                      extra: {type: 'change-order', transactionId: initialAction.payload?.initialData?.transactionId}
                    },
                  };
                })
              );
          } else if (metadata.employeeModalConfig) {
            return this.employeeModalService.getEmployeeMedium
            (null, null, metadata.employeeModalConfig,
              this.physicalStoreService.currentStore.storeNumber, metadata.role).pipe(
              map((tabDetails: TabDetails) => {
                return {
                  ...action.payload,
                  details: tabDetails
                };
              })
            );
          } else {
            return of(action.payload);
          }
        }),
        flatMap((openTabData: Partial<OpenTabData> | null) =>
          this.store.select(NavigationSelectors.navigationState).pipe(
            first(),
            map((state: NavigationState) => [state, openTabData])
          )
        ),
        tap((res: [NavigationState, Partial<OpenTabData>]) => {
          const state = res[0];
          const partialOpenTabData = res[1];
          const alreadyOpenTab = state.tabIds
            .map((tabId: NavigationTabId) => state.tabs[tabId])
            .find((tab: NavigationTab) => tab.type === partialOpenTabData.type);
          if (alreadyOpenTab && !alreadyOpenTab.allowMultiple) {
            this.store.dispatch(new NavigationActions.SetActiveTab(alreadyOpenTab.id));
          } else {
            // const existingIds: number[] = state.tabIds.map((id: NavigationTabId) => +id);
            // const maxId = existingIds.length > 0 ? Math.max(...existingIds) : -1;
            // const newId = (maxId ? maxId + 1 : +navigationHomeTabId + 1).toString();
            const newId = state.homeTabId ? cuid.slug() : homePageTabName;
            const openTabData: OpenTabData = {
              ...partialOpenTabData,
              id: newId
            } as OpenTabData;
            this.store.dispatch(new NavigationActions.OpenTab(openTabData));

            if (openTabData?.details?.extra?.type === 'change-order') {
              setTimeout(() => {
                this.navigation.dispatchTabAction((tabId) =>
                  new SaleActions.ChangeTransaction({tabId, data: {tabId, id: openTabData?.details?.extra?.transactionId}}));
              });
            }
          }
        }),
        catchError((err) => {
          return of(null);
        })
      );
    }),
  );

  /* Helper functions */
  private showMaxModal() {
    const popupData: PopupData = {
      title: 'Warning',
      header: `You can only open max of ${Config.navigation.maxTabs} navs`,
      needCancel: false,
      icon: PopupIcon.warning
    };
    return this.modalService.openPopup(popupData).pipe(
      first()
    );
  }
}
