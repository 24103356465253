<div class="rb-dialog employee-modal">
  <div>
    <div class="rb-modal-header">
      <div *ngIf="!hideX" class="rb-dialog-btn-close" (click)="closeModal()">✕</div>
      <div class="modal-header-copy"
           [innerHTML]="title"></div>
    </div>
    <div class="employee-modal__body">
      <section class="section__employee">
        <div class="d-flex justify-content-between align-items-center mb-4">
          <div class="header">YOUR NAME</div>
          <ng-template #employeeContent>
            <span
              [innerHTML]="'Click here to refresh employees information'">
            </span>
          </ng-template>
          <span class="pointer"
                [ngbPopover]="employeeContent"
                [popoverClass]="'popover-class'"
                [placement]="'auto'"
                [triggers]="'mouseenter:mouseleave'"
                (click)="refreshEmployees()">
            <i class="rb-icon-restart -mr8"></i>
          </span>
        </div>
        <div class="section__employee__btn-group">
          <button *ngFor="let employee of employees"
                  class="rb-btn-secondary-dark"
                  [class.selected]="employee.id === selectedEmployee?.id"
                  (click)="selectEmployee(employee)">
            {{employee.nickName}}
          </button>
        </div>
        <div *ngIf="employeeMissing" class="alert alert-dark">Name is required</div>
      </section>
      <section *ngIf="modalConfig === EmployeeModalConfig.EmployeeWithPassword" class="section__password">
        <div class="header -mb16">PASSWORD</div>
        <div class="search-container">
          <div class="search-button"><i class="rb-icon-lock-password"></i></div>
          <input #passwordField type="password" class="search-box form-control"
                 placeholder="Enter Password"
                 [formControl]="password">
        </div>
        <!-- TODO: Please fix the classes for next two errors -->
        <div *ngIf="passwordMissing" class="alert alert-dark">Password is required</div>
        <div *ngIf="passwordInvalid" class="alert alert-dark">You password doesn't match selected user</div>
      </section>
      <section *ngIf="modalConfig === EmployeeModalConfig.EmployeeAndMedium" class="section__medium">
        <rb-employee-medium
          [medium]="selectedMedium"
          (updateMedium)="selectMedium($event)">
        </rb-employee-medium>
        <div *ngIf="mediumMissing" class="alert alert-dark">Medium is required</div>
      </section>
    </div>
    <div *ngIf="!autoClose" class="employee-modal__footer">
      <button class="rb-btn-primary-green footer-ok-btn"
              (click)="okClicked()">OK
      </button>
    </div>
  </div>
</div>
