import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { AppLoadService } from 'src/app/core/services/app-load.service';
import {BaseTabComponent} from 'src/app/core/services/base-tab.component';

export function init_app(appLoadService: AppLoadService) {
  return () => appLoadService.initializeApp();
}

@NgModule({
  imports: [
  ],
  exports: [
    BaseTabComponent,
  ],
  providers: [
    {provide: APP_INITIALIZER, useFactory: init_app, deps: [AppLoadService], multi: true}
  ],
  bootstrap: [],
  declarations: [
    BaseTabComponent,
  ]
})
export class CoreModule {
  /* make sure CoreModule is imported only by one NgModule the AppModule */
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
