import {OrderStatusEnum} from 'src/app/core/constants/common.enum';
import {Medium, NavigationTab, NavigationTabId, PhoneNumber} from 'src/app/core/models/common.model';

export interface Order {
  orderId: number;
  medium: Medium;
  risk: string;
  fulfillmentDate: string;
  fulfillmentTimeStart: string;
  fulfillmentTimeEnd: string;
  fulfillmentStoreNumber: number;
  fulfillmentMethod: FulfillmentMethod;
  deliveryAddress: Address;
  temporaryAddresses: Address[]; // not needed to save in Backend
  searchedCustomers: Customer[]; // not needed
  customersLoading: boolean; // not needed
  customersError: string; //  not needed
  createdCustomer: Customer; //  not needed
  createCustomerLoading: boolean; //  not needed
  createCustomerError: string; //  not needed
  fulfillmentDeliveryNote: string;
  steps: OrderStep []; //  not needed
  activeStep: OrderStep;
  isStoreClosed: boolean; //  not needed
  location: string;
  boxCount: string;
  orderStatus: OrderStatusEnum; // need to change to order orderStatusClass
  editOrder: boolean;
  shopifyOrderId?: string;
  shopifyOrderName?: string;
  isChangeOrder?: boolean;
  chargeDeliveryAddress: string;
}

export interface Address {
  id: number;
  name: string;
  phoneNumber: PhoneNumber;
  email: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  province: string;
  hallNumber: string;
  customerId: number;
  active: boolean;
  type: string;
  webUrl: string;
  mapUrl: string;
}

export enum FulfillmentMethod {
  none = '',
  delivery = 'delivery',
  pickup = 'pickup'
}

export interface Customer {
  id: number;
  firstName: string;
  lastName: string;
  homeStore: string;
  communicationPreferences: string;
  notes: string;
  priceLevel: string;
  rating: string;
  fraudCode: string;
  phones: PhoneNumber[];
  emails: string[];
  addresses: Addresses;
  errorCode: string;
  forceInsert: number;
}

export interface Addresses {
  shippingAddress: Address[];
}

export interface DeliverModalData {
  tabId: NavigationTabId;
  index: number;
  isTemporary: boolean;
}

export interface OrderStep {
  name: string;
  number: number;
}

export interface CustomizationStep {
  name: string;
  number: number;
}

export enum OrderStepNumbers {
  customerSearch = 0,
  deliveryAndPickup = 1,
  dateAndTimeSelection = 2,
  startTransaction = 3,
}

export const OrderSteps: OrderStep[] = [
  {name: 'Customer', number: OrderStepNumbers.customerSearch},
  {name: 'Delivery & Pickup', number: OrderStepNumbers.deliveryAndPickup},
  {name: 'Date & Time', number: OrderStepNumbers.dateAndTimeSelection},
  {name: 'Transaction', number: OrderStepNumbers.startTransaction},
];

export const InitialCustomer: Customer = {
  id: 0,
  firstName: '',
  lastName: '',
  homeStore: '',
  communicationPreferences: '',
  notes: '',
  priceLevel: '',
  rating: '',
  fraudCode: '',
  phones: [],
  emails: [],
  addresses: null,
  errorCode: '',
  forceInsert: null
};

export enum PersonType {
  customer = 'Customer',
  deliveryPerson = 'Delivery Person'
}

export interface FulfillmentDateInfo {
  fulfillmentDate: string;
  fulfillmentTimeStart: string;
  fulfillmentTimeEnd: string;
}

export interface GoogleAddress {
  isEstablishment: boolean;
  name: string;
  streetNumber: string;
  route: string;
  locality: string;
  postalCode: string;
  province: string;
  webUrl: string;
  mapUrl: string;
  types: string[];
}

export interface DuplicateCustomerModalData {
  customer: Partial<Customer>;
  tabId: NavigationTabId;
}
