import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NavigationState, navigationStateKey } from 'src/app/store/navigation/navigation.reducer';
import { NavigationTabId } from 'src/app/core/models/common.model';

// tslint:disable-next-line:no-namespace
export namespace NavigationSelectors {

  export const navigationState = createFeatureSelector(navigationStateKey);

  export const tabsMap = createSelector(
    navigationState,
    (state: NavigationState) => state.tabs
  );

  export const tabs = createSelector(
    navigationState,
    (state: NavigationState) => state.tabIds.map((id: NavigationTabId) => state.tabs[id])
  );

  export const activeTabId = createSelector(
    navigationState,
    (state: NavigationState) => state.activeTabId
  );

  export const activeTab = createSelector(
    tabsMap,
    activeTabId,
    (currentTabsMap, currentActiveTabId) => currentTabsMap[currentActiveTabId]
  );

  export const tab = (tabId: NavigationTabId) => createSelector(
    tabsMap,
    (currentTabsMap) => currentTabsMap[tabId]
  );

  export const currentEmployee = createSelector(
    tabsMap,
    activeTabId,
    (currentTabsMap, currentActiveTabId) => {
      return currentTabsMap[currentActiveTabId] &&
      currentTabsMap[currentActiveTabId].details && currentTabsMap[currentActiveTabId].details.employee;
    }
  );

}
