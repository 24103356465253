import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeDigit'
})
export class TimeDigitPipe implements PipeTransform {

  transform(time12: string): string {
    const today = moment().format('YYYY-MM-DD');
    return moment(`${today} ${time12}`).format('h');
  }

}
