import {Pipe, PipeTransform} from '@angular/core';
import {template as lTemplate, templateSettings as lTemplateSettings} from 'lodash';

@Pipe({
  name: 'interpolate'
})
export class InterpolatePipe implements PipeTransform {
  transform(value: string, params: { [key: string]: any }): string {
    let result: string;
    lTemplateSettings.interpolate = /{{([\s\S]+?)}}/g;
    const compiled = lTemplate(value);
    // TODO: Rajiv Find a better alternative to lodash compiled function
    try {
      result = compiled(params);
    } catch {
      result = value;
    }
    return result;
  }
}
