import {MasterDataState} from 'src/app/store/master-data/master-data.reducer';
import {BaseAction} from 'src/app/store/models/base-action';
import {PayloadAction} from 'src/app/store/models/payload-action';
import {
  EmployeeSearchParam,
  EmployeeTimeInOut,
  EmployeeTimeInSearchParam
} from 'src/app/core/models/common.model';
import {EmployeeActions, EmployeeActionTypes} from 'src/app/store/employee/employee.actions';
import {Employee} from 'src/app/core/models/common.model';

export const employeeStateKey = 'employee';

export const employeeCacheKey: (keyof EmployeesInOutState)[] = [];

export interface EmployeesInOutState {
  employees: Employee[];
  timeIn: EmployeeTimeInOut[];
  employeesTimeInLoading: boolean;
  employeesTimeInError: string;
  createEmployeesTimeInLoading: boolean;
  createEmployeesTimeInError: string;
  updateEmployeesTimeInLoading: boolean;
  updateEmployeesTimeInError: string;
  searchTerm: Partial<EmployeeTimeInSearchParam>;
  searchedEmployeesTimeIn: EmployeeTimeInOut[];

  employeeSearchTerm: EmployeeSearchParam;
  employeesLoading: boolean;
  employeesSuccess: boolean;
  employeesError: string;

  createEmployeeLoading: boolean;
  createEmployeeError: string;

  updateEmployeeLoading: boolean;
  updateEmployeeError: string;
}

const initialEmployeeState: EmployeesInOutState = {
  employees : [],
  timeIn: [],
  employeesTimeInLoading: false,
  employeesTimeInError : '',
  createEmployeesTimeInLoading: false,
  createEmployeesTimeInError: '',
  updateEmployeesTimeInLoading: false,
  updateEmployeesTimeInError: '',
  searchTerm : null,
  searchedEmployeesTimeIn: [],

  employeeSearchTerm: null,
  employeesLoading: false,
  employeesSuccess: false,
  employeesError: '',

  createEmployeeLoading: false,
  createEmployeeError: '',

  updateEmployeeLoading: false,
  updateEmployeeError: ''
};

export function employeeReducer(state: EmployeesInOutState = initialEmployeeState,
                                action: BaseAction | PayloadAction<any>): EmployeesInOutState {
  switch (action.type) {
    case EmployeeActionTypes.EmployeeTimeIn: {
      return {
        ...state,
        employeesTimeInLoading: true,
        employeesTimeInError : ''
      };
    }

    case EmployeeActionTypes.LoadEmployeesTimeInSuccess: {
      const newData: EmployeeTimeInOut[] = (action as EmployeeActions.LoadEmployeesTimeInSuccess).payload;
      return {
        ...state,
        timeIn: newData,
        employeesTimeInLoading: false,
        employeesTimeInError : ''
      };
    }

    case EmployeeActionTypes.LoadEmployeesTimeInError: {
      const error: string = (action as EmployeeActions.LoadEmployeesTimeInError).payload;
      return {
        ...state,
        employeesTimeInLoading: false,
        employeesTimeInError: error
      };
    }
    case EmployeeActionTypes.CreateEmployeeTimeIn: {
      return {
        ...state,
        createEmployeesTimeInLoading: true,
        createEmployeesTimeInError: ''
      };
    }

    case EmployeeActionTypes.CreateEmployeesTimeInSuccess: {
      return {
        ...state,
        createEmployeesTimeInLoading: false,
        createEmployeesTimeInError: ''
      };
    }
    case EmployeeActionTypes.CreateEmployeesTimeInError: {
      const error: string = (action as EmployeeActions.CreateEmployeesTimeInError).payload;
      return {
        ...state,
        createEmployeesTimeInLoading: false,
        createEmployeesTimeInError: error
      };
    }

    case EmployeeActionTypes.UpdateEmployeeTimeIn: {
      return {
        ...state,
        updateEmployeesTimeInLoading: true,
        updateEmployeesTimeInError: ''
      };
    }

    case EmployeeActionTypes.UpdateEmployeesTimeInSuccess: {
      const updatedTimeIn: EmployeeTimeInOut = (action as EmployeeActions.UpdateEmployeesTimeInSuccess).payload[0];
      const searchedEmployeesTimeIn: EmployeeTimeInOut[] = [...state.searchedEmployeesTimeIn]
      const index = searchedEmployeesTimeIn.findIndex(user=> user?.employeeId === updatedTimeIn?.employeeId);
      searchedEmployeesTimeIn[index]= {
        ...updatedTimeIn
      }
      return {
        ...state,
        searchedEmployeesTimeIn: [...searchedEmployeesTimeIn],
        updateEmployeesTimeInLoading: false,
        updateEmployeesTimeInError: ''
      };
    }
    case EmployeeActionTypes.UpdateEmployeesTimeInError: {
      const error: string = (action as EmployeeActions.CreateEmployeesTimeInError).payload;
      return {
        ...state,
        updateEmployeesTimeInLoading: false,
        updateEmployeesTimeInError: error
      };
    }
    case EmployeeActionTypes.SearchEmployeesTimeIn: {

      const searchTerm: Partial<EmployeeTimeInSearchParam> = (action as EmployeeActions.SearchEmployeesTimeIn).payload;
      return {
        ...state,
        searchTerm,
        employeesTimeInLoading: true,
        employeesTimeInError : '',
      };
    }
    case EmployeeActionTypes.SearchEmployeesTimeInSuccess: {
      const newEmployeesTimeIn: EmployeeTimeInOut[] = (action as EmployeeActions.SearchEmployeesTimeInSuccess).payload;
      return {
        ...state,
        employeesTimeInLoading: false,
        employeesTimeInError : '',
        searchedEmployeesTimeIn: newEmployeesTimeIn
      };
    }

    case EmployeeActionTypes.SearchEmployeesTimeInError: {
      const error: string = (action as EmployeeActions.SearchEmployeesTimeInError).payload;
      return {
        ...state,
        employeesTimeInLoading: false,
        employeesTimeInError : error
      };
    }
    case EmployeeActionTypes.DeleteEmployeesTimeIn: {
      return {
        ...state
      };
    }

    case EmployeeActionTypes.DeleteEmployeesTimeInSuccess: {
      const searchedEmployees = [...state.searchedEmployeesTimeIn];
      const deletedId = (action as EmployeeActions.DeleteEmployeesTimeInSuccess).payload;
      const index = searchedEmployees.findIndex(item => item.id === deletedId);
      searchedEmployees.splice(index, 1);
      return {
        ...state,
        employeesTimeInLoading: true,
        employeesTimeInError : '',
        searchedEmployeesTimeIn: searchedEmployees
      };
    }

    case EmployeeActionTypes.SearchEmployee: {
      const employeeSearchTerm: EmployeeSearchParam = (action as EmployeeActions.SearchEmployee).payload;
      return {
        ...state,
        employeeSearchTerm,
        employeesLoading: true,
        employeesError: ''
      };
    }

    case EmployeeActionTypes.SearchEmployeeSuccess: {
      const employees: Employee[] = (action as EmployeeActions.SearchEmployeeSuccess).payload;
      return {
        ...state,
        employeesLoading: false,
        employeesError: '',
        employees
      };
    }

    case EmployeeActionTypes.SearchEmployeeError: {
      const error: string = (action as EmployeeActions.SearchEmployeeError).payload;
      return {
        ...state,
        employeesLoading: false,
        employeesError: error,
      };
    }

    case EmployeeActionTypes.CreateEmployee: {
      return {
        ...state,
        createEmployeeLoading: true,
        createEmployeeError: ''
      };
    }

    case EmployeeActionTypes.CreateEmployeeSuccess: {
      const newEmp: Employee = (action as EmployeeActions.CreateEmployeeSuccess).payload;
      const employees: Employee[] = [...state.employees];
      employees.push(newEmp);
      return {
        ...state,
        employees,
        createEmployeeLoading: false,
        createEmployeeError: ''
      };
    }

    case EmployeeActionTypes.CreateEmployeeError: {
      const error: string = (action as EmployeeActions.CreateEmployeeError).payload;
      return {
        ...state,
        createEmployeeLoading: false,
        createEmployeeError: error
      };
    }

    case EmployeeActionTypes.UpdateEmployee: {
      return {
        ...state,
        updateEmployeeLoading: true,
        updateEmployeeError: ''
      };
    }

    case EmployeeActionTypes.UpdateEmployeeSuccess: {
      const updatedEmployee: Employee = (action as EmployeeActions.UpdateEmployeeSuccess).payload;
      const employees: Employee[] = [...state.employees];
      const index = employees.findIndex(item => item.id === updatedEmployee.id);
      employees[index] = updatedEmployee;
      return {
        ...state,
        employees,
        updateEmployeeLoading: false,
        updateEmployeeError: ''
      };
    }

    case EmployeeActionTypes.UpdateEmployeeError: {
      const error: string = (action as EmployeeActions.UpdateEmployeeError).payload;
      return {
        ...state,
        updateEmployeeError: error,
        updateEmployeeLoading: false
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
}
