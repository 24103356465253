import { Action } from '@ngrx/store';

export abstract class BaseAction implements Action {

  public abstract readonly type: string;

  public readonly timestamp: Date = new Date();

  constructor() {
  }

}

