import {Customization, Tier} from 'src/app/core/api-models/customization';
import {Log} from 'src/app/core/api-models/logs';
import {Product} from 'src/app/core/api-models/menu.model';
import {Address, Customer, Order, OrderStep, OrderStepNumbers, OrderSteps} from 'src/app/core/api-models/order';
import {
  Notification,
  SearchParam,
  SearchTransactions,
  SelectedOrderTransaction,
  Transaction,
  TransactionType
} from 'src/app/core/api-models/transaction';
import {
  AddTransactionItemParam,
  TransactionItem,
  UpdateCustomizationItem,
  UpdateTierInfo,
  UpdateTransactionItemParam,
} from 'src/app/core/api-models/transaction-item';
import {TransactionPayment} from 'src/app/core/api-models/transaction.payment';
import {TransactionItemStatus} from 'src/app/core/constants/common.enum';
import {
  ChangeTransactionParams,
  CreateCustomersParams, CreateNotificationInputParams,
  GetCustomerParams, GetProductInfoParams, GetSelectedOrderParams, NotificationInputParams,
  SaveTransactionParams,
  SearchCustomersParams,
  SearchProductParams, SearchTransactionsParams, UpdateCustomerParams, UpdateSearchedOrderParams, UpdateTransactionToServerParams
} from 'src/app/core/models/common.model';
import {UtilsService} from 'src/app/core/services/utils.service';
import {BaseAction} from 'src/app/store/models/base-action';
import {PayloadAction} from 'src/app/store/models/payload-action';
import {
  generateInitialTabSegmentState,
  generateRemoveTabSegmentState,
  generateTabSegmentState,
  TabSegmentedState
} from 'src/app/store/models/tab-segmented-state';
import {NavigationActions, NavigationActionTypes} from 'src/app/store/navigation/navigation.actions';
import {SaleActions, SaleActionTypes} from 'src/app/store/sale/sale.actions';
import {SaleService} from 'src/app/store/sale/sale.service';

export interface TransactionState {
  error: string;
  success: boolean;
  transaction: number;
  loading: boolean;
}

export const saleStateKey = 'sale';

export const saleCacheKeys: (keyof SaleSegmentState)[] = [];

export interface SaleSegmentState {
  activeMenuId: number;
  searchProducts: {
    searchTerm: string;
    products: Product[];
    productsLoading: boolean;
    productsError: string;
  };
  transactionState: TransactionState;
  transaction: Transaction;
  transactionBackup: Transaction;
  searchTransactions: SearchTransactions;
  selectedOrder: SelectedOrderTransaction;
  notification: Notification;
}

export interface SaleState extends TabSegmentedState<SaleSegmentState> {
}

// TODO: RAJIV Remove this export from here, just temporarily added here
export const initialSaleSegmentState: SaleSegmentState = {
  activeMenuId: 1,
  searchProducts: {
    searchTerm: '',
    products: [],
    productsLoading: false,
    productsError: ''
  },
  transactionState: {
    error: '',
    success: false,
    transaction: 0,
    loading: false
  },
  transaction: SaleService.initialTransaction(),
  transactionBackup: SaleService.initialTransaction(),
  searchTransactions: {
    searchTerm: null,
    transactions: [],
    transactionsLoading: false,
    transactionsError: '',
    updateSearchedOrderError: '',
    updateSearchedOrderLoading: false
  },
  selectedOrder: {
    transaction: null,
    selectedOrderLoading: false,
    selectedOrderError: ''
  },
  notification: {
    notificationState: {
      loading: false,
      success: false,
      error: false
    },
    transactionId: 0,
    event: null,
    templateType: null
  }
};

const initialSaleState: SaleState = {};

export function saleReducer(state: SaleState = initialSaleState, action: BaseAction | PayloadAction<any>): SaleState {
  switch (action.type) {
    case SaleActionTypes.SelectMenu: {
      return generateTabSegmentState(state, action as SaleActions.SelectMenu, (segment: SaleSegmentState, data: number) => {
        return {
          ...segment,
          activeMenuId: data
        };
      });
    }
    case SaleActionTypes.UpdateTransaction: {
      return generateTabSegmentState(state, action as SaleActions.UpdateTransaction,
        (segment: SaleSegmentState, data: Partial<Transaction>) => {
          const transactionPartial: Partial<Transaction> = (action as SaleActions.UpdateTransaction).payload.data;
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              ...transactionPartial
            }
          };
        });
    }
    case SaleActionTypes.UpdateServerTransactionOrder: {
      return generateTabSegmentState(state, action as SaleActions.UpdateServerTransactionOrder,
        (segment: SaleSegmentState, data: UpdateSearchedOrderParams) => {
          return {
            ...segment,
            transactionState: {
              ...segment.transactionState,
              loading: true,
              success: false,
              error: ''
            }
          };
        });
    }
    case SaleActionTypes.UpdateTransactionOrder: {
      return generateTabSegmentState(state, action as SaleActions.UpdateTransactionOrder,
        (segment: SaleSegmentState, data: Partial<Order>) => {
          const orderPartial: Partial<Order> = (action as SaleActions.UpdateTransactionOrder).payload.data;
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              order: {
                ...segment.transaction.order,
                ...orderPartial
              }
            }
          };
        });
    }
    case SaleActionTypes.UpdateTransactionOrderSuccess: {
      return generateTabSegmentState(state, action as SaleActions.UpdateTransactionOrderSuccess,
        (segment: SaleSegmentState, data: Partial<Order>) => {
          const orderPartial: Partial<Order> = (action as SaleActions.UpdateTransactionOrderSuccess).payload.data;
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              order: {
                ...segment.transaction?.order,
                ...orderPartial
              }
            },
            transactionState: {
              ...segment.transactionState,
              loading: false,
              success: true,
              error: ''
            }
          };
        });
    }
    case SaleActionTypes.UpdateTransactionOrderError: {
      return generateTabSegmentState(state, action as SaleActions.UpdateTransactionOrderError,
        (segment: SaleSegmentState, data: string) => {
          return {
            ...segment,
            transactionState: {
              ...segment.transactionState,
              loading: false,
              success: false,
              error: data
            }
          };
        });
    }
    case SaleActionTypes.BackupTransactionOrder: {
      return generateTabSegmentState(state, action as SaleActions.BackupTransactionOrder,
        (segment: SaleSegmentState, data: number) => {
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              orderBackup: {
                ...segment.transaction.order,
              }
            }
          };
        });
    }
    case SaleActionTypes.RestoreTransactionOrder: {
      return generateTabSegmentState(state, action as SaleActions.RestoreTransactionOrder,
        (segment: SaleSegmentState, data: number) => {
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              order: {
                ...segment.transaction.orderBackup,
              }
            }
          };
        });
    }
    case SaleActionTypes.UpdateOrderCustomerAddress: {
      return generateTabSegmentState(state, action as SaleActions.UpdateOrderCustomerAddress,
        (segment: SaleSegmentState, data: Address) => {
          let updatedAddressList = [];
          const newAddress: Address = (action as SaleActions.UpdateOrderCustomerAddress).payload.data;
          const customer = {...segment.transaction.customer};
          if (customer.addresses?.shippingAddress) {
            updatedAddressList = [...segment.transaction.customer.addresses.shippingAddress]
              .filter(obj => obj.id !== newAddress.id);
          }

          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              customer: {
                ...segment.transaction.customer,
                addresses: {
                  ...segment.transaction.customer.addresses,
                  shippingAddress: [
                    ...updatedAddressList,
                    newAddress
                  ]
                }
              }
            }
          };
        });
    }
    case SaleActionTypes.DeleteCustomerAddress: {
      return generateTabSegmentState(state, action as SaleActions.DeleteCustomerAddress,
        (segment: SaleSegmentState, data: Address) => {
          const addressPartial: Address = (action as SaleActions.DeleteCustomerAddress).payload.data;
          const updatedAddressList = [...segment.transaction.customer.addresses.shippingAddress]
            .filter(obj => obj.id !== addressPartial.id);
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              order: {
                ...segment.transaction.order,
                customer: {
                  ...segment.transaction.customer,
                  addresses: {
                    ...segment.transaction.customer.addresses,
                    shippingAddress: [
                      ...updatedAddressList
                    ]
                  }
                }
              }
            }
          };
        });
    }
    case SaleActionTypes.RemoveOrderItem: {
      return generateTabSegmentState(state, action as SaleActions.RemoveOrderItem,
        (segment: SaleSegmentState, data: string[]) => {
          const propertyName: string[] = (action as SaleActions.RemoveOrderItem).payload.data;
          const lOrder = {...segment.transaction.order};
          propertyName.forEach(key => {
            delete lOrder[key];
          });
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              order: {
                ...lOrder,
              }
            }
          };
        });
    }
    case SaleActionTypes.AddCustomization: {
      return generateTabSegmentState(state, action as SaleActions.AddCustomization,
        (segment: SaleSegmentState, data: number) => {
          const index: number = (action as SaleActions.AddCustomization).payload.data;
          const customizationObj = addCustomization();
          const transactionItems: TransactionItem[] = [...segment.transaction.transactionItems];
          transactionItems[index] = {
            ...transactionItems[index],
            customization: {
              ...customizationObj
            }
          };
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              transactionItems: [
                ...transactionItems
              ]
            }
          };
        });
    }
    case SaleActionTypes.UpdateTransactionCustomization: {
      return generateTabSegmentState(state, action as SaleActions.UpdateTransactionCustomization,
        (segment: SaleSegmentState, data: UpdateCustomizationItem) => {
          const index: number = (action as SaleActions.UpdateTransactionItem).payload.data.index;
          const customizationPartial: UpdateCustomizationItem = (action as SaleActions.UpdateTransactionCustomization).payload.data;
          const transactionItems: TransactionItem[] = [...segment.transaction.transactionItems];
          if (transactionItems[index] && transactionItems[index].sku) {
            transactionItems[index] = {
              ...transactionItems[index],
              customization: {
                ...transactionItems[index]?.customization,
                ...customizationPartial.updateObject
              }
            };
          }
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              transactionItems: [
                ...transactionItems
              ]
            }
          };
        });
    }
    case SaleActionTypes.BackupTransactionCustomization: {
      return generateTabSegmentState(state, action as SaleActions.BackupTransactionCustomization,
        (segment: SaleSegmentState, data: number) => {
          const index: number = (action as SaleActions.BackupTransactionCustomization).payload.data;
          const transactionItems: TransactionItem[] = [...segment.transaction.transactionItems];
          transactionItems[index] = {
            ...transactionItems[index],
            customizationBackup: {
              ...transactionItems[index].customization
            }
          };
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              transactionItems: [
                ...transactionItems
              ]
            }
          };
        });
    }
    case SaleActionTypes.RestoreTransactionCustomization: {
      return generateTabSegmentState(state, action as SaleActions.RestoreTransactionCustomization,
        (segment: SaleSegmentState, data: number) => {
          const index: number = (action as SaleActions.RestoreTransactionCustomization).payload.data;
          const transactionItems: TransactionItem[] = [...segment.transaction.transactionItems];
          transactionItems[index] = {
            ...transactionItems[index],
            customization: {
              ...transactionItems[index].customizationBackup
            },
            customizationBackup: null
          };
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              transactionItems: [
                ...transactionItems
              ]
            }
          };
        });
    }
    case SaleActionTypes.UpdateCustomizationTierInfo: {
      return generateTabSegmentState(state, action as SaleActions.UpdateCustomizationTierInfo,
        (segment: SaleSegmentState, data: UpdateTierInfo) => {
          const itemIndex: number = (action as SaleActions.UpdateCustomizationTierInfo).payload.data.itemIndex;
          const tierIndex: number = (action as SaleActions.UpdateCustomizationTierInfo).payload.data.tierIndex;
          const tierPartial: Partial<Tier> = (action as SaleActions.UpdateCustomizationTierInfo).payload.data.updateObject;
          const tiers: Tier[] = [...segment?.transaction?.transactionItems[itemIndex]?.customization?.tiers];
          tiers[tierIndex] = {
            ...tiers[tierIndex],
            ...tierPartial
          };
          const transactionItems: TransactionItem[] = [...segment.transaction.transactionItems];
          transactionItems[itemIndex] = {
            ...transactionItems[itemIndex],
            customization: {
              ...transactionItems[itemIndex].customization,
              tiers
            }
          };
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              transactionItems: [
                ...transactionItems
              ]
            }
          };
        });
    }
    case SaleActionTypes.AddTransactionItem: {
      return generateTabSegmentState(state, action as SaleActions.AddTransactionItem,
        (segment: SaleSegmentState, data: AddTransactionItemParam) => {
          const itemParams: AddTransactionItemParam = (action as SaleActions.AddTransactionItem).payload.data;
          const transactionItem = addTransactionItem(itemParams);
          const activeItem = {...segment}.transaction?.transactionItems?.length || 0;
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              activeItem,
              transactionItems: [
                ...segment.transaction.transactionItems,
                transactionItem,
              ]
            }
          };
        });
    }
    case SaleActionTypes.RemoveTransactionItem: {
      return generateTabSegmentState(state, action as SaleActions.RemoveTransactionItem,
        (segment: SaleSegmentState, data: string | number) => {
          let newTransactionItems = [];
          const transaction = JSON.parse(JSON.stringify(segment.transaction));
          const transactionItems = transaction.transactionItems;
          if (typeof data === 'string') {
            const uuid = (action as SaleActions.RemoveTransactionItem).payload.data;
            newTransactionItems = transactionItems.filter(item => item.uuid !== uuid);
          } else {
            transactionItems.splice(data, 1);
          }
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              transactionItems: typeof data === 'string' ? newTransactionItems : transactionItems
            }
          };
        });
    }
    case SaleActionTypes.UpdateTransactionItem: {
      return generateTabSegmentState(state, action as SaleActions.UpdateTransactionItem,
        (segment: SaleSegmentState, data: UpdateTransactionItemParam) => {
          let transactionItems: TransactionItem[];
          const val: UpdateTransactionItemParam = (action as SaleActions.UpdateTransactionItem).payload.data;
          if (val.uuid) {
            const uuid = val.uuid;
            transactionItems = [...segment.transaction.transactionItems].map(item => {
              if (item.uuid === uuid) {
                return {
                  ...item,
                  ...val.updateObject
                };
              }
              return item;
            });
          } else {
            transactionItems = [...segment.transaction.transactionItems];
            transactionItems[val.index] = {
              ...transactionItems[val.index],
              ...val.updateObject
            };
          }

          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              transactionItems
            }
          };
        });
    }
    case SaleActionTypes.AddLogToTransaction: {
      return generateTabSegmentState(state, action as SaleActions.AddLogToTransaction,
        (segment: SaleSegmentState, data: Log[]) => {
          const thisTransaction: Transaction = segment ? {...segment.transaction} : null;
          const thisLogs: Log[] = segment?.transaction?.logs ? [...segment.transaction.logs] : [];
          return {
            ...segment,
            transaction: {
              ...thisTransaction,
              logs: [
                ...thisLogs,
                ...data
              ]
            }
          };
        });
    }
    case SaleActionTypes.SearchProducts: {
      return generateTabSegmentState(state, action as SaleActions.SearchProducts,
        (segment: SaleSegmentState, data: SearchProductParams) => {
          const searchTerm: string = (action as SaleActions.SearchProducts).payload.data?.searchTerm;
          return {
            ...segment,
            searchProducts: {
              ...segment.searchProducts,
              productsLoading: true,
              productsError: '',
              searchTerm
            }
          };
        });
    }
    case SaleActionTypes.SearchProductsSuccess: {
      return generateTabSegmentState(state, action as SaleActions.SearchProductsSuccess,
        (segment: SaleSegmentState, data: Product[]) => {
          const products: Product[] = (action as SaleActions.SearchProductsSuccess).payload.data;
          return {
            ...segment,
            searchProducts: {
              ...segment.searchProducts,
              productsLoading: false,
              productsError: '',
              products
            }
          };
        });
    }
    case SaleActionTypes.SearchProductsError: {
      return generateTabSegmentState(state, action as SaleActions.SearchProductsError,
        (segment: SaleSegmentState, data: string) => {
          const error: string = (action as SaleActions.SearchProductsError).payload.data;
          return {
            ...segment,
            searchProducts: {
              ...segment.searchProducts,
              productsLoading: false,
              productsError: error,
              products: []
            }
          };
        });
    }
    case SaleActionTypes.SearchCustomers: {
      return generateTabSegmentState(state, action as SaleActions.SearchCustomers,
        (segment: SaleSegmentState, data: SearchCustomersParams) => {
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              order: {
                ...segment.transaction.order,
                customersLoading: true,
                customersError: '',
              }
            }
          };
        });
    }
    case SaleActionTypes.SearchCustomersSuccess: {
      return generateTabSegmentState(state, action as SaleActions.SearchCustomersSuccess,
        (segment: SaleSegmentState, data: Customer[]) => {
          const searchedCustomers: Customer[] = (action as SaleActions.SearchCustomersSuccess).payload.data;
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              order: {
                ...segment.transaction.order,
                customersLoading: false,
                customersError: '',
                searchedCustomers
              }
            }
          };
        });
    }
    case SaleActionTypes.SearchCustomersError: {
      return generateTabSegmentState(state, action as SaleActions.SearchCustomersError,
        (segment: SaleSegmentState, data: string) => {
          const error: string = (action as SaleActions.SearchCustomersError).payload.data;
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              order: {
                ...segment.transaction.order,
                customersLoading: false,
                customersError: error,
              }
            }
          };
        });
    }
    case SaleActionTypes.CreateCustomer: {
      return generateTabSegmentState(state, action as SaleActions.CreateCustomer,
        (segment: SaleSegmentState, data: CreateCustomersParams) => {
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              order: {
                ...segment.transaction.order,
                createCustomerLoading: true,
                createCustomerError: '',
              }
            }
          };
        });
    }
    case SaleActionTypes.CreateCustomerSuccess: {
      return generateTabSegmentState(state, action as SaleActions.CreateCustomerSuccess,
        (segment: SaleSegmentState, data: Customer) => {
          const createdCustomer: Customer = (action as SaleActions.CreateCustomerSuccess).payload.data;
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              order: {
                ...segment.transaction.order,
                createCustomerLoading: false,
                createCustomerError: '',
                createdCustomer
              }
            }
          };
        });
    }
    case SaleActionTypes.CreateCustomerError: {
      return generateTabSegmentState(state, action as SaleActions.CreateCustomerError,
        (segment: SaleSegmentState, data: string) => {
          const error: string = (action as SaleActions.CreateCustomerError).payload.data;
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              order: {
                ...segment.transaction.order,
                createCustomerLoading: false,
                createCustomerError: error,
              }
            }
          };
        });
    }
    case SaleActionTypes.UpdateCustomer: {
      return generateTabSegmentState(state, action as SaleActions.UpdateCustomer,
        (segment: SaleSegmentState, data: UpdateCustomerParams) => {
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              updateCustomerLoading: true,
              updateCustomerError: '',
            }
          };
        });
    }
    case SaleActionTypes.UpdateCustomerSuccess: {
      return generateTabSegmentState(state, action as SaleActions.UpdateCustomerSuccess,
        (segment: SaleSegmentState, data: Customer) => {
          const createdCustomer: Customer = (action as SaleActions.UpdateCustomerSuccess).payload.data;
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              updateCustomerLoading: false,
              updateCustomerError: '',
              createdCustomer
            }
          };
        });
    }
    case SaleActionTypes.UpdateCustomerError: {
      return generateTabSegmentState(state, action as SaleActions.UpdateCustomerError,
        (segment: SaleSegmentState, data: string) => {
          const error: string = (action as SaleActions.UpdateCustomerError).payload.data;
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              updateCustomerLoading: false,
              updateCustomerError: error,
            }
          };
        });
    }
    case SaleActionTypes.GetCustomer: {
      return generateTabSegmentState(state, action as SaleActions.GetCustomer,
        (segment: SaleSegmentState, data: GetCustomerParams) => {
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
            }
          };
        });
    }
    case SaleActionTypes.SaveTransaction: {
      return generateTabSegmentState(state, action as SaleActions.SaveTransaction,
        (segment: SaleSegmentState, data: SaveTransactionParams) => {
          return {
            ...segment,
            transactionState: {
              ...segment.transactionState,
              loading: true
            }
          };
        });
    }
    case SaleActionTypes.SaveTransactionSuccess: {
      return generateTabSegmentState(state, action as SaleActions.SaveTransactionSuccess,
        (segment: SaleSegmentState, data: Partial<Transaction>) => {
          const transaction: Partial<Transaction> = (action as SaleActions.SaveTransactionSuccess).payload.data;
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              transactionId: transaction.transactionId,
              shortCode: transaction.shortCode,
              randomId: transaction.randomId,
              transactionStatus: transaction.transactionStatus,
              order: {
                ...segment.transaction.order,
                ...transaction.order
              }
            },
            transactionState: {
              success: true,
              error: '',
              transaction: +transaction.transactionId,
              loading: false,
              changeTransaction: false
            }
          };
        });
    }
    case SaleActionTypes.SaveTransactionError: {
      return generateTabSegmentState(state, action as SaleActions.SaveTransactionError,
        (segment: SaleSegmentState, data: string) => {
          const error: string = data.toString();
          return {
            ...segment,
            transactionState: {
              success: false,
              error,
              transaction: 0,
              loading: false,
              changeTransaction: false
            }
          };
        });
    }
    case SaleActionTypes.AddPaymentToSelectedTransaction: {
      return generateTabSegmentState(state, action as SaleActions.AddPaymentToSelectedTransaction,
        (segment: SaleSegmentState, data: TransactionPayment) => {
          const payment: TransactionPayment = (action as SaleActions.AddPaymentToSelectedTransaction).payload.data;
          const transactionPayments: TransactionPayment[] = [...segment.transaction.transactionPayments, payment];

          return {
            ...segment,
            selectedOrder: {
              ...segment.selectedOrder,
              transaction: {
                ...segment.selectedOrder.transaction,
                transactionPayments
              }
            }
          };
        });
    }
    case SaleActionTypes.UpdateTransactionToServer: {
      return generateTabSegmentState(state, action as SaleActions.UpdateTransactionToServer,
        (segment: SaleSegmentState, data: UpdateTransactionToServerParams) => {
          const transactionPartial: Partial<Transaction<Partial<Order>>> =
            (action as SaleActions.UpdateTransactionToServer).payload.data.transaction;
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              ...transactionPartial,
              order: {
                ...segment.transaction.order,
                ...transactionPartial.order
              }
            },
            selectedOrder: {
              ...segment.selectedOrder,
              transaction: {
                ...segment.transaction,
                ...transactionPartial,
                order: {
                  ...segment.transaction.order,
                  ...transactionPartial.order
                }
              }
            },
            transactionState: {
              ...segment.transactionState,
              loading: true,
              error: ''
            }
          };
        });
    }
    case SaleActionTypes.UpdateTransactionToServerSuccess: {
      return generateTabSegmentState(state, action as SaleActions.UpdateTransactionToServerSuccess,
        (segment: SaleSegmentState, data: Partial<Transaction>) => {
          const transactionPartial: Partial<Transaction> = (action as SaleActions.UpdateTransaction).payload.data;
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              transactionPartial
            },
            selectedOrder: {
              ...segment.selectedOrder,
              transaction: {
                ...segment.selectedOrder.transaction,
                transactionPartial
              },
              selectedOrderLoading: false
            },
            transactionState: {
              ...segment.transactionState,
              loading: false,
              error: ''
            }
          };
        });
    }
    case SaleActionTypes.UpdateTransactionToServerError: {
      return generateTabSegmentState(state, action as SaleActions.UpdateTransactionToServerError,
        (segment: SaleSegmentState, data: string) => {
          return {
            ...segment,
            transactionState: {
              ...segment.transactionState,
              loading: false,
              error: data
            }
          };
        });
    }
    case SaleActionTypes.AddTransactionPayment: {
      return generateTabSegmentState(state, action as SaleActions.AddTransactionPayment,
        (segment: SaleSegmentState, data: Partial<TransactionPayment>) => {
          const itemParams: Partial<TransactionPayment> = (action as SaleActions.AddTransactionPayment).payload.data;
          const transactionPayment = addTransactionPayment(itemParams);

          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              transactionPayments: [
                transactionPayment,
                ...segment.transaction.transactionPayments
              ]
            }
          };
        });
    }
    case SaleActionTypes.GetProductInfo: {
      return generateTabSegmentState(state, action as SaleActions.GetProductInfo,
        (segment: SaleSegmentState, data: GetProductInfoParams) => {
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              productLoading: true,
              productError: ''
            }
          };
        });
    }
    case SaleActionTypes.GetProductInfoSuccess: {
      return generateTabSegmentState(state, action as SaleActions.GetProductInfoSuccess,
        (segment: SaleSegmentState, data: Product) => {
          const key: number = (action as SaleActions.GetProductInfoSuccess).payload.data.id;
          const product: Product = (action as SaleActions.GetProductInfoSuccess).payload.data;
          const products = {...segment.transaction.products};
          products[key] = product;
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              products,
              productLoading: false,
              productError: ''
            }
          };
        });
    }
    case SaleActionTypes.GetProductInfoError: {
      return generateTabSegmentState(state, action as SaleActions.GetProductInfoError,
        (segment: SaleSegmentState, data: string) => {
          const error: string = (action as SaleActions.GetProductInfoError).payload.data;
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              productError: error,
              productLoading: false
            }
          };
        });
    }
    case SaleActionTypes.SearchTransactions: {
      return generateTabSegmentState(state, action as SaleActions.SearchTransactions,
        (segment: SaleSegmentState, data: SearchTransactionsParams) => {
          const searchTerm: Partial<SearchParam> = (action as SaleActions.SearchTransactions).payload.data.searchParam;
          return {
            ...segment,
            searchTransactions: {
              ...(segment.searchTransactions ? segment.searchTransactions : null),
              transactionsLoading: true,
              transactionsError: '',
              searchTerm
            }
          };
        });
    }
    case SaleActionTypes.SearchTransactionsSuccess: {
      return generateTabSegmentState(state, action as SaleActions.SearchTransactionsSuccess,
        (segment: SaleSegmentState, data: Transaction[]) => {
          const transactions: Transaction[] = (action as SaleActions.SearchTransactionsSuccess).payload.data;
          return {
            ...segment,
            searchTransactions: {
              ...(segment.searchTransactions ? segment.searchTransactions : null),
              transactionsLoading: false,
              transactionsError: '',
              transactions
            }
          };
        });
    }
    case SaleActionTypes.SearchTransactionsError: {
      return generateTabSegmentState(state, action as SaleActions.SearchTransactionsError,
        (segment: SaleSegmentState, data: string) => {
          const error: string = (action as SaleActions.SearchTransactionsError).payload.data;
          return {
            ...segment,
            searchTransactions: {
              ...(segment.searchTransactions ? segment.searchTransactions : null),
              transactionsLoading: false,
              transactionsError: error,
              transactions: []
            }
          };
        });
    }
    case SaleActionTypes.AddOrderTransaction: {
      return generateTabSegmentState(state, action as SaleActions.AddOrderTransaction,
        (segment: SaleSegmentState, data: Transaction) => {
          const transaction: Transaction = (action as SaleActions.AddOrderTransaction).payload.data;
          return {
            ...segment,
            selectedOrderTransaction: {
              ...transaction
            }
          };
        });
    }
    case SaleActionTypes.UpdateSearchedOrder: {
      return generateTabSegmentState(state, action as SaleActions.UpdateSearchedOrder,
        (segment: SaleSegmentState, data: UpdateSearchedOrderParams) => {
          return {
            ...segment,
            searchTransactions: {
              ...(segment.searchTransactions ? segment.searchTransactions : null),
              updateSearchedOrderLoading: true,
              updateSearchedOrderError: '',
            }
          };
        });
    }
    case SaleActionTypes.UpdateSearchedOrderSuccess: {
      return generateTabSegmentState(state, action as SaleActions.UpdateSearchedOrderSuccess,
        (segment: SaleSegmentState, data: Order) => {
          const transactions: Transaction[] = segment.searchTransactions.transactions
            ? [...segment.searchTransactions.transactions]
            : [];
          const lOrder = (action as SaleActions.UpdateSearchedOrderSuccess).payload.data;
          const index: number = transactions.findIndex(key => key.order?.orderId === lOrder?.orderId);
          if (index) {
            transactions[index] = {
              ...transactions[index],
              order: {
                ...lOrder
              }
            };
          }
          return {
            ...segment,
            searchTransactions: {
              ...(segment.searchTransactions ? segment.searchTransactions : null),
              updateSearchedOrderLoading: false,
              updateSearchedOrderError: '',
              transactions: [...transactions],
            }
          };
        });
    }
    case SaleActionTypes.UpdateSearchedOrderError: {
      return generateTabSegmentState(state, action as SaleActions.UpdateSearchedOrderError,
        (segment: SaleSegmentState, data: string) => {
          const error: string = (action as SaleActions.UpdateSearchedOrderError).payload.data;
          return {
            ...segment,
            searchTransactions: {
              ...(segment.searchTransactions ? segment.searchTransactions : null),
              updateSearchedOrderLoading: false,
              updateSearchedOrderError: error,
            }
          };
        });
    }
    case SaleActionTypes.UpdateSelectedOrder: {
      return generateTabSegmentState(state, action as SaleActions.UpdateSelectedOrder,
        (segment: SaleSegmentState, data: Order) => {
          const lOrder = (action as SaleActions.UpdateSelectedOrder).payload.data;
          const transaction = segment.selectedOrder?.transaction || null;
          return {
            ...segment,
            selectedOrder: {
              ...segment.selectedOrder,
              transaction: {
                ...transaction,
                order: {
                  ...lOrder
                }
              }
            }
          };
        });
    }

    case SaleActionTypes.UpdateSelectedOrderTransaction: {
      return generateTabSegmentState(state, action as SaleActions.UpdateSelectedOrderTransaction,
        (segment: SaleSegmentState, data: Order) => {
          const transaction = (action as SaleActions.UpdateSelectedOrderTransaction).payload.data;
          return {
            ...segment,
            selectedOrder: {
              ...segment.selectedOrder,
              transaction: {
                ...segment.selectedOrder.transaction,
                ...transaction
              }
            }
          };
        });
    }

    case SaleActionTypes.GetSelectedOrder: {
      return generateTabSegmentState(state, action as SaleActions.GetSelectedOrder,
        (segment: SaleSegmentState, data: GetSelectedOrderParams) => {
          return {
            ...segment,
            selectedOrder: {
              ...segment.selectedOrder,
              selectedOrderLoading: true,
              selectedOrderError: '',
            }
          };
        });
    }
    case SaleActionTypes.GetSelectedOrderSuccess: {
      return generateTabSegmentState(state, action as SaleActions.GetSelectedOrderSuccess,
        (segment: SaleSegmentState, data: Transaction) => {
          const transaction: Transaction = (action as SaleActions.GetSelectedOrderSuccess).payload.data;
          return {
            ...segment,
            transaction: SaleService.initialTransaction(),
            selectedOrder: {
              transaction,
              selectedOrderLoading: false,
              selectedOrderError: '',
            }
          };
        });
    }
    case SaleActionTypes.GetSelectedOrderError: {
      return generateTabSegmentState(state, action as SaleActions.GetSelectedOrderError,
        (segment: SaleSegmentState, data: string) => {
          const error: string = (action as SaleActions.GetSelectedOrderError).payload.data;
          return {
            ...segment,
            selectedOrder: {
              ...segment.selectedOrder,
              selectedOrderLoading: false,
              selectedOrderError: error,
            }
          };
        });
    }

    // Change Order
    case SaleActionTypes.ChangeTransaction: {
      return generateTabSegmentState(state, action as SaleActions.ChangeTransaction,
        (segment: SaleSegmentState, data: ChangeTransactionParams) => {
          return {
            ...segment,
            transaction: {
              ...segment.transaction,
              changeTransaction: true
            },
            transactionState: {
              loading: true,
              error: '',
              success: false,
              transaction: null,
              changeTransaction: true
            }
          };
        });
    }
    case SaleActionTypes.ChangeOrderSuccess: {
      return generateTabSegmentState(state, action as SaleActions.ChangeOrderSuccess,
        (segment: SaleSegmentState, data: Transaction) => {
          const transaction: Transaction = (action as SaleActions.ChangeOrderSuccess).payload.data;
          let order: Order = null;
          if (transaction?.transactionType === TransactionType.ORDER) {
            order = {
              ...transaction.order,
              activeStep: {
                name: 'Transaction',
                number: OrderStepNumbers.startTransaction
              },
              steps: addOrderSteps(transaction)
            };
          }
          const updatedTransaction = {
            ...segment.transaction,
            ...transaction,
            order,
            transactionItems: addUuid(transaction)
          };
          return {
            ...segment,
            transaction: updatedTransaction,
            transactionBackup: updatedTransaction,
            transactionState: {
              loading: false,
              error: '',
              success: true,
              transaction: null,
              changeTransaction: true
            }
          };
        });
    }
    case SaleActionTypes.ChangeOrderError: {
      return generateTabSegmentState(state, action as SaleActions.ChangeOrderError,
        (segment: SaleSegmentState, data: string) => {
          const error: string = (action as SaleActions.ChangeOrderError).payload.data;
          return {
            ...segment,
            transactionState: {
              loading: false,
              error,
              success: false,
              transaction: null,
              changeTransaction: true
            }
          };
        });
    }

    // Send Notification
    case SaleActionTypes.SendTransactionNotification: {
      return generateTabSegmentState(state, action as SaleActions.SendTransactionNotification,
        (segment: SaleSegmentState, data: NotificationInputParams) => {
          return {
            ...segment,
            notification: {
              ...segment.notification,
              notificationState: {
                loading: true,
                success: false,
                error: false,
              }
            }
          };
        });
    }
    case SaleActionTypes.SendTransactionNotificationSuccess: {
      return generateTabSegmentState(state, action as SaleActions.SendTransactionNotificationSuccess,
        (segment: SaleSegmentState, data: boolean) => {
          return {
            ...segment,
            notification: {
              ...segment.notification,
              notificationState: {
                loading: false,
                success: true,
                error: false
              }
            }
          };
        });
    }
    case SaleActionTypes.SendTransactionNotificationError: {
      return generateTabSegmentState(state, action as SaleActions.SendTransactionNotificationError,
        (segment: SaleSegmentState, data: boolean) => {
          const error: boolean = (action as SaleActions.SendTransactionNotificationError).payload.data;
          return {
            ...segment,
            notification: {
              ...segment.notification,
              notificationState: {
                loading: false,
                success: false,
                error
              }
            }
          };
        });
    }

    // Create Notification
    case SaleActionTypes.CreateTransactionNotification: {
      return generateTabSegmentState(state, action as SaleActions.CreateTransactionNotification,
        (segment: SaleSegmentState, data: CreateNotificationInputParams) => {
          return {
            ...segment,
            notification: {
              ...segment.notification,
              notificationState: {
                loading: true,
                success: false,
                error: false,
              }
            }
          };
        });
    }
    case SaleActionTypes.CreateTransactionNotificationSuccess: {
      return generateTabSegmentState(state, action as SaleActions.CreateTransactionNotificationSuccess,
        (segment: SaleSegmentState, data: boolean) => {
          return {
            ...segment,
            notification: {
              ...segment.notification,
              notificationState: {
                loading: false,
                success: true,
                error: false
              }
            }
          };
        });
    }
    case SaleActionTypes.CreateTransactionNotificationError: {
      return generateTabSegmentState(state, action as SaleActions.CreateTransactionNotificationError,
        (segment: SaleSegmentState, data: boolean) => {
          const error: boolean = (action as SaleActions.CreateTransactionNotificationError).payload.data;
          return {
            ...segment,
            notification: {
              ...segment.notification,
              notificationState: {
                loading: false,
                success: false,
                error
              }
            }
          };
        });
    }

    // Navigation Open / Remove tab etc.
    case NavigationActionTypes.OpenTab: {
      return generateInitialTabSegmentState(state, action as NavigationActions.OpenTab, initialSaleSegmentState);
    }
    case NavigationActionTypes.RemoveTab: {
      return generateRemoveTabSegmentState(state, action as NavigationActions.RemoveTab);
    }
  }
  return {
    ...state
  };
}

const addTransactionItem = (transactionItemParam: AddTransactionItemParam): TransactionItem => {
  const {product, menuButtonId, menuId, itemQty} = transactionItemParam;
  const productTaxCode = product.taxCode;
  const itemTaxCode = product.taxCode;
  const status = TransactionItemStatus.ACTIVE;
  const discount = 0.00;
  const shortName = product.shortName ? product.shortName : product.productName;
  const {
    price: unitPrice,
    id: productId,
    productName: longName,
    sku, smallImage, webUrl,
    productConfig, posProductType
  } = product;

  return {
    ...SaleService.initialTransactionItem(),
    itemQty,
    productTaxCode,
    itemTaxCode,
    status,
    discount,
    shortName,
    unitPrice,
    productPrice: unitPrice,
    productId,
    longName,
    sku,
    smallImage,
    webUrl,
    menuId,
    menuButtonId,
    productConfig,
    posProductType
  };
};

const addTransactionPayment = (transactionPayment: Partial<TransactionPayment>): TransactionPayment => {
  const paymentDate = UtilsService.currentDate();

  return {
    ...SaleService.initialTransactionPayment(),
    ...transactionPayment,
    paymentDate
  };
};

const addCustomization = (): Customization => {
  return {
    ...SaleService.initialCustomizationState(),
  };
};

const addOrderSteps = (transaction: Transaction): OrderStep[] => {
  const store = transaction.order?.fulfillmentStoreNumber === 0 ? 'Web'
    : transaction.order?.fulfillmentStoreNumber === 1000 ? 'Mississauga' : 'Brampton';
  const orderSteps = JSON.parse(JSON.stringify(OrderSteps));

  orderSteps[0].name = `${transaction.customer?.firstName} ${transaction.customer?.lastName}`;
  orderSteps[1].name = `${UtilsService.TitleCase(transaction.order?.fulfillmentMethod)} @ ${store}`;
  return orderSteps;
};

const addUuid = (transaction: Transaction): TransactionItem[] => {
  const transactionItems = JSON.parse(JSON.stringify(transaction.transactionItems));

  return transactionItems.map(transactionItem => {
    return {
      ...transactionItem,
      uuid: UtilsService.uuid()
    };
  });
};
