import { Injectable } from '@angular/core';
import {storagePrefix} from 'src/app/core/constants/constants';
import { ApplicationStorage } from './storage.interface';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService implements ApplicationStorage {
  private prefix = storagePrefix;
  private sessionStorageRef: Storage;

  constructor() {
    this.sessionStorageRef = window.sessionStorage;
  }

  public set(key: string, value: any) {
    this.sessionStorageRef.setItem(`${this.prefix}${key}`, value);
  }

  public get(key: string): any {
    return this.sessionStorageRef.getItem(`${this.prefix}${key}`) || null;
  }

  public remove(key: string) {
    this.sessionStorageRef.removeItem(`${this.prefix}${key}`);
  }

  public exists(key: string): boolean {
    return typeof this.get(`${this.prefix}${key}`) !== 'undefined';
  }

  public canUse() {
    const test = `${this.prefix}test`;
    try {
      this.sessionStorageRef.setItem(test, test);
      this.sessionStorageRef.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }
}
