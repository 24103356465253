import {Log} from 'src/app/core/api-models/logs';
import {PayloadAction} from 'src/app/store/models/payload-action';

export enum LogActionTypes {
  SaveLogToServer = '[Log - Save] Save Log To Server'
}

// tslint:disable-next-line:no-namespace
export namespace LogActions {

  export class SaveLogToServer extends PayloadAction<Log[]> {
    readonly type = LogActionTypes.SaveLogToServer;
  }

}
