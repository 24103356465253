import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, filter, first, switchMap, tap} from 'rxjs/operators';
import {PopupData, PopupIcon, PopupType} from 'src/app/core/models/popup.model';
import {PopupModalService} from 'src/app/core/services/modals/popup-modal.service';
import {AppStore} from 'src/app/store/index.reducer';
import {MasterDataActions} from 'src/app/store/master-data/master-data.actions';
import {MasterDataState} from 'src/app/store/master-data/master-data.reducer';
import {MasterDataSelectors} from 'src/app/store/master-data/master-data.selectors';

@Injectable({
  providedIn: 'root'
})
export class PosResolveService implements Resolve<boolean> {
  masterData$: Observable<MasterDataState> = this.store.select(MasterDataSelectors.masterData);

  constructor(
    private store: Store<AppStore>,
    private popupModalService: PopupModalService
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const popupData: PopupData = {
      title: 'Error',
      message: 'Error in starting the application. Please try again. If the issue persists, contact Technical Support.',
      icon: PopupIcon.alert,
      type: PopupType.alert,
      needCancel: false
    };

    let firstTime = true;
    this.store.dispatch(new MasterDataActions.UpdateRefreshToken());

    return this.masterData$.pipe(
      filter((masterData: MasterDataState) => !masterData.tokenLoading),
      tap(() => {
        if (firstTime) {
          this.store.dispatch(new MasterDataActions.LoadStores());
          this.store.dispatch(new MasterDataActions.LoadMenus());
          this.store.dispatch(new MasterDataActions.LoadEmployees());
          this.store.dispatch(new MasterDataActions.LoadCountryCodes());
          firstTime = false;
        }
      }),
      filter((dataState: MasterDataState) =>
        !(dataState.storesLoading || dataState.menusLoading || dataState.employeesLoading)),
      filter((data: MasterDataState) =>
        data.stores.length > 0 && data.menus.length > 0 && data.employees.length > 0),
      first(),
      switchMap(() => of(true)),
      catchError(error => {
        this.popupModalService.openPopup(popupData).subscribe();
        return of(false);
      })
    );
  }
}
