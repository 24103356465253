import { BaseAction } from 'src/app/store/models/base-action';
import { NavigationTabId } from 'src/app/core/models/common.model';

export interface TabActionData<T = null> {
  tabId: NavigationTabId;
  data: T;
}

export abstract class TabAction<T> extends BaseAction {

  constructor(public payload: TabActionData<T>) {
    super();
  }

}

