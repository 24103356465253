import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import * as Sentry from '@sentry/browser';
import { appVersionNumber } from 'src/app/core/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  constructor() {
  }

  init() {
    if (environment.production) {
      Sentry.init({
        release: appVersionNumber,
        dsn: 'https://0bf3d0df9b1d4c6682c0d4a994a89697@o424128.ingest.sentry.io/5355543',
        // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
        // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
        // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
        defaultIntegrations: false,
        integrations: [
          new Sentry.Integrations.TryCatch({
            XMLHttpRequest: false,
          })
        ],
      });
    }
  }

  handleError(error: any) {
    if (environment.production) {
      // Capture handled exception and send it to Sentry.
      const eventId = Sentry.captureException(error);

      // Optionally show user dialog to provide details on what happened.
      Sentry.showReportDialog({eventId});
    } else {
      // When in development mode, log the error to console for immediate feedback.
      console.error(error);
    }
  }

  configureScope(callback: (scope: Sentry.Scope) => void) {
    Sentry.configureScope(callback);
  }
}
