import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, first, flatMap, map, tap} from 'rxjs/operators';
import {Menu, MenuButton, Product} from 'src/app/core/api-models/menu.model';
import {PhysicalStore} from 'src/app/core/api-models/physical-store';
import {RegisterSecurity, SetupRegisterParams, SetupRegisterResponse} from 'src/app/core/api-models/register';
import {HttpClientWrapper} from 'src/app/core/api/http-wrapper.service';
import {Constants, securityKey} from 'src/app/core/constants/constants';
import {productConfig, ProductConfig} from 'src/app/core/constants/product-config.constants';
import {Country, Employee, VerifyPasswordParams} from 'src/app/core/models/common.model';
import {NavigationService} from 'src/app/core/services/navigation.service';
import {StorageService, StoreType} from 'src/app/core/services/storage/storage.service';
import {UtilsService} from 'src/app/core/services/utils.service';
import {AppStore} from 'src/app/store/index.reducer';
import {MasterDataActions, MasterDataActionTypes} from 'src/app/store/master-data/master-data.actions';

@Injectable()
export class MasterDataEffects {

  constructor(private actions$: Actions,
              private store: Store<AppStore>,
              private navigation: NavigationService,
              private httpClientWrapper: HttpClientWrapper,
              private storageService: StorageService) {
  }

  @Effect()
  loadStores = this.actions$.pipe(
    ofType(MasterDataActionTypes.LoadStores),
    flatMap((action: MasterDataActions.LoadStores) => {
      return this.getStores().pipe(
        first(),
        map((data: PhysicalStore[]) =>
          new MasterDataActions.LoadStoresSuccess(data)
        ),
        catchError((error: string) =>
          of(new MasterDataActions.LoadStoresError(error))
        )
      );
    }));

  @Effect()
  setupRegister = this.actions$.pipe(
    ofType(MasterDataActionTypes.SetupRegister),
    flatMap((action: MasterDataActions.SetupRegister) => {
      return this.postSetupRegister(action.payload).pipe(
        first(),
        tap((data: SetupRegisterResponse) =>
          this.store.dispatch(
            new MasterDataActions.StoreToken(data))),
        map((data: SetupRegisterResponse) =>
          new MasterDataActions.SetupRegisterSuccess({
            registerNumber: data.registerNumber,
            storeNumber: data.storeNumber,
            role: data.role,
            appConfig: data.appConfig
          })
        ),
        catchError((error: Error) => {
            return of(new MasterDataActions.SetupRegisterError(error.message));
          }
        )
      );
    }));

  @Effect({dispatch: false})
  storeToken = this.actions$.pipe(
    ofType(MasterDataActionTypes.StoreToken),
    flatMap((action: MasterDataActions.StoreToken) =>
      this.storeTokenInStorage(action.payload))
  );


  @Effect()
  updateRefreshToken = this.actions$.pipe(
    ofType(MasterDataActionTypes.UpdateRefreshToken),
    flatMap((action: MasterDataActions.UpdateRefreshToken) => {
      return this.refreshToken().pipe(
        first(),
        tap((data: SetupRegisterResponse) =>
          this.store.dispatch(
            new MasterDataActions.StoreToken(data))),
        tap((data: SetupRegisterResponse) =>
          this.store.dispatch(
            new MasterDataActions.SetupRegisterSuccess({
              registerNumber: data.registerNumber,
              storeNumber: data.storeNumber,
              role: data.role,
              appConfig: data.appConfig
            }))
        ),
        map(() =>
          new MasterDataActions.RefreshTokenSuccess(true)
        ),
        catchError((error: string) =>
          of(new MasterDataActions.RefreshTokenError(true))
        )
      );
    }));

  @Effect()
  loadMenus = this.actions$.pipe(
    ofType(MasterDataActionTypes.LoadMenus),
    flatMap((action: MasterDataActions.LoadMenus) => {
      return this.getMenus().pipe(
        map((data: Menu[]) =>
          new MasterDataActions.LoadMenusSuccess(data)
        ),
        catchError((error: string) =>
          of(new MasterDataActions.LoadMenusError(error))
        )
      );
    }));

  @Effect()
  loadEmployees = this.actions$.pipe(
    ofType(MasterDataActionTypes.LoadEmployees),
    flatMap((action: MasterDataActions.LoadEmployees) => {
      return this.getEmployees().pipe(
        map((data: Employee[]) =>
          new MasterDataActions.LoadEmployeesSuccess(data)
        ),
        catchError((error: string) =>
          of(new MasterDataActions.LoadEmployeesError(error))
        )
      );
    }));

  @Effect()
  verifyPassword = this.actions$.pipe(
    ofType(MasterDataActionTypes.VerifyPassword),
    flatMap((action: MasterDataActions.VerifyPassword) => {
      return this.verifyEmployeePassword(action.payload).pipe(
        map((data: boolean) =>
          new MasterDataActions.VerifyPasswordSuccess(data)
        ),
        catchError((error: Error) =>
          of(new MasterDataActions.VerifyPasswordError(error.message))
        )
      );
    }));

  @Effect()
  loadCountryCodes = this.actions$.pipe(
    ofType(MasterDataActionTypes.LoadCountryCodes),
    flatMap((action: MasterDataActions.LoadCountryCodes) => {
      return this.getCountryCodes().pipe(
        map((data: Country[]) =>
          new MasterDataActions.LoadCountryCodesSuccess(data)
        ),
        catchError((error: string) =>
          of(new MasterDataActions.LoadCountryCodesError(error))
        )
      );
    }));

  getStores(): Observable<PhysicalStore[]> {
    return this.httpClientWrapper.get<PhysicalStore[]>(Constants.apiPaths.getStores);
  }

  postSetupRegister(setupRegisterParams: SetupRegisterParams): Observable<SetupRegisterResponse> {
    return this.httpClientWrapper.post<SetupRegisterParams, SetupRegisterResponse>(null,
      (Constants.apiPaths.setupRegister), setupRegisterParams);
  }

  storeTokenInStorage(setupRegisterParams: SetupRegisterResponse): Observable<boolean> {
    const {token, ...localStorage} = setupRegisterParams;
    this.storageService.set(securityKey, localStorage);
    this.storageService.set(securityKey, {token}, {storeType: StoreType.SessionStorage});
    return of(true);
  }

  refreshToken(): Observable<RegisterSecurity> {
    const refreshToken = this.storageService.get(securityKey)
      && this.storageService.get(securityKey).refreshToken || '';
    return this.httpClientWrapper.post<{ refreshToken: string }, RegisterSecurity>(null,
      (Constants.apiPaths.refreshToken), {refreshToken});
  }

  getMenus(): Observable<Menu[]> {
    const searchMenu: Menu[] = [{
      menuName: 'Search',
      buttons: null,
      menuId: 0,
      active: false,
      displayOrder: 0
    }];
    return this.httpClientWrapper.get<Menu[]>(Constants.apiPaths.getMenus)
      .pipe(
        map((results: Menu[]) => {
          const menus = results.map(menu => {
            // Sort menu buttons
            menu.buttons.sort((a: MenuButton, b: MenuButton) =>
              a.gridPoistion < b.gridPoistion ? -1 : a.gridPoistion > b.gridPoistion ? 1 : 0);

            menu.buttons.map(button => {
              button.product = UtilsService.updateProduct(button.product);
              return button;
            });
            return menu;
          });

          const menusSorted = menus.sort(this.sortMenu);
          return searchMenu.concat(menusSorted);
        }));
  }

  private sortMenu(a: Menu, b: Menu) {
    return a.displayOrder > b.displayOrder ? 1 : a.displayOrder < b.displayOrder ? -1 : 0;
  }

  getEmployees(): Observable<Employee[]> {
    return this.httpClientWrapper.get<Employee[]>(Constants.apiPaths.getEmployees);
  }

  verifyEmployeePassword(params: VerifyPasswordParams): Observable<boolean> {
    const url = `${Constants.apiPaths.getEmployees}/${params.employee.id}${Constants.apiPaths.verifyPassword}`;
    return this.httpClientWrapper.post<{ password: string }, boolean>(params.employee.fullName,
      url, {password: params.password});
  }

  getCountryCodes(): Observable<Country[]> {
    return this.httpClientWrapper.get<Country[]>(Constants.apiPaths.getCountryCodes);
  }

}
