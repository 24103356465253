/* tslint:disable:import-blacklist*/
import {
  ActionReducerMap,
  ActionReducer,
  MetaReducer,
} from '@ngrx/store';
import {DeliveryEffects} from 'src/app/store/delivery/delivery.effects';
import {deliveryReducer, DeliveryState} from 'src/app/store/delivery/delivery.reducer';
import {LogEffects} from 'src/app/store/log/log.effects';
import {logReducer, LogState} from 'src/app/store/log/log.reducer';
import {MasterDataEffects} from 'src/app/store/master-data/master-data.effects';
import {masterDataReducer, MasterDataState, masterDataStateKey} from 'src/app/store/master-data/master-data.reducer';
import {storeFreeze} from 'ngrx-store-freeze';
import {navigationReducer, NavigationState, navigationStateKey} from 'src/app/store/navigation/navigation.reducer';
import {NavigationEffects} from 'src/app/store/navigation/navigation.effects';
import {PublicEffects} from 'src/app/store/public/public.effects';
import {publicReducer, PublicState} from 'src/app/store/public/public.reducer';
import {saleReducer, SaleState, saleStateKey} from 'src/app/store/sale/sale.reducer';
import {SaleEffects} from 'src/app/store/sale/sale.effects';
import {environment} from 'src/environments/environment';
import {localStorageSync} from 'ngrx-store-localstorage';
import {customerReducer, CustomerState, CustomerStateKey} from 'src/app/store/customer/customer.reducer';
import {CustomerEffects} from 'src/app/store/customer/customer.effects';
import {employeeReducer, EmployeesInOutState, employeeStateKey} from 'src/app/store/employee/employee.reducer';
import {EmployeeEffects} from 'src/app/store/employee/employee.effects';
import {closingReducer, ClosingState} from './closing/closing.reducer';
import {ClosingEffects} from './closing/closing.effects';
import createNgrxMiddleware from 'logrocket-ngrx';
import * as LogRocket from 'logrocket';

/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */

export interface AppStore {
  navigation: NavigationState;
  sale: SaleState;
  masterData: MasterDataState;
  customer: CustomerState;
  log: LogState;
  employee: EmployeesInOutState;
  closing: ClosingState;
  public: PublicState;
  delivery: DeliveryState;
}

export const reducers: ActionReducerMap<AppStore> = {
  navigation: navigationReducer,
  sale: saleReducer,
  masterData: masterDataReducer,
  customer: customerReducer,
  log: logReducer,
  employee: employeeReducer,
  closing: closingReducer,
  public: publicReducer,
  delivery: deliveryReducer
};

export const effects = [
  NavigationEffects,
  SaleEffects,
  MasterDataEffects,
  CustomerEffects,
  LogEffects,
  EmployeeEffects,
  ClosingEffects,
  PublicEffects,
  DeliveryEffects
];

export const localStorageCacheKeys = [
  navigationStateKey,
  saleStateKey,
  masterDataStateKey,
  CustomerStateKey,
  employeeStateKey,
];

// console.log all actions
export function logger(reducer: ActionReducer<AppStore>): ActionReducer<AppStore> {
  return (state: AppStore, action: any): AppStore => {
    return reducer(state, action);
  };
}

export function localStorageSyncReducer(reducer: ActionReducer<AppStore>): ActionReducer<AppStore> {
  const rehydrate = checkRehydrate();
  if (!rehydrate) {
    window.location.href = window.location.href.split('?')[0];
  }

  // if (window.localStorage || window.sessionStorage) {
  if (window.sessionStorage) {
    // const storage: Storage = window.localStorage || window.sessionStorage;
    const storage: Storage = window.sessionStorage;
    return localStorageSync({
      keys: localStorageCacheKeys,
      restoreDates: false,
      rehydrate,
      removeOnUndefined: true,
      storage,
    })(reducer);
  }
  return reducer;
}

const logrocketMiddleware = createNgrxMiddleware(LogRocket);

/**
 * By default, @stores/stores uses combineReducers with the reducer map to compose
 * the root meta-reducer. To bundleAdd more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<AppStore>[] = !environment.production
  ? [
    storeFreeze,
    localStorageSyncReducer,
  ]
  : [
    localStorageSyncReducer,
    logrocketMiddleware
  ];

function checkRehydrate() {
  const params = new URLSearchParams(window.location.search);
  const clear  = params.get('clear');
  return !!!clear;
}
