import {Product} from 'src/app/core/api-models/menu.model';

export interface Products {
  [key: number]: Product;
}
export interface varitionGroup {
  variations: Variation[];
}
export interface Variation {
  name: string;
  description: string;
  imageCode: string;
  price: string;
  sortOrder: number;
  status: string;
  variationType: VariationType;
  linkedId: number;
  linkedVariations: number[];
}
export enum VariationType {
  sponge = 'C',
  size = 'S',
  flavour = 'F'
}
