import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {HttpClientWrapper} from 'src/app/core/api/http-wrapper.service';
import {AppStore} from 'src/app/store/index.reducer';
import {catchError, first, flatMap, map} from 'rxjs/operators';
import {Constants} from 'src/app/core/constants/constants';
import {Observable, of} from 'rxjs';
import {NavigationService} from 'src/app/core/services/navigation.service';
import {ClosingActions, ClosingActionTypes} from './closing.action';
import {Closing, ClosingReq, ExistingClosingReq, PosReport, SubmitClosingReq} from '../../core/api-models/closing';
import {isEmpty as lIsEmpty} from 'lodash';
import {StorageService, StoreType} from 'src/app/core/services/storage/storage.service';

@Injectable()
export class ClosingEffects {

  constructor(private actions$: Actions,
              private store: Store<AppStore>,
              private navigation: NavigationService,
              private httpClientWrapper: HttpClientWrapper) {
  }

  @Effect()
  LoadClosingInfo = this.actions$.pipe(
    ofType(ClosingActionTypes.LoadClosingPosInfo),
    flatMap((action: ClosingActions.LoadClosingPosInfo) => {
      return this.getClosingInfo(action.payload).pipe(
        first(),
        map((data: PosReport) => {
          if (data.error === 'NoTransactionExists') {
            return of(new ClosingActions.LoadClosingInfoPosError(data.error));
          } else {
            return new ClosingActions.LoadClosingInfoPosSuccess(data);
          }
          }
        ),
        catchError((error: any) => {
          return of(new ClosingActions.LoadClosingInfoPosError(error?.message || error));
          }
        )
      );
    }));

  @Effect()
  LoadExistingClosingInfo = this.actions$.pipe(
    ofType(ClosingActionTypes.LoadExistingClosing),
    flatMap((action: ClosingActions.LoadExistingClosing) => {
        return this.getExistingClosingInfo(action.payload).pipe(
          first(),
          map((data: Closing) => {
            if (!lIsEmpty(data)) {
              return new ClosingActions.LoadExistingClosingSuccess(data);
            }
            return of(new ClosingActions.LoadExistingClosingError(null));
          }),
          catchError((error: any) => {
              return of(new ClosingActions.LoadExistingClosingError(error?.message || error));
            }
          )
        );
      }
    )
  );

  @Effect()
  SubmitClosingReport = this.actions$.pipe(
    ofType(ClosingActionTypes.SubmitClosingReport),
    flatMap((action: ClosingActions.SubmitClosingReport) => {
      return this.submitClosing(action.payload).pipe(
        first(),
        map((data: boolean) =>
          new ClosingActions.SubmitClosingReportSuccess(data)
        ),
        catchError((error: any) =>
          of(new ClosingActions.SubmitClosingReportError(error))
        )
      );
    }));

  getClosingInfo(requestBody: ClosingReq): Observable<PosReport> {
    const employee = this.navigation?.currentEmployee?.fullName || 'No employee';
    return this.httpClientWrapper.post(employee, Constants.apiPaths.getClosingReport, requestBody);
  }

  getExistingClosingInfo(requestBody: ExistingClosingReq): Observable<Closing> {
    const {storeNumber, date} = requestBody;
    const url = `${Constants.apiPaths.closing}/${storeNumber}/${date}`
    return this.httpClientWrapper.get(url);
  }

  submitClosing(requestBody: SubmitClosingReq): Observable<boolean> {
    const employee = this.navigation?.currentEmployee?.fullName || 'No employee';
    return this.httpClientWrapper.put(employee, `${Constants.apiPaths.getClosingReport}/${requestBody?.id}`,
      requestBody?.reqBody);
  }

}
