import {VoidPaymentReasonList} from 'src/app/core/api-models/closing';
import {PageType, PaymentCode} from 'src/app/core/constants/common.enum';
import {Routes, UrlTree} from 'src/app/core/constants/route-constants';
import {EmployeeModalConfig, EmployeeRole, NavigationTabMetadata} from 'src/app/core/models/common.model';
import {PopupReturnValue} from 'src/app/core/models/popup.model';

export let appVersionNumber = 'Failed to read version';

try {
  const packageJson = require('../../../../package.json');
  appVersionNumber = packageJson.version;
} catch (e) {
  console.error(`'package.json' not found`);
}


export let buildMetaData: { hash: string; buildDate: string } = { hash: '', buildDate: '' };
try {
  buildMetaData = require('../../../../build-metadata.json');
} catch (e) {
  console.error(`'build-metadata.json' not found`);
}

export const storagePrefix = 'rashmis-bakery.';
export const securityKey = 'security';

export const Config = {
  navigation: {
    maxTabs: 10
  }
};

export const Constants = {
  routes: Routes,
  urlTree: UrlTree,
  modalIds: {
    genericPopupModal: 'genericPopupModal',
    loaderPopupModal: 'loadingModal',
  },
  alerts: {
    alert: 'alert',
    warning: 'warning',
    info: 'info',
    success: 'success',
    blockAlert: 'block-alert',
    blockSuccess: 'block-success',
    technical: 'technical'
  },
  apiPaths: {
    getStores: '/public/stores',
    setupRegister: '/pos/setup-register',
    refreshToken: '/pos/refresh-token',
    getMenus: '/pos/menus',
    getEmployees: '/pos/employees',
    verifyPassword: '/verify',
    saveLogs: '/pos/logs',
    getCustomers: '/pos/customers',
    createCustomer: '/pos/customer',
    searchCustomers: '/pos/search/customers/',
    updateCustomers: '/pos/customers',
    getProduct: '/pos/products/?',
    getUsers: '/pos/users',
    quickSale: '/pos/quick-sale',
    placeOrder: '/pos/place-order',
    updateOrderOnly: '/pos/orders',
    searchProduct: '/pos/product/search',
    searchOrder: '/pos/search-orders',
    payInOut: '/pos/pay-in-out',
    searchTransaction: '/pos/search-transactions',
    closing: '/pos/closing',
    orderStatus: '/pos/orders-status',
    recentOrders: '/pos/recent-orders',
    ingestedOrderStatus: '/pos/ingestion/order-status',
    updateAddress: '/pos/addresses',
    getCountryCodes: '/pos/country-codes',
    selectEmployee: '/pos/select-employee',
    employeeReport: '/pos/employee-report',
    orderForm: '/pos/order-form',
    getProductInfo: '/pos/products',
    employeeTimeIn: '/pos/employees-timein',
    updateEmployees: '/pos/employees',
    getClosingReport: '/pos/closing',
    transactions: '/pos/transactions',
    shopifyOrders: '/pos/shopify-order',
    createNotification: '/pos/notification',
    sendNotification: '/pos/send-notification',
    reminders: '/pos/reminders',
    publicTransaction: '/public/search-transactions',
    publicTransactionIdPhone: '/public/transaction-id-phone',
    deliveryTransaction: '/public/search-transactions',
    customerReview: '/public/feedback'
  },
  time: {
    locale: 'en-US',
    zone: 'America/New_York'
  },
  debounceTime: 1000,
  emailRegex: '^([a-zA-Z0-9_\\.\\-])+\\@(([a-zA-Z0-9\\-])+\\.)+([a-zA-Z0-9]{2,4})+$',
  maxTiers: 10,
  other: 'other',
  closingDiffLimit: 10,
  duplicatePhone: 'DUPLICATE_PHONE'
};

export const pageConfig: { [type: string]: NavigationTabMetadata } = {
  [PageType.home]: {
    type: PageType.home,
    title: ''
  },
  [PageType.searchOrder]: {
    type: PageType.searchOrder,
    title: 'Search Order',
    allowClosing: true,
  },
  [PageType.sale]: {
    type: PageType.sale,
    title: '{{enum}} - Sale',
    allowMultiple: true,
    employeeModalConfig: EmployeeModalConfig.EmployeeOnly,
    role: EmployeeRole.csr
  },
  [PageType.searchSale]: {
    type: PageType.searchSale,
    title: 'Search Sale',
    allowClosing: true,
  },
  [PageType.searchPayment]: {
    type: PageType.searchPayment,
    title: 'Search Payment',
    allowClosing: true,
  },
  [PageType.searchEmployee]: {
    type: PageType.searchEmployee,
    title: 'Search Employee',
    allowClosing: true,
    role: EmployeeRole.manager,
    employeeModalConfig: EmployeeModalConfig.EmployeeWithPassword
  },
  [PageType.order]: {
    type: PageType.order,
    title: '{{enum}} - Order',
    allowMultiple: true,
    employeeModalConfig: EmployeeModalConfig.EmployeeAndMedium,
    role: EmployeeRole.csr
  },
  [PageType.customer]: {
    type: PageType.customer,
    title: 'Customer',
    allowClosing: true,
  },
  [PageType.searchCustomer]: {
    type: PageType.searchCustomer,
    title: 'Search Customer',
    allowClosing: true,
  },
  [PageType.timeIn]: {
    type: PageType.timeIn,
    title: 'Time In',
    allowClosing: true,
  },
  [PageType.orderReport]: {
    type: PageType.orderReport,
    title: 'Order Receipt',
    allowClosing: true,
  },
  [PageType.closingReport]: {
    type: PageType.closingReport,
    title: 'Closing Report',
    employeeModalConfig: EmployeeModalConfig.EmployeeWithPassword
  },
  [PageType.viewClosingReport]: {
    type: PageType.viewClosingReport,
    title: 'Closing Report',
  },
  [PageType.payInOut]: {
    type: PageType.payInOut,
    title: '{{enum}} - Pay In / Out',
    employeeModalConfig: EmployeeModalConfig.EmployeeWithPassword,
    allowClosing: true,
    role: EmployeeRole.manager
  },
  [PageType.selectEmployee]: {
    type: PageType.selectEmployee,
    title: 'Time In / Out',
    allowClosing: true,
    role: EmployeeRole.manager,
    employeeModalConfig: EmployeeModalConfig.EmployeeWithPassword
  },
  [PageType.employeeReport]: {
    type: PageType.employeeReport,
    title: 'Reports',
    allowClosing: true
  },
  [PageType.orderForm]: {
    type: PageType.orderForm,
    title: 'Order Form',
    allowClosing: true
  },
  [PageType.orderStatus]: {
    type: PageType.orderStatus,
    title: 'Order Status',
    allowClosing: true,
    // allowMultiple: true,
    // employeeModalConfig: EmployeeModalConfig.EmployeeOnly,
    // role: EmployeeRole.employee
  },
  [PageType.orderScan]: {
    type: PageType.orderScan,
    title: '{{enum}} - Order Scan',
    allowClosing: true,
    allowMultiple: false,
    employeeModalConfig: EmployeeModalConfig.EmployeeOnly,
    role: EmployeeRole.employee
  },
  [PageType.orderReminders]: {
    type: PageType.orderReminders,
    title: 'Reminders',
    allowClosing: true,
    allowMultiple: false
  },
  [PageType.orderPickup]: {
    type: PageType.orderPickup,
    title: 'Quick Pickups',
    allowClosing: true,
    allowMultiple: false
  }
};

export interface PaymentMetaData {
  value: PaymentCode;
  name: string;
  class: string;
  disabled: boolean;
  enableForSplit: boolean;
}

export const PaymentConfig: { [type: string]: PaymentMetaData } = {
  [PaymentCode.CASH]: {
    value: PaymentCode.CASH,
    name: 'Cash',
    class: 'rb-icon-money-dollar-circle',
    disabled: false,
    enableForSplit: true,
  },
  [PaymentCode.DEBIT]: {
    value: PaymentCode.DEBIT,
    name: 'Debit',
    class: 'rb-icon-bank-card',
    disabled: false,
    enableForSplit: true,
  },
  [PaymentCode.VISA]: {
    value: PaymentCode.VISA,
    name: 'Visa',
    class: 'rb-icon-visa',
    disabled: false,
    enableForSplit: true,
  },
  [PaymentCode.MASTER]: {
    value: PaymentCode.MASTER,
    name: 'Master Card',
    class: 'rb-icon-mastercard',
    disabled: false,
    enableForSplit: true,
  },
  [PaymentCode.PAYPAL]: {
    value: PaymentCode.PAYPAL,
    name: 'Paypal',
    class: 'rb-icon-paypal',
    disabled: false,
    enableForSplit: true,
  },
  [PaymentCode.GIFT]: {
    value: PaymentCode.GIFT,
    name: 'Gift Card',
    class: 'rb-icon-gift',
    disabled: true,
    enableForSplit: false,
  },
  [PaymentCode.SHOPIFY]: {
    value: PaymentCode.SHOPIFY,
    name: 'Shopify',
    class: 'rb-icon-shopify',
    disabled: false,
    enableForSplit: true,
  },
  [PaymentCode.AMEX]: {
    value: PaymentCode.AMEX,
    name: 'Amex',
    class: 'rb-icon-amex',
    disabled: false,
    enableForSplit: true,
  },
  [PaymentCode.ETRANSFER]: {
    value: PaymentCode.ETRANSFER,
    name: 'e-Transfer',
    class: 'rb-icon-exchange-funds',
    disabled: false,
    enableForSplit: true,
  },
  [PaymentCode.SQUARE]: {
    value: PaymentCode.SQUARE,
    name: 'Square',
    class: 'rb-icon-square',
    disabled: false,
    enableForSplit: true,
  }
};

export enum IconSize {
  xs = 'xs',
  small = 'small',
  medium = 'medium',
  large = 'large',
  xl = 'x-large'
}

export enum Params {
  Clear = 'yes'
}

export interface VoidPaymentModalResult {
  reasonCode: string;
  comments: string;
  action: PopupReturnValue;
}

