import {Log} from 'src/app/core/api-models/logs';
import {TransactionItem, TransactionItemWithTotal} from 'src/app/core/api-models/transaction-item';
import {TransactionPayment} from 'src/app/core/api-models/transaction.payment';
import {Employee, NavigationTabId, PhoneNumber} from 'src/app/core/models/common.model';
import {Customer, Order} from 'src/app/core/api-models/order';
import {Products} from 'src/app/core/api-models/product';

export enum TransactionType {
  BLANK = '',
  SALE = 'sale',
  ORDER = 'order',
  CLOSING = 'closing',
  PAY_IN = 'pay-in',
  PAY_OUT = 'pay-out',
}

export enum OrderStoreType {
  ORDER = 'order',
  FULFILLMENT = 'fulfillment',
  SALE = 'sale'
}

export enum TransactionStatus {
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  HOLD = 'hold',
  VOID = 'void',
  RETURNED = 'returned',
  CANCELLED = 'cancelled'
}

export interface CurrentPayment {
  previousAmountDue: number;
  previousDeposit: number;
  previousBalance: number;
  previousRounding: number;
  changeInAmountDue: number;
  totalAmountDue: number;
  balance: number;
  deposit: number;
  amountPaid: number;
  amountTendered: number;
  rounding: number;
  change: number;
}

export interface Transaction<T = Order> {
  tabId?: NavigationTabId;
  transactionId: number;
  activeItem: number;
  createTime: string;
  storeNumber: number;
  registerNumber: number;
  transactionType: TransactionType;
  subTotal: number;
  discountValue: number;
  discount: number;
  discountReason: string;
  discountType: string;
  discountDetails: string;
  couponCode: string;
  tax: number;
  itemsCount: number;
  customer: Customer;
  duplicateCustomer: Customer;
  orderId?: number;
  rewardsPointsAwarded: number;
  rewardsPointsUsed: number;
  rewardsPointsDollars: number;
  transactionStatus: TransactionStatus;
  zNumber: number;
  employee: Employee;
  transactionItems: TransactionItem[] | TransactionItemWithTotal[] | [];
  transactionPayments: TransactionPayment[] | [];
  currentPayment: CurrentPayment;
  amountDue: number;
  amountPaid: number;
  deposit: number;
  balance: number;
  rounding: number;
  amountTendered: number; // RAJIV: New Property
  change: number; // RAJIV: New Property
  changeTransaction: boolean; // RAJIV: New property
  order: T;
  orderBackup?: Order;
  logs: Log[];
  products: Products;
  productLoading: boolean;
  productError: string;
  updateCustomerLoading: boolean;
  updateCustomerError: string;
  isCustomerUpdated: boolean;
  isDelivery: boolean;
  isTiered: boolean;
  hasPhotoCake: boolean;
  hasFondantCake: boolean;
  shortCode?: string;
  randomId: string;
  transactionNote?: string;
  noEmail: boolean;
  noSms: boolean;
  isGlutenFree: boolean;
  isVegan: boolean;
  itemCountDescription: string;
}

export interface SearchParam {
  search: string;
  dateRange: DateRange;
  status: string | string[];
  orderAt: string;
  transactionType: string;
  dateRangeType: string;
  tags: string[];
  fulfillmentBy: string;
  orderStatus: string[];
  clear: boolean;
  paymentMethod: string;
  deposit: string;
  isStatusPage?: boolean;
}

export interface DateRange {
  from: Date;
  to: Date;
}

export interface SearchTransactions {
  searchTerm: Partial<SearchParam>;
  transactions: Transaction[];
  transactionsLoading: boolean;
  transactionsError: string;
  updateSearchedOrderLoading: boolean;
  updateSearchedOrderError: string;
}

export interface SelectedOrderTransaction {
  transaction: Transaction;
  selectedOrderLoading: boolean;
  selectedOrderError: string;
}

export enum NotificationEvent {
  OrderCreated = 'order-create',
  SaleCreated = 'sale-create',
  OrderUpdated = 'order-changed',
  OrderPickedUp = 'order-picked-p',
  OrderShipped = 'order-shipped',
  OrderDelivered = 'order-delivered',
  OrderReadyForPickup = 'order-ready-for-pickup',
  OrderReadyForPickupBrampton = 'order-ready-for-pickup-brampton',
  OrderReadyForPickupMississauga = 'order-ready-for-pickup-mississauga',
  OrderReadyForPickupMississaugaNoBalance = 'order-ready-for-pickup-mississauga-no-balance',
  OrderReadyForPickupMississaugaBalance = 'order-ready-for-pickup-mississauga-balance',
  OrderReadyForPickupBramptonNoBalance = 'order-ready-for-pickup-brampton-no-balance',
  OrderReadyForPickupBramptonBalance = 'order-ready-for-pickup-brampton-balance',
  HelpRequested = 'help-requested',
  ApplicationReloaded = 'application-reloaded',
  JoinQueue = 'join-queue',
}

export enum NotificationType {
  Email = 'EMAIL',
  SMS = 'SMS'
}

export interface NotificationState {
  loading: boolean;
  success: boolean;
  error: boolean;
}

export interface Notification {
  notificationState: NotificationState;
  transactionId: number;
  event: NotificationEvent;
  templateType: NotificationType;
}

export interface NotificationParam {
  recipient: string | PhoneNumber;
  notificationType: NotificationType;
}
