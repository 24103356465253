import { Injectable } from '@angular/core';
import {storagePrefix} from 'src/app/core/constants/constants';
import { ApplicationStorage } from './storage.interface';

declare const window;

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService implements ApplicationStorage {
  private prefix = storagePrefix;
  private localStorageRef: Storage;

  constructor() {
    this.localStorageRef = window.localStorage;
  }

  public set(key: string, value: any) {
    this.localStorageRef.setItem(`${this.prefix}${key}`, value);
  }

  public get(key: string): any {
    return this.localStorageRef.getItem(`${this.prefix}${key}`) || null;
  }

  public remove(key: string) {
    this.localStorageRef.removeItem(`${this.prefix}${key}`);
  }

  public removeBase(key: string) {
    this.localStorageRef.removeItem(key);
  }

  public exists(key: string): boolean {
    return typeof this.get(`${this.prefix}${key}`) !== 'undefined';
  }

  public canUse(): boolean {
    return !!this.localStorageRef;
  }
}
