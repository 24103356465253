import {CustomizationStep, OrderStep} from 'src/app/core/api-models/order';
import {Tag} from 'src/app/core/models/inscription-config';
import {Variation} from 'src/app/core/api-models/product';

export enum CustomizationStatus {
  InProgress,
  Completed,
  Cancelled
}

export interface Customization {
  inscriptionMessage: string;
  milestone: number;
  gender: string;
  tags: Tag[];
  textOption: string;
  textOn: string;
  decorationColors: string[];
  writingColors: string[];
  writingsOnTheCake: string;
  activeTierIndex: number;
  tiers: Tier[];
  biggerBoard: boolean;
  coloredMasking: boolean;
  noMasking: boolean;
  doubleHeight: boolean;
  tripleHeight: boolean;
  fondantCake: boolean;
  fondantDecorations: boolean;
  lessCream: boolean;
  chocolateMasking: boolean;
  vanillaMasking: boolean;
  steps: CustomizationStep [];
  activeStep?: CustomizationStep;
  note: string;
  otherSelected: boolean;
  customizationStatus: CustomizationStatus;
  edit: boolean;
  type: string;
}

export interface Tier {
  size: Variation;
  sponge: Variation;
  flavour: Variation;
}

export enum TierTypes {
  singleTier = 'Single Tier',
  multiTier = 'Multi Tier'
}

export enum CustomizationTypes {
  All = 'all',
  Web = 'web'
}

export const TypeList: string[] = ['web', 'all'];

export enum CustomizationStepNumbers {
  customizeCake = 0,
  customizeInscription = 1,
  summary = 2,
}
export enum TierFeatureNumber {
  sponge = 1,
  size = 2,
  flavour = 3
}

export enum TierTypeNumber {
  singleTier = 1,
  multiTier = 2
}

export const CustomizationSteps: CustomizationStep[] = [
  {name: 'Customize Cake', number : CustomizationStepNumbers.customizeCake},
  {name: 'Customize Inscription', number : CustomizationStepNumbers.customizeInscription},
  {name: 'Summary', number : CustomizationStepNumbers.summary}
];
