import {Transaction} from 'src/app/core/api-models/transaction';
import {DeliveryActions, DeliveryActionTypes} from 'src/app/store/delivery/delivery.actions';
import {BaseAction} from 'src/app/store/models/base-action';
import {PayloadAction} from 'src/app/store/models/payload-action';

export const DeliveryStateKey = 'delivery';

export const deliveryCacheKeys = [];

export interface DeliveryState {
  selectedTransaction: DeliveryTransactionState;
}

export interface TransactionState {
  loading: boolean;
  success: boolean;
  error: string;
}

export interface DeliveryTransactionState {
  transaction: Transaction;
  transactionState: TransactionState;
}

export const initialPublicTransactionState: DeliveryTransactionState = {
  transaction: null,
  transactionState: {
    loading: false,
    success: false,
    error: ''
  }
};

const initialDeliveryState: DeliveryState = {
  selectedTransaction: initialPublicTransactionState
};

export function deliveryReducer(state: DeliveryState = initialDeliveryState,
                                action: BaseAction | PayloadAction<any>): DeliveryState {
  switch (action?.type) {
    case DeliveryActionTypes.LoadTransaction: {
      return {
        ...state,
        selectedTransaction: {
          ...state.selectedTransaction,
          transactionState: {
            loading: true,
            success: false,
            error: ''
          }
        }
      };
    }
    case DeliveryActionTypes.LoadTransactionSuccess: {
      return {
        ...state,
        selectedTransaction: {
          ...state.selectedTransaction,
          transaction: (action as DeliveryActions.LoadTransactionSuccess).payload,
          transactionState: {
            loading: false,
            success: true,
            error: ''
          }
        }
      };
    }
    case DeliveryActionTypes.LoadTransactionError: {
      return {
        ...state,
        selectedTransaction: {
          ...state.selectedTransaction,
          transactionState: {
            loading: false,
            success: false,
            error: (action as DeliveryActions.LoadTransactionError).payload
          }
        }
      };
    }
  }
  return {
    ...state
  };
}


