// All Common Enums should be defined here

export enum ApplicationPage {
  BLANK = '',
  DEVELOPER = 'developer',
  SETUP_REGISTER = 'setup-register',
  POS = 'pos',
  CUSTOMER = 'customer',
  DELIVERY = 'delivery',
  DASHBOARD = 'dashboard',
}

export enum EnvironmentName {
  local,
  dev,
  prod
}

export enum StoreName {
  Mississauga = 1000,
  Brampton = 1001,
  Web = 0
}
export enum StoreShortName {
  Mississauga = 'm',
  Brampton = 'b',
  Web = 'w'
}

export enum OrderStatusEnum {
  ORDERED = 'ordered',
  PRINTED = 'printed',
  BAKED = 'baked',
  MASKED = 'masked',
  DECORATED = 'decorated',
  READY = 'ready',
  REMINDER_SENT = 'reminder',
  INCOMPLETE = 'incomplete',
  DELIVERY_STARTED = 'delivery-started',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  HOLD = 'hold'
}

export enum HomePageOrderSummaryEnum {
  TRANSACTION = 'transaction',
  ORDERED = 'ordered',
  PRINTED = 'printed',
  BAKED = 'baked',
  MASKED = 'masked',
  DECORATED = 'decorated',
  READY = 'ready',
  INCOMPLETE = 'incomplete',
  DELIVERY_STARTED = 'delivery-started',
  COMPLETED = 'completed',
}

export interface OrderStatusSummary {
  orderStatus: HomePageOrderSummaryEnum;
  totalCount: number;
  fulfillmentTime: string;
}

export enum SocketMessageEnum {
  ORDER_CREATED = 'order-create',
  ORDER_UPDATED = 'order-update',
  TRANSACTION_CREATED = 'order-update'
}

export enum TransactionItemStatus {
  ACTIVE = 'active',
  CANCELLED = 'cancelled'
}

export enum TransactionItemOptionStatus {
  ACTIVE = 'active',
  CANCELLED = 'cancelled'
}

export enum TransactionPaymentStatus {
  BLANK = 'blank',
  FULL = 'full',
  SPLIT = 'split'
}

export enum TaxCode {
  E = 'E',
  H = 'H'
}

export enum TaxPercentage {
  E = 0, // Tax exempted
  H = 13
}

export enum PageType {
  home = 'home-page',
  sale = 'sale-page',
  searchSale = 'search-page',
  searchPayment = 'search-payment',
  searchEmployee = 'search-employee',
  order = 'order-page',
  searchOrder = 'search-order-page',
  customer = 'customer-page',
  searchCustomer = 'search-customer-page',
  orderReport = 'order-report-page',
  timeIn = 'time-in-page',
  closingReport = 'closing-report-page',
  viewClosingReport = 'view-closing-report-page',
  payInOut = 'pay-out-page',
  selectEmployee = 'select-employee-page',
  employeeReport = 'employee-report',
  orderForm = 'order-form',
  orderStatus = 'order-status',
  orderScan = 'order-scan',
  orderReminders = 'order-reminders',
  orderPickup = 'order-pickup',
}

export enum FulfillmentType {
  Pickup = 'pickup',
  Delivery = 'delivery'
}

export enum DateSelectionType {
  OrderDate = 'order-date',
  FulfillmentDate = 'fulfillment-date'
}

export enum PaymentCode {
  BLANK = '',
  CASH = 'cash',
  VISA = 'visa',
  MASTER = 'master',
  DEBIT = 'debit',
  SHOPIFY = 'shopify',
  PAYPAL = 'paypal',
  GIFT = 'gift',
  AMEX = 'amex',
  ETRANSFER = 'interac',
  SQUARE = 'square'
}

export enum DiscountLevel {
  transaction = 'transaction',
  item = 'item'
}

export enum DiscountType {
  percentage = 'percentage',
  dollar = 'dollar'
}

export enum FlowType {
  order = 'order',
  customization = 'customization'
}

export enum PaymentType {
  cash = 'cash',
  credit = 'credit'
}

export enum CashCreditButtonTrigger {
  transaction,
  transactionReturn,
  orderComplete,
  orderCancel,
  orderChange
}

export enum DepositModalResult {
  notNeeded,
  zeroDeposit,
  someDeposit,
  cancelled
}

export enum PaymentCompleteStatus {
  complete,
  cancelled,
  completeZeroBalance
}

export enum TimeIn {
  timeIn1 = 'timeIn1',
  timeOut1 = 'timeOut1',
  timeIn2 = 'timeIn2',
  timeOut2 = 'timeOut2',
  timeIn3 = 'timeIn3',
  timeOut3 = 'timeOut3',
  timeIn4 = 'timeIn4',
  timeOut4 = 'timeOut4',
}

export enum Reason {
  reason1 = 'reason1',
  reason2 = 'reason2',
  reason3 = 'reason3',
  reason4 = 'reason4',
  none = ''
}

export enum ReceiptType {
  Receipt,
  OrderForm,
  DeliverySlip
}

export enum VoidedCompletedStatus {
  BLANK = '',
  VOID= 'void',
  COMPLETED = 'completed'
}
