import {ApplicationPage} from './common.enum';

export const Routes = {
  empty: '',
  wildCard: '**',
  developer: {
    self: 'developer'
  },
  setupRegister: {
    self: '-internal-/setup-register'
  },
  pos: {
    self: '-internal-/pos',
    queue: 'queue',
    order: {
      self: 'order',
      scan: 'scan',
      pickup: 'pickup',
      pickupQueue: 'pickup-queue',
    },
    viewClosing: 'v'
  },
  customer: {
    self: 'c',
    transaction: {
      self: 't'
    },
    queue: {
      self: 'q',
      create: 'qc'
    },
    location: {
      self: 'l'
    },
    feedback: {
      self: 'f'
    },
    getReceipt: {
      self: 'r'
    }
  },
  delivery: {
    self: 'd',
    transaction: {
      self: 't'
    }
  },
  dashboard: {
    self: 'dashboard',
  },
};

export const UrlTree = {
  developer: {
    self: ``,
  },
  setupRegister: {
    self: `/${Routes.setupRegister.self}`,
  },
  pos: {
    self: `/${Routes.pos.self}`,
    viewClosing: `/${Routes.pos.self}/${Routes.pos.viewClosing}`
  },
  customer: {
    self: `/${Routes.customer.self}`,
  },
  delivery: {
    self: `/${Routes.delivery.self}`,
  },
  dashboard: {
    self: `/${Routes.dashboard.self}`,
  },
};

export const ApplicationPageUrl = {
  [ApplicationPage.DEVELOPER]: UrlTree.developer.self,

  // Setup Register
  [ApplicationPage.SETUP_REGISTER]: UrlTree.setupRegister.self,

  // POS
  [ApplicationPage.POS]: UrlTree.pos.self,

  // Customer
  [ApplicationPage.CUSTOMER]: UrlTree.customer.self,

  // Delivery
  [ApplicationPage.DELIVERY]: UrlTree.delivery.self,

  // Dashboard
  [ApplicationPage.DASHBOARD]: UrlTree.dashboard.self,
};
