import * as moment from 'moment';
import {Customization, CustomizationStatus, CustomizationTypes, Tier} from 'src/app/core/api-models/customization';
import {Variation, VariationType} from 'src/app/core/api-models/product';
import {CurrentPayment, Transaction, TransactionStatus, TransactionType} from 'src/app/core/api-models/transaction';
import {TransactionItem, TransactionItemWithTotal} from 'src/app/core/api-models/transaction-item';
import {TransactionItemOption} from 'src/app/core/api-models/transaction-item-option';
import {TransactionPayment} from 'src/app/core/api-models/transaction.payment';
import {PaymentCode, TaxCode, TransactionPaymentStatus, VoidedCompletedStatus} from 'src/app/core/constants/common.enum';
import {UtilsService} from 'src/app/core/services/utils.service';
import {StoreReport} from '../../core/api-models/closing';

export class SaleService {
  constructor() {
  }

  static initialTransaction(): Transaction {
    return {
      tabId: '0',
      transactionId: 0,
      activeItem: 0,
      createTime: moment().format('YYYY-MM-DD hh:mm:ss'),
      storeNumber: 0,
      registerNumber: 0,
      transactionType: TransactionType.BLANK,
      subTotal: 0,
      discountValue: 0,
      discount: 0,
      discountReason: '',
      discountType: '',
      discountDetails: '',
      couponCode: '',
      tax: 0,
      amountDue: 0,
      amountPaid: 0,
      deposit: 0,
      balance: 0,
      itemsCount: 0,
      customer: null,
      orderId: null,
      rewardsPointsAwarded: 0,
      rewardsPointsUsed: 0,
      rewardsPointsDollars: 0,
      transactionStatus: TransactionStatus.IN_PROGRESS,
      zNumber: 0,
      employee: null,
      transactionItems: [],
      transactionPayments: [],
      currentPayment: SaleService.initialCurrentPayment(),
      rounding: 0,
      amountTendered: 0,
      change: 0,
      logs: [],
      order: null,
      products: null,
      productLoading: null,
      productError: '',
      updateCustomerError: '',
      updateCustomerLoading: null,
      isCustomerUpdated: null,
      isDelivery: null,
      hasPhotoCake: null,
      hasFondantCake: null,
      isTiered: null,
      randomId: '',
      changeTransaction: false,
      noEmail: false,
      noSms: false,
      duplicateCustomer: null,
      isGlutenFree: false,
      isVegan: false,
      itemCountDescription: ''
    };
  }

  static initialTransactionItem(): TransactionItem | TransactionItemWithTotal {
    return {
      uuid: UtilsService.uuid(),
      id: 0,
      transactionId: 0,
      lineId: 0,
      productId: 0,
      sku: '',
      shortName: '',
      longName: '',
      variantName: '',
      menuId: 0,
      menuButtonId: 0,
      itemQty: 0,
      unitPrice: 0,
      discountReason: '',
      discountType: '',
      discount: 0,
      discountDetails: '',
      itemTaxCode: TaxCode.E,
      status: '',
      itemDetails: '',
      transactionItemOption: [],
      smallImage: '',
      images: '',
      webUrl: '',
      inscription: '',
      productPrice: 0,
      discountValue: 0,
      productTaxCode: TaxCode.E,
      uploadedFiles: [],
      productConfig: null,
      posProductType: null,
      product: null
    };
  }

  static initialTransactionPayment(): TransactionPayment {
    return {
      txnId: 0,
      storeNumber: 0,
      paymentCode: PaymentCode.VISA,
      amountTendered: 0,
      rewardsPointsUsed: 0,
      paymentStatus: TransactionPaymentStatus.FULL,
      employee: null,
      paymentDate: '',
      status: VoidedCompletedStatus.BLANK
    };
  }

  static initialCurrentPayment(): CurrentPayment {
    return {
      previousAmountDue: 0,
      previousDeposit: 0,
      previousBalance: 0,
      previousRounding: 0,
      changeInAmountDue: 0,
      totalAmountDue: 0,
      balance: 0,
      deposit: 0,
      amountPaid: 0,
      amountTendered: 0,
      rounding: 0,
      change: 0
    };
  }

  static initialTransactionItemOption(): TransactionItemOption {
    return {
      id: 0,
      txnId: 0,
      storeNumber: 0,
      cakeDetails: '',
      transactionItemId: 0,
      type: '',
      inscription: '',
      notes: '',
      status: '',
    };
  }

  static initialCustomizationState(): Customization {
    return {
      inscriptionMessage: '',
      milestone: 0,
      gender: '',
      tags: [],
      textOption: '',
      textOn: '',
      decorationColors: [],
      writingColors: [],
      writingsOnTheCake: '',
      tiers: [],
      activeTierIndex: 0,
      coloredMasking: false,
      noMasking: false,
      chocolateMasking: false,
      vanillaMasking: false,
      biggerBoard: false,
      doubleHeight: false,
      tripleHeight: false,
      fondantCake: false,
      fondantDecorations: false,
      lessCream: false,
      steps: [],
      note: '',
      otherSelected: false,
      customizationStatus: CustomizationStatus.InProgress,
      edit: false,
      type: CustomizationTypes.Web
    };
  }

  static initialSpongeVariation(): Variation {
    return {
      name: '',
      description: '',
      imageCode: '',
      price: '',
      sortOrder: 0,
      status: '',
      variationType: VariationType.sponge,
      linkedId: 0,
      linkedVariations: []
    };
  }

  static initialSizeVariation(): Variation {
    return {
      name: '',
      description: '',
      imageCode: '',
      price: '',
      sortOrder: 0,
      status: '',
      variationType: VariationType.size,
      linkedId: 0,
      linkedVariations: []
    };
  }

  static initialFlavourVariation(): Variation {
    return {
      name: '',
      description: '',
      imageCode: '',
      price: '',
      sortOrder: 0,
      status: '',
      variationType: VariationType.flavour,
      linkedId: 0,
      linkedVariations: []
    };
  }

  static initialStoreReportState(): StoreReport {
    return {
      cashReport: {
        cashTotal: null,
        cashStart: 0,
        hundredCount: 0,
        fiftyCount: 0,
        twentyCount: 0,
        tenCount: 0,
        fiveCount: 0,
        twoCount: 0,
        oneCount: 0,
        quarterCount: 0,
        dimeCount: 0,
        nickelCount: 0,
        cashDifference: 0
      },
      creditReport: {
        creditTotal: null,
        creditDebit0: 0,
        creditDebit1: 0,
        creditDebit2: 0,
        debitDifference: 0,
        creditVisa1: 0,
        creditVisa0: 0,
        creditVisa2: 0,
        visaDifference: 0,
        creditMaster1: 0,
        creditMaster0: 0,
        creditMaster2: 0,
        masterDifference: 0,
        creditAmex1: 0,
        creditAmex0: 0,
        creditAmex2: 0,
        amexDifference: 0,
        creditGift: 0,
      },
      otherReport: {
        otherShopify: 0,
        otherPaypal: 0,
        otherInterac: 0,
        otherSquare: 0,
        otherTotal: null
      },
      grandTotal: 0,
      totalDifference: 0
    };
  }
}

export const initialTier: Tier = {
  size: SaleService.initialSpongeVariation(),
  sponge: SaleService.initialSizeVariation(),
  flavour: SaleService.initialFlavourVariation()
};
