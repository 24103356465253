import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Medium} from 'src/app/core/models/common.model';

@Component({
  selector: 'rb-employee-medium',
  templateUrl: './employee-medium.component.html',
  styleUrls: ['./employee-medium.component.scss']
})
export class EmployeeMediumComponent implements OnInit {
  mediumEnum = Medium;

  @Input() medium: Medium;
  @Output() updateMedium: EventEmitter<Medium> = new EventEmitter<Medium>();
  selectedMedium: Medium;

  constructor() {
  }

  ngOnInit() {
    this.selectedMedium = this.medium;
  }

  selectMedium(medium: Medium) {
    this.selectedMedium = medium;
    this.updateMedium.emit(medium);
  }
}
