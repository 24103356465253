import {Component, HostListener, OnDestroy, OnInit, Renderer2, RendererFactory2} from '@angular/core';
import {Constants} from 'src/app/core/constants/constants';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'rb-app-component',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2
  ) {
    if (this.isPOSUrl) {
      this.addPlaceJs();
    }
  }

  ngOnInit() {
  }

  private addPlaceJs() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyCZMWaTVM8EefYoM0x1HxWIrdCFLP9tcmA';
    this.renderer.appendChild(document.head, script);
  }

  @HostListener('window:beforeunload', ['$event'])
  windowBeforeUnload(event) {
    // Don't show this message for public urls
    if (this.isPOSUrl) {
      event.returnValue = this.isPOSUrl;
    }
  }

  get isPOSUrl(): boolean {
    if (window.location.href.indexOf(`/${Constants.routes.customer.self}/`) === -1) {
      return true;
    } else {
      return null;
    }
  }

  ngOnDestroy() {
  }
}
