import {BaseAction} from 'src/app/store/models/base-action';
import {PayloadAction} from 'src/app/store/models/payload-action';
import {CashReport, Closing, CreditReport, OtherReport, PosReport, StoreReport} from '../../core/api-models/closing';
import {ClosingActions, ClosingActionTypes} from './closing.action';
import {SaleService} from '../sale/sale.service';
import {cloneDeep as lCloneDeep} from 'lodash';
import {ClosingReportMode} from 'src/app/core/models/common.model';


export const ClosingStateKey = 'closing';

export interface ClosingState {
  closingPosReport: PosReport;
  closingPosReportLoading: boolean;
  closingPosReportSuccess: boolean;
  closingPosReportError: string;
  storeReport: StoreReport;
  reason: string;
  comment: string;
  submitClosingReportLoading: boolean;
  submitClosingReportSuccess: boolean;
  submitClosingReportError: string;
  isSubmitted: boolean;
  createdBy?: string;
  updatedBy?: string;
  reportMode: ClosingReportMode;
  createdTimeStamp?: string;
  updatedTimeStamp?: string;
}

const initialClosingState: ClosingState = {
  closingPosReport: null,
  closingPosReportLoading: false,
  closingPosReportSuccess: false,
  closingPosReportError: '',
  storeReport: SaleService.initialStoreReportState(),
  reason: null,
  comment: null,
  submitClosingReportLoading: false,
  submitClosingReportSuccess: false,
  submitClosingReportError: '',
  isSubmitted: false,
  createdBy: null,
  updatedBy: null,
  reportMode: null,
  createdTimeStamp: null,
  updatedTimeStamp: null
};

export function closingReducer(state: ClosingState = initialClosingState,
                               action: BaseAction | PayloadAction<any>): ClosingState {
  switch (action.type) {
    case ClosingActionTypes.LoadClosingPosInfo:
    case ClosingActionTypes.LoadExistingClosing: {
      return {
        ...state,
        closingPosReportLoading: true,
        closingPosReportSuccess: false,
        closingPosReportError: '',
      };
    }
    case ClosingActionTypes.LoadClosingInfoPosSuccess: {
      const posReport: PosReport = (action as ClosingActions.LoadClosingInfoPosSuccess).payload;
      return {
        ...state,
        closingPosReport: {
          ...posReport
        },
        closingPosReportLoading: false,
        closingPosReportSuccess: true,
        closingPosReportError: '',
      };
    }
    case ClosingActionTypes.LoadClosingInfoPosError: {
      const error: string = (action as ClosingActions.LoadClosingInfoPosError).payload;
      return {
        ...state,
        closingPosReportLoading: false,
        closingPosReportSuccess: false,
        closingPosReportError : error
      };
    }
  case ClosingActionTypes.LoadExistingClosingSuccess: {
    const closingReport: Closing = (action as ClosingActions.LoadExistingClosingSuccess).payload;
    return {
      ...state,
      closingPosReport: {
        ...lCloneDeep(closingReport.posReport)
      },
      storeReport: {
        ...lCloneDeep(closingReport.storeReport)
      },
      reason: closingReport.reason,
      comment: closingReport.comment,
      createdBy: closingReport.createdBy,
      updatedBy: closingReport.updatedBy,
      createdTimeStamp: closingReport.createdTimeStamp,
      updatedTimeStamp: closingReport.updatedTimeStamp,
      closingPosReportLoading: false,
      closingPosReportSuccess: true,
      closingPosReportError: '',
    };
  }
    case ClosingActionTypes.LoadExistingClosingError: {
      const error: string = (action as ClosingActions.LoadExistingClosingError).payload;
      return {
        ...state,
        closingPosReport: null,
        storeReport: SaleService.initialStoreReportState(),
        reason: null,
        comment: null,
        closingPosReportLoading: false,
        closingPosReportSuccess: false,
        closingPosReportError : error
      };
    }
    case ClosingActionTypes.UpdateCashReport: {
      const cashReportPartial: Partial<CashReport> = (action as ClosingActions.UpdateCashReport).payload;
      return {
        ...state,
        storeReport: {
          ...state.storeReport,
          cashReport: {
            ...state.storeReport.cashReport,
            ...cashReportPartial
          }
        }
      };
    }
    case ClosingActionTypes.UpdateCreditReport: {
      const creditReportPartial: Partial<CreditReport> = (action as ClosingActions.UpdateCreditReport).payload;
      return {
        ...state,
        storeReport: {
          ...state.storeReport,
          creditReport: {
            ...state.storeReport.creditReport,
            ...creditReportPartial
          }
        }
      };
    }
    case ClosingActionTypes.UpdateOtherReport: {
      const otherReportPartial: Partial<OtherReport> = (action as ClosingActions.UpdateOtherReport).payload;
      return {
        ...state,
        storeReport: {
          ...state.storeReport,
          otherReport: {
            ...state.storeReport.otherReport,
            ...otherReportPartial
          }
        }
      };
    }
    case ClosingActionTypes.UpdateClosing: {
      const closing: Partial<Closing> = (action as ClosingActions.UpdateClosing).payload;
      return {
        ...state,
        ...closing
      };
    }
    case ClosingActionTypes.CancelClosing: {
      return {
        ...state,
        closingPosReport: null,
        closingPosReportLoading: false,
        closingPosReportSuccess: false,
        closingPosReportError: '',
        storeReport: SaleService.initialStoreReportState(),
        reason: null,
        comment: null,
        submitClosingReportLoading: false,
        submitClosingReportSuccess: false,
        submitClosingReportError: '',
        isSubmitted: false
      };
    }
    case ClosingActionTypes.SubmitClosingReport: {
      return {
        ...state,
        submitClosingReportLoading: true,
        submitClosingReportSuccess: false,
        submitClosingReportError: '',
      };
    }
    case ClosingActionTypes.SubmitClosingReportSuccess: {
      const submitStatus: boolean = (action as ClosingActions.SubmitClosingReportSuccess).payload;
      return {
        ...state,
        submitClosingReportLoading: false,
        submitClosingReportSuccess: true,
        submitClosingReportError: '',
        isSubmitted: submitStatus
      };
    }
    case ClosingActionTypes.SubmitClosingReportError: {
      const error: string = (action as ClosingActions.SubmitClosingReportError).payload;
      return {
        ...state,
        submitClosingReportLoading: false,
        submitClosingReportSuccess: false,
        submitClosingReportError: error,
      };
    }
    case ClosingActionTypes.SetClosingReportMode: {
      const reportMode: ClosingReportMode = (action as ClosingActions.SetClosingReportMode).payload;
      return {
        ...state,
        reportMode
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
}


