import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Employee, NavigationTab, NavigationTabId, RequestTabData} from 'src/app/core/models/common.model';
import {UtilsService} from 'src/app/core/services/utils.service';
import {AppStore} from 'src/app/store/index.reducer';
import {NavigationActions} from 'src/app/store/navigation/navigation.actions';
import {NavigationSelectors} from 'src/app/store/navigation/navigation.selectors';
import {first, tap} from 'rxjs/operators';
import {TabAction} from 'src/app/store/models/tab-action';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  employee: Employee;
  tabs: NavigationTab[];
  activeTabId!: NavigationTabId;
  tabs$: Observable<NavigationTab[]> = this.store.select(NavigationSelectors.tabs);
  activeTabId$: Observable<NavigationTabId> = this.store.select(NavigationSelectors.activeTabId);
  activeTab$: Observable<NavigationTab> = this.store.select(NavigationSelectors.activeTab);
  currentEmployee$: Observable<Employee> = this.store.select(NavigationSelectors.currentEmployee);

  // browserTabId = UtilsService.randomString();
  // removeTabBroadcastChannel = new BroadcastChannel('remove_tabs');

  constructor(
    private store: Store<AppStore>
  ) {
    this.activeTabId$
      .subscribe(activeTabId => this.activeTabId = activeTabId);

    this.currentEmployee$
      .subscribe(currentEmployee => this.employee = currentEmployee);

    this.tabs$
      .subscribe((tabs: NavigationTab[]) => this.tabs = tabs);

    // this.removeTabBroadcastChannel.onmessage = (messageEvent: MessageEvent) => {
    //   console.log('Received message to remove tab', this.browserTabId, messageEvent);
    //   if (this.browserTabId !== messageEvent.data.browserTabId) {
    //     this.removeTab(messageEvent.data.tabId, messageEvent.data.browserTabId);
    //   }
    // };
  }

  get currentEmployee() {
    return this.employee;
  }

  get allTabs() {
    return this.tabs;
  }

  tab(tabId): NavigationTab {
    return this.tabs.find(tab => tab.id === tabId);
  }

  setActiveTab(tabId: NavigationTabId) {
    this.store.dispatch(new NavigationActions.SetActiveTab(tabId));
  }

  requestTab(data: RequestTabData) {
    this.store.dispatch(new NavigationActions.RequestTab(data));
  }

  removeCurrentTab() {
    this.activeTabId$.pipe(
      first()
    ).subscribe((activeTabId: NavigationTabId) => this.removeTab(activeTabId));
  }

  removeTab(tabId: NavigationTabId, browserTabId?: string) {
    this.store.dispatch(new NavigationActions.RemoveTab(tabId));
    // console.log('Sending message to remove tab', this.browserTabId, tabId);
    // if (!browserTabId) {
    //   this.removeTabBroadcastChannel.postMessage({browserTabId: this.browserTabId, tabId});
    // }
  }

  updateTabEmployee(employee: Employee) {
    this.activeTabId$.pipe(
      first()
    ).subscribe((activeTabId: NavigationTabId) => {
      this.store.dispatch(new NavigationActions.UpdateTabEmployee({id: activeTabId, employee}));
    });
  }

  dispatchTabAction<T>(func: (navigationTabId: NavigationTabId) => TabAction<T>) {
    this.store.dispatch(func(this.activeTabId));
  }

}
