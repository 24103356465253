import {Menu} from 'src/app/core/api-models/menu.model';
import {PhysicalStore, PosRole} from 'src/app/core/api-models/physical-store';
import {Register} from 'src/app/core/api-models/register';
import {Country, Employee, EmployeeSearchParam} from 'src/app/core/models/common.model';
import {MasterDataActions, MasterDataActionTypes} from 'src/app/store/master-data/master-data.actions';
import {BaseAction} from 'src/app/store/models/base-action';
import {PayloadAction} from 'src/app/store/models/payload-action';
import {SaleSegmentState} from 'src/app/store/sale/sale.reducer';

export const masterDataStateKey = 'masterData';

export const masterDataCacheKeys: (keyof MasterDataState)[] = [];

export interface MasterDataState {
  stores: PhysicalStore[];
  storesLoading: boolean;
  storesSuccess: boolean;
  storesError: boolean;

  tokenLoading: boolean;
  tokenSuccess: boolean;
  tokenError: boolean;

  register: Register;
  registerLoading: boolean;
  registerSuccess: boolean;
  registerError: string;

  menus: Menu[];
  menusLoading: boolean;
  menusSuccess: boolean;
  menusError: boolean;

  employees: Employee[];
  employeesLoading: boolean;
  employeesSuccess: boolean;
  employeesError: string;

  VerifyPasswordLoading: boolean;
  VerifyPasswordSuccess: boolean;
  VerifyPasswordError: string;

  countryCodes: Country[];
  countryCodesLoading: boolean;
  countryCodesSuccess: boolean;
  countryCodesError: string;

  createEmployeeLoading: boolean;
  createEmployeeError: string;

  updateEmployeeLoading: boolean;
  updateEmployeeError: string;
  searchTerm: EmployeeSearchParam;
}

const initialMasterDataState: MasterDataState = {
  stores: [],
  storesLoading: false,
  storesSuccess: false,
  storesError: false,

  tokenLoading: false,
  tokenSuccess: false,
  tokenError: false,

  register: {
    registerNumber: 0,
    storeNumber: 0,
    role: PosRole.None,
    appConfig: null
  },
  registerLoading: false,
  registerSuccess: false,
  registerError: '',

  menus: [],
  menusLoading: false,
  menusSuccess: false,
  menusError: false,

  employees: [],
  employeesLoading: false,
  employeesSuccess: false,
  employeesError: '',

  VerifyPasswordLoading: false,
  VerifyPasswordSuccess: false,
  VerifyPasswordError: '',

  countryCodes: [],
  countryCodesLoading: false,
  countryCodesSuccess: false,
  countryCodesError: '',

  createEmployeeLoading: false,
  createEmployeeError: '',

  updateEmployeeLoading: false,
  updateEmployeeError: '',
  searchTerm: {
    name: '',
    store: [],
    role: [],
    active: false
  }
};

export function masterDataReducer(state: MasterDataState = initialMasterDataState,
                                  action: BaseAction | PayloadAction<any>): MasterDataState {
  switch (action.type) {
    // Stores
    case MasterDataActionTypes.LoadStores: {
      return {
        ...state,
        storesLoading: true,
      };
    }
    case MasterDataActionTypes.LoadStoresSuccess: {
      return {
        ...state,
        storesLoading: false,
        storesSuccess: true,
        storesError: false,
        stores: (action as MasterDataActions.LoadStoresSuccess).payload
      };
    }
    case MasterDataActionTypes.LoadStoresError: {
      return {
        ...state,
        storesLoading: false,
        storesError: true,
        storesSuccess: false
      };
    }
    // Register
    case MasterDataActionTypes.SetupRegister: {
      return {
        ...state,
        registerLoading: true,
      };
    }
    case MasterDataActionTypes.SetupRegisterSuccess: {
      return {
        ...state,
        registerLoading: false,
        registerSuccess: true,
        registerError: '',
        register: (action as MasterDataActions.SetupRegisterSuccess).payload
      };
    }
    case MasterDataActionTypes.SetupRegisterError: {
      return {
        ...state,
        registerLoading: false,
        registerError: (action as MasterDataActions.SetupRegisterError).payload,
        registerSuccess: false,
      };
    }
    // Token into local storage, not into state
    case MasterDataActionTypes.StoreToken: {
      return {
        ...state,
      };
    }
    case MasterDataActionTypes.UpdateRefreshToken: {
      return {
        ...state,
        tokenLoading: true,
      };
    }
    case MasterDataActionTypes.RefreshTokenSuccess: {
      return {
        ...state,
        tokenLoading: false,
        tokenSuccess: true,
        tokenError: false,
      };
    }
    case MasterDataActionTypes.RefreshTokenError: {
      return {
        ...state,
        tokenLoading: false,
        tokenSuccess: false,
        tokenError: true,
      };
    }
    // Menus
    case MasterDataActionTypes.LoadMenus: {
      return {
        ...state,
        menusLoading: true,
      };
    }
    case MasterDataActionTypes.LoadMenusSuccess: {
      return {
        ...state,
        menusLoading: false,
        menusSuccess: true,
        menusError: false,
        menus: (action as MasterDataActions.LoadMenusSuccess).payload
      };
    }
    case MasterDataActionTypes.LoadMenusError: {
      return {
        ...state,
        menusLoading: false,
        menusSuccess: false,
        menusError: true
      };
    }
    // Employees
    case MasterDataActionTypes.LoadEmployees: {
      return {
        ...state,
        employeesLoading: true,
        employeesError: null
      };
    }
    case MasterDataActionTypes.LoadEmployeesSuccess: {
      return {
        ...state,
        employeesLoading: false,
        employeesSuccess: true,
        employeesError: '',
        employees: (action as MasterDataActions.LoadEmployeesSuccess).payload
      };
    }
    case MasterDataActionTypes.LoadEmployeesError: {
      const error: string = (action as MasterDataActions.LoadEmployeesError).payload;
      return {
        ...state,
        employeesLoading: false,
        employeesSuccess: false,
        employeesError: error
      };
    }
    // Verify Password
    case MasterDataActionTypes.VerifyPassword: {
      return {
        ...state,
        VerifyPasswordLoading: true,
        VerifyPasswordError: null
      };
    }
    case MasterDataActionTypes.VerifyPasswordSuccess: {
      return {
        ...state,
        VerifyPasswordLoading: false,
        VerifyPasswordSuccess: true,
        VerifyPasswordError: null,
      };
    }
    case MasterDataActionTypes.VerifyPasswordError: {
      return {
        ...state,
        VerifyPasswordLoading: false,
        VerifyPasswordSuccess: false,
        VerifyPasswordError: null
      };
    }
    // Country Codes
    case MasterDataActionTypes.LoadCountryCodes: {
      return {
        ...state,
        countryCodesLoading: true,
        countryCodesError: ''
      };
    }
    case MasterDataActionTypes.LoadCountryCodesSuccess: {
      return {
        ...state,
        countryCodesLoading: false,
        countryCodesSuccess: true,
        countryCodesError: '',
        countryCodes: (action as MasterDataActions.LoadCountryCodesSuccess).payload
      };
    }
    case MasterDataActionTypes.LoadCountryCodesError: {
      return {
        ...state,
        countryCodesLoading: false,
        countryCodesSuccess: false,
        countryCodesError: (action as MasterDataActions.LoadCountryCodesError).payload,
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
}

