import {CommonModule, TitleCasePipe} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CurrencyPipe} from '@angular/common';
import {MaskDirective, NgxMaskModule} from 'ngx-mask';
import {AlertBoxComponent} from 'src/app/common/components/alert-box/alert-box.component';
import {CakeAmountSummaryPrintComponent} from 'src/app/common/components/cake-amount-summary-print/cake-amount-summary-print.component';
import {CakeLocationComponent} from 'src/app/common/components/cake-location/cake-location.component';
import {OrderPrintCakeDetailsComponent} from 'src/app/common/components/order-print-cake-details/order-print-cake-details.component';

import {PageNotFoundComponent} from 'src/app/common/components/page-not-found/page-not-found.component';
import {ProductAutoCompleteComponent} from 'src/app/common/components/product-auto-complete/product-auto-complete.component';
import {TransactionItemsComponent} from 'src/app/common/components/transaction-items/transaction-items.component';
import {Time12Pipe} from 'src/app/common/pipes/time12.pipe';
import {CreditPaymentModalComponent} from 'src/app/common/popups/credit-payment-modal/credit-payment-modal.component';
import {DiscountModalComponent} from 'src/app/common/popups/discount-modal/discount-modal.component';
import {ItemPriceModalComponent} from 'src/app/common/popups/item-price-modal/item-price-modal.component';
import {PopupModalComponent} from 'src/app/common/popups/popup-modal/popup-modal.component';
import {LoadingErrorComponent} from 'src/app/common/directives/loader/components/loading-error/loading-error.component';
import {LoadingIndicatorComponent} from 'src/app/common/directives/loader/components/loading-indicator/loading-indicator.component';
import {LoaderDirective} from 'src/app/common/directives/loader/loader.directive';
import {
  UpdatePaymentMethodModalComponent
} from 'src/app/common/popups/select-order-modal/update-payment-method-modal/update-payment-method-modal.component';
import {UpdatePaymentAmountModalComponent} from 'src/app/common/popups/update-payment-amount-modal/update-payment-amount-modal.component';
import {InterpolatePipe} from './pipes/interpolate.pipe';
import {ButtonComponent} from './components/button/button.component';

import {DateSelectionComponent} from 'src/app/common/components/date-selection/date-selection.component';
import {EmployeeModalComponent} from 'src/app/common/popups/employee-modal/employee-modal.component';
import {EmployeeMediumComponent} from 'src/app/common/popups/employee-modal/employee-medium/employee-medium.component';
import {StoreButtonComponent} from './components/store-button/store-button.component';
import {RiskButtonComponent} from './components/risk-button/risk-button.component';
import {CounterComponent} from './components/counter/counter.component';
import {NgbDatepickerModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {InputWithNumberPadComponent} from './components/input-with-number-pad/input-with-number-pad.component';
import {CalendarComponent} from './components/calendar/calendar.component';
import {DepositModalComponent} from 'src/app/common/popups/deposit-modal/deposit-modal.component';
import {CashPaymentModalComponent} from './popups/cash-payment-modal/cash-payment-modal.component';
import {CashChangeModalComponent} from './popups/cash-change-modal/cash-change-modal.component';
import {NumberButtonsComponent} from './components/number-buttons/number-buttons.component';
import {TransactionComponent} from './components/transaction/transaction.component';
import {TransactionPaymentsComponent} from './components/transaction-payments/transaction-payments.component';
import {GetQuantityComponent} from './components/get-quantity/get-quantity.component';
import {PhonePipe} from './pipes/phone.pipe';
import {CustomizeModalComponent} from './popups/customize-modal/customize-modal.component';
import {CustomizeSummaryComponent} from './popups/customize-modal/customize-summary/customize-summary.component';
import {CustomizeInscriptionComponent} from './popups/customize-modal/customize-inscription/customize-inscription.component';
import {StepTrackerComponent} from './components/step-tracker/step-tracker.component';
import {InscriptionComponent} from './components/inscription/inscription.component';
import {DeliveryAddressModalComponent} from './popups/delivery-address-modal/delivery-address-modal.component';
import {RegisterSetupPageComponent} from './components/pages/register-setup-page/register-setup-page.component';
import {StatusButtonComponent} from './components/status-button/status-button.component';
import {LoaderModalComponent} from './popups/loader-modal/loader-modal.component';
import {CustomerModalComponent} from './popups/customer-modal/customer-modal.component';
import {CustomizeCakeComponent} from './popups/customize-modal/customize-cake/customize-cake.component';
import {
  CustomizeCakeSpongeComponent
} from './popups/customize-modal/customize-cake/customize-cake-sponge/customize-cake-sponge.component';
import {CustomizeCakeSizeComponent} from './popups/customize-modal/customize-cake/customize-cake-size/customize-cake-size.component';
import {
  CustomizeCakeFlavourComponent
} from './popups/customize-modal/customize-cake/customize-cake-flavour/customize-cake-flavour.component';
import {
  CustomizeCakeTierComponent
} from './popups/customize-modal/customize-cake-tier/customize-cake-tier.component';
import {StepTrackerFooterComponent} from './components/step-tracker-footer/step-tracker-footer.component';
import {PhoneNumberFieldComponent} from './components/phone-number-field/phone-number-field.component';
import {SelectSaleModalComponent} from './popups/select-sale-modal/select-sale-modal.component';
import {SelectOrderModalComponent} from './popups/select-order-modal/select-order-modal.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TierSelectModalComponent} from './popups/tier-select-modal/tier-select-modal.component';
import {FulfillmentMethodIconComponent} from './components/fulfillment-method-icon/fulfillment-method-icon.component';
import {OrderMediumIconComponent} from './components/order-medium-icon/order-medium-icon.component';
import {CakeNoteComponent} from './components/cake-note/cake-note.component';
import {SelectedPageHeaderComponent} from './popups/select-order-modal/selected-page-header/selected-page-header.component';
import {SelectedPageSideMenuComponent} from './popups/select-order-modal/selected-page-side-menu/selected-page-side-menu.component';
import {SelectedPageLogInfoComponent} from './popups/select-order-modal/selected-page-log-info/selected-page-log-info.component';
import {SelectedPagePaymentComponent} from './popups/select-order-modal/selected-page-payment/selected-page-payment.component';
import {SelectedPageGeneralComponent} from './popups/select-order-modal/selected-page-general/selected-page-general.component';
import {CustomerInfoComponent} from './components/customer-info/customer-info.component';
import {DeliveryInfoComponent} from './components/delivery-info/delivery-info.component';
import {OrderingInfoComponent} from './components/ordering-info/ordering-info.component';
import {CakeInscriptionComponent} from './components/cake-inscription/cake-inscription.component';
import {CakeAmountSummaryComponent} from './components/cake-amount-summary/cake-amount-summary.component';
import {OrderCakeDetailsComponent} from './components/order-cake-details/order-cake-details.component';
import {RbSelectTagPopoverComponent} from './components/rb-select-tag-popover/rb-select-tag-popover.component';
import {SelectStatusPopoverComponent} from './components/select-status-popover/select-status-popover.component';
import {
  SelectedPageCakeInscriptionComponent
} from './popups/select-order-modal/selected-page-cake-inscription/selected-page-cake-inscription.component';
import {SelectedSaleGeneralComponent} from './popups/select-sale-modal/selected-sale-general/selected-sale-general.component';
import {CompleteOrderComponent} from './popups/select-order-modal/complete-order/complete-order.component';
import {CreateEmployeeModalComponent} from './popups/create-employee-modal/create-employee-modal.component';
import {DateTodayTomorrowPipe} from './pipes/date-today-tomorrow.pipe';
import {PostalCodePipe} from './pipes/postal-code.pipe';
import {ChangeStoreModalComponent} from './popups/change-store-modal/change-store-modal.component';
import {CashCreditButtonsComponent} from './components/cash-credit-buttons/cash-credit-buttons.component';
import {CakeBoxesComponent} from './components/cake-boxes/cake-boxes.component';
import {OrderStatusButtonComponent} from './components/order-status-button/order-status-button.component';
import {CancelTransactionPaymentComponent} from 'src/app/common/components/cancel-order-payment/cancel-transaction-payment.component';
import {TimeinReportModalComponent} from 'src/app/common/popups/timein-report-modal/timein-report-modal.component';
import {SelectTimeSlotComponent} from 'src/app/common/components/select-time-slot/select-time-slot.component';
import {StoreNamePipe} from './pipes/store-name.pipe';
import {ChoseCustomerModalComponent} from './popups/chose-customer-modal/chose-customer-modal.component';
import {SafePipe} from './pipes/safe.pipe';
import {SaleSideMenuComponent} from './popups/select-sale-modal/sale-side-menu/sale-side-menu.component';
import {VoidSaleModalComponent} from './popups/select-sale-modal/void-sale-modal/void-sale-modal.component';
import {GooglePlacesComponent} from './components/google-places/google-places.component';
import {ReceiptNotificationModalComponent} from './popups/receipt-notification-modal/receipt-notification-modal.component';
import { CustomizationStepTrackerComponent } from './components/customization-step-tracker/customization-step-tracker.component';
import {
  CustomizationStepTrackerFooterComponent
} from './components/customization-step-tracker-footer/customization-step-tracker-footer.component';
import { ProductVariantModalComponent } from './popups/product-variant-modal/product-variant-modal.component';
import { SelectedPageTiersComponent } from './popups/select-order-modal/selected-page-tiers/selected-page-tiers.component';
import { TimeDigitPipe } from './pipes/time-digit.pipe';
import { DuplicateCustomerModalComponent } from './popups/duplicate-customer-modal/duplicate-customer-modal.component';
import { RelativeTimePipe } from 'src/app/common/pipes/relative-time.pipe';
import { RelativeTimeUpdatePipe } from './pipes/relative-time-update.pipe';
import { FirstNamePipe } from './pipes/first-name.pipe';
import { FirstNameOverflowPipe } from './pipes/first-name-overflow.pipe';
import { PosQueueCreateModalComponent } from 'src/app/common/popups/pos-queue-create-modal/pos-queue-create-modal.component';
import { PosSingleQueueComponent } from 'src/app/common/components/pos-single-queue/pos-single-queue.component';
import { PosSpotPhoneComponent } from './components/pos-single-queue/pos-spot-phone/pos-spot-phone.component';
import { MaskedNamePipe } from './pipes/masked-name.pipe';
import { OrderLocationComponent } from './components/order-location/order-location.component';
import { FixSpongeSizeFlavourPipe } from './pipes/fix-sponge-size-flavour.pipe';
import { TransactionNumberSplitPipe } from 'src/app/common/pipes/transaction-number-split.pipe';
import { LocationPipe } from './pipes/location.pipe';
import { DaysFromTodayPipe } from './pipes/days-from-today.pipe';
import { SelectClosingReportModalComponent } from './popups/select-closing-report-modal/select-closing-report-modal.component';
import { EmployeeTimeLogComponent } from './popups/employee-time-log/employee-time-log.component';
import {NgxJsonViewerModule} from 'ngx-json-viewer';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        CurrencyPipe,
        PhonePipe,
        MaskDirective,
        Time12Pipe,
        TimeDigitPipe,
        TitleCasePipe
    ],
    declarations: [
        PageNotFoundComponent,
        PopupModalComponent,
        InterpolatePipe,
        LoadingErrorComponent,
        LoadingIndicatorComponent,
        AlertBoxComponent,
        LoaderDirective,
        ButtonComponent,
        DateSelectionComponent,
        EmployeeModalComponent,
        EmployeeMediumComponent,
        StoreButtonComponent,
        RiskButtonComponent,
        CounterComponent,
        InputWithNumberPadComponent,
        CalendarComponent,
        DepositModalComponent,
        CashPaymentModalComponent,
        CashChangeModalComponent,
        CreditPaymentModalComponent,
        NumberButtonsComponent,
        DiscountModalComponent,
        ItemPriceModalComponent,
        ProductAutoCompleteComponent,
        TransactionItemsComponent,
        TransactionComponent,
        TransactionPaymentsComponent,
        GetQuantityComponent,
        PhonePipe,
        CustomizeModalComponent,
        CustomizeSummaryComponent,
        CustomizeInscriptionComponent,
        StepTrackerComponent,
        InscriptionComponent,
        DeliveryAddressModalComponent,
        RegisterSetupPageComponent,
        StatusButtonComponent,
        LoaderModalComponent,
        CustomerModalComponent,
        CustomizeCakeComponent,
        CustomizeCakeSpongeComponent,
        CustomizeCakeSizeComponent,
        CustomizeCakeFlavourComponent,
        CustomizeCakeTierComponent,
        StepTrackerFooterComponent,
        PhoneNumberFieldComponent,
        SelectSaleModalComponent,
        SelectOrderModalComponent,
        TierSelectModalComponent,
        FulfillmentMethodIconComponent,
        OrderMediumIconComponent,
        CakeNoteComponent,
        SelectedPageHeaderComponent,
        SelectedPageSideMenuComponent,
        SelectedPageLogInfoComponent,
        SelectedPagePaymentComponent,
        SelectedPageGeneralComponent,
        CustomerInfoComponent,
        DeliveryInfoComponent,
        OrderingInfoComponent,
        CakeInscriptionComponent,
        CakeAmountSummaryComponent,
        OrderCakeDetailsComponent,
        CakeAmountSummaryPrintComponent,
        RbSelectTagPopoverComponent,
        SelectStatusPopoverComponent,
        SelectedPageCakeInscriptionComponent,
        SelectedSaleGeneralComponent,
        CompleteOrderComponent,
        CreateEmployeeModalComponent,
        DateTodayTomorrowPipe,
        PostalCodePipe,
        ChangeStoreModalComponent,
        CashCreditButtonsComponent,
        CakeLocationComponent,
        CakeBoxesComponent,
        OrderStatusButtonComponent,
        CancelTransactionPaymentComponent,
        TimeinReportModalComponent,
        SelectTimeSlotComponent,
        StoreNamePipe,
        ChoseCustomerModalComponent,
        SafePipe,
        SaleSideMenuComponent,
        VoidSaleModalComponent,
        UpdatePaymentMethodModalComponent,
        GooglePlacesComponent,
        ReceiptNotificationModalComponent,
        Time12Pipe,
        CustomizationStepTrackerComponent,
        CustomizationStepTrackerFooterComponent,
        SelectedPageTiersComponent,
        CustomizationStepTrackerFooterComponent,
        ProductVariantModalComponent,
        TimeDigitPipe,
        DuplicateCustomerModalComponent,
        UpdatePaymentAmountModalComponent,
        OrderPrintCakeDetailsComponent,
        RelativeTimePipe,
        RelativeTimeUpdatePipe,
        FirstNamePipe,
        FirstNameOverflowPipe,
        PosQueueCreateModalComponent,
        PosSingleQueueComponent,
        PosSpotPhoneComponent,
        MaskedNamePipe,
        OrderLocationComponent,
        FixSpongeSizeFlavourPipe,
        TransactionNumberSplitPipe,
        LocationPipe,
    DaysFromTodayPipe,
    SelectClosingReportModalComponent,
    EmployeeTimeLogComponent,],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbDatepickerModule,
    NgxMaskModule.forChild(),
    NgbModule,
    DragDropModule,
    NgxJsonViewerModule
  ],
    exports: [
        PageNotFoundComponent,
        PopupModalComponent,
        InterpolatePipe,
        AlertBoxComponent,
        LoaderDirective,
        ButtonComponent,
        DateSelectionComponent,
        EmployeeModalComponent,
        StoreButtonComponent,
        RiskButtonComponent,
        CounterComponent,
        InputWithNumberPadComponent,
        CalendarComponent,
        CashPaymentModalComponent,
        CreditPaymentModalComponent,
        DepositModalComponent,
        CashChangeModalComponent,
        DiscountModalComponent,
        ItemPriceModalComponent,
        ProductAutoCompleteComponent,
        TransactionItemsComponent,
        TransactionComponent,
        GetQuantityComponent,
        PhonePipe,
        StepTrackerComponent,
        StatusButtonComponent,
        StepTrackerFooterComponent,
        FulfillmentMethodIconComponent,
        OrderMediumIconComponent,
        CustomerInfoComponent,
        DeliveryInfoComponent,
        OrderingInfoComponent,
        OrderCakeDetailsComponent,
        OrderPrintCakeDetailsComponent,
        CakeInscriptionComponent,
        CakeAmountSummaryComponent,
        CakeAmountSummaryPrintComponent,
        RbSelectTagPopoverComponent,
        SelectStatusPopoverComponent,
        CustomizeCakeTierComponent,
        DateTodayTomorrowPipe,
        CakeLocationComponent,
        CakeBoxesComponent,
        OrderStatusButtonComponent,
        SelectTimeSlotComponent,
        StoreNamePipe,
        PostalCodePipe,
        SafePipe,
        GooglePlacesComponent,
        Time12Pipe,
        TimeDigitPipe,
        RelativeTimePipe,
        RelativeTimeUpdatePipe,
        FirstNamePipe,
        FirstNameOverflowPipe,
        PosQueueCreateModalComponent,
        PosSingleQueueComponent,
        MaskedNamePipe,
        OrderLocationComponent,
        FixSpongeSizeFlavourPipe,
        TransactionNumberSplitPipe,
        LocationPipe,
        LoadingErrorComponent,
        LoadingIndicatorComponent,
        InputWithNumberPadComponent,

    ]
})
export class RbCommonModule {
}
