<div class="header">SELECT MEDIUM</div>
<div class="section__medium__btn-group">
  <button class="rb-btn-secondary-purple btn-with-icon"
          [class.selected]="selectedMedium === mediumEnum.store"
          (click)="selectMedium(mediumEnum.store)">
    <span class="-mr8 -pt4"><i class="rb-icon-store"></i></span>
    <span>Store</span>
  </button>
  <button class="rb-btn-secondary-blue btn-with-icon"
          [class.selected]="selectedMedium === mediumEnum.phone"
          (click)="selectMedium(mediumEnum.phone)">
    <span class="-mr8 -pt4"><i class="rb-icon-phone"></i></span>
    <span>Phone</span>
  </button>
  <button class="rb-btn-secondary-cyan btn-with-icon"
          [class.selected]="selectedMedium === mediumEnum.web"
          (click)="selectMedium(mediumEnum.web)">
    <span class="-mr8 -pt4"><i class="rb-icon-web"></i></span>
    <span>Web</span>
  </button>
  <button class="rb-btn-secondary-teal btn-with-icon"
          [class.selected]="selectedMedium === mediumEnum.email"
          (click)="selectMedium(mediumEnum.email)">
    <span class="-mr8 -pt4"><i class="rb-icon-mail"></i></span>
    <span>Email</span>
  </button>
</div>
