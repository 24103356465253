import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import * as LogRocket from 'logrocket';
import { SentryService } from 'src/app/core/services/sentry.service';

@Injectable({
  providedIn: 'root'
})
export class LogrocketService {

  constructor(
    private sentry: SentryService
  ) {
  }

  init() {
    // if (environment.production) {
    if (false) {
      LogRocket.init(environment.logrocketApiKey);
      LogRocket.getSessionURL(sessionURL => {
        this.sentry.configureScope(scope => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });
    }
  }

  handleError(error: any) {
    LogRocket.captureException(error);
  }

  identify(storeName: string, registerNumber: number) {
    LogRocket.identify(`${storeName}-${registerNumber}`);
    LogRocket.warn();
  }

  captureMessage(message) {
    LogRocket.captureMessage(message);
  }
}
