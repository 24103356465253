<div class="-mt40 -mb16 text-center">
  <img src="assets/icons/button/rb-logo.svg">
</div>
<div class="login-section">
  <form [formGroup]="formGroup">
    <div>
      <div class="-mb32 heading-text">
        Try to login automatically by pressing POS. If that doesn't work then enter id and password.
      </div>
      <div class="-mb16">
        <input type="text"
               placeholder="Enter Id"
               formControlName="id"
               (change)="submitted = false; mismatchError = false;">
      </div>
      <div *ngIf="submitted && formGroup.get('id').hasError('required')">
        <div class="alert alert-dark">Id is required.</div>
      </div>
      <div class="-mb24">
        <input type="password"
               placeholder="Enter Password"
               formControlName="password"
               (change)="submitted = false; mismatchError = false;">
      </div>
      <div *ngIf="submitted && formGroup.get('password').hasError('required')">
        <div class="alert alert-dark">Password is required.</div>
      </div>
      <div *ngIf="mismatchError">
        <div class="alert alert-dark">Id and Password don't match, please try again!</div>
      </div>
      <div class="login-btn">
        <button type="button" class="rb-btn-secondary-gray -mr24" (click)="goToPos()">POS</button>
        <button type="button" class="rb-btn-primary-pink" (click)="setup()">Setup Register</button>
      </div>
    </div>
  </form>
</div>
