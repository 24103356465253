import {Transaction} from 'src/app/core/api-models/transaction';
import {DeliveryTransactionParams} from 'src/app/core/models/common.model';
import {PayloadAction} from 'src/app/store/models/payload-action';

export enum DeliveryActionTypes {
  LoadTransaction = '[Transaction] Load Delivery Transaction',
  LoadTransactionSuccess = '[Transaction] Load Delivery Transaction Success',
  LoadTransactionError = '[Transaction] Load Delivery Transaction Error',
}

// tslint:disable-next-line:no-namespace
export namespace DeliveryActions {
  export class LoadTransaction extends PayloadAction<DeliveryTransactionParams> {
    readonly type = DeliveryActionTypes.LoadTransaction;
  }

  export class LoadTransactionSuccess extends PayloadAction<Transaction> {
    readonly type = DeliveryActionTypes.LoadTransactionSuccess;
  }

  export class LoadTransactionError extends PayloadAction<string> {
    readonly type = DeliveryActionTypes.LoadTransactionError;
  }
}
