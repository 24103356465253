import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'time12'
})
export class Time12Pipe implements PipeTransform {

  transform(time12: string): string {
    const today = moment().format('M/D/YYYY');
    return moment(`${today} ${time12}`).format('h:mm A');
  }
}
