import {PayloadAction} from 'src/app/store/models/payload-action';
import {
  Employee,
  EmployeeSearchParam,
  EmployeeTimeInOut,
  EmployeeTimeInSearchParam
} from 'src/app/core/models/common.model';
import {BaseAction} from 'src/app/store/models/base-action';

export enum EmployeeActionTypes {
  EmployeeTimeIn = '[Employee] Load Employee Time In',
  LoadEmployeesTimeInSuccess = '[Employee] Load Employees Time In Success',
  LoadEmployeesTimeInError = '[Employee] Load Employee Time In Error',
  CreateEmployeeTimeIn = '[Employee] Create Employee TimeIn',
  CreateEmployeesTimeInSuccess = '[Employee] Create Employees Time In Success',
  CreateEmployeesTimeInError = '[Employee] Create Employee Time In Error',
  UpdateEmployeeTimeIn = '[Employee] Update Employee TimeIn',
  UpdateEmployeesTimeInSuccess = '[Employee] Update Employees Time In Success',
  UpdateEmployeesTimeInError = '[Employee] Update Employee Time In Error',
  SearchEmployeesTimeIn = '[Employee] Search Employee TimeIn',
  SearchEmployeesTimeInSuccess = '[Employee] Search Employees Time In Success',
  SearchEmployeesTimeInError = '[Employee] Search Employee Time In Error',
  DeleteEmployeesTimeIn = '[Employee] Delete Employee TimeIn',
  DeleteEmployeesTimeInSuccess = '[Employee] Delete Employee TimeIn success',

  SearchEmployee= '[Employee] Search Employees',
  SearchEmployeeSuccess = '[Employee] Search Employees Success',
  SearchEmployeeError = '[Employee] Search Employees Error',

  CreateEmployee = '[Employee] Create Employees',
  CreateEmployeeSuccess = '[Employee] Create Employees Success',
  CreateEmployeeError = '[Employee] Create Employees Error',

  UpdateEmployee= '[Employee] Update Employees',
  UpdateEmployeeSuccess = '[Employee] Update Employees Success',
  UpdateEmployeeError = '[Employee] Update Employees Error',
}

// tslint:disable-next-line:no-namespace
export namespace EmployeeActions {

  export class LoadEmployeesTimeInSuccess extends PayloadAction<EmployeeTimeInOut[]> {
    readonly type = EmployeeActionTypes.LoadEmployeesTimeInSuccess;
  }

  export class LoadEmployeesTimeInError extends PayloadAction<string> {
    readonly type = EmployeeActionTypes.LoadEmployeesTimeInError;
  }

  export class EmployeeTimeIn extends BaseAction {
    readonly type = EmployeeActionTypes.EmployeeTimeIn;
  }

  export class CreateEmployeeTimeIn extends PayloadAction<EmployeeTimeInOut> {
    readonly type = EmployeeActionTypes.CreateEmployeeTimeIn;
  }

  export class CreateEmployeesTimeInSuccess extends PayloadAction<EmployeeTimeInOut> {
    readonly type = EmployeeActionTypes.CreateEmployeesTimeInSuccess;
  }

  export class CreateEmployeesTimeInError extends PayloadAction<string> {
    readonly type = EmployeeActionTypes.CreateEmployeesTimeInError;
  }
  export class UpdateEmployeeTimeIn extends PayloadAction<EmployeeTimeInOut> {
    readonly type = EmployeeActionTypes.UpdateEmployeeTimeIn;
  }

  export class UpdateEmployeesTimeInSuccess extends PayloadAction<EmployeeTimeInOut> {
    readonly type = EmployeeActionTypes.UpdateEmployeesTimeInSuccess;
  }

  export class UpdateEmployeesTimeInError extends PayloadAction<string> {
    readonly type = EmployeeActionTypes.UpdateEmployeesTimeInError;
  }

  export class SearchEmployeesTimeIn extends PayloadAction<Partial<EmployeeTimeInSearchParam>> {
    readonly type = EmployeeActionTypes.SearchEmployeesTimeIn;
  }

  export class SearchEmployeesTimeInSuccess extends PayloadAction<EmployeeTimeInOut[]> {
    readonly type = EmployeeActionTypes.SearchEmployeesTimeInSuccess;
  }

  export class SearchEmployeesTimeInError extends PayloadAction<string> {
    readonly type = EmployeeActionTypes.SearchEmployeesTimeInError;
  }

  export class DeleteEmployeesTimeIn extends PayloadAction<number> {
    readonly type = EmployeeActionTypes.DeleteEmployeesTimeIn;
  }

  export class DeleteEmployeesTimeInSuccess extends PayloadAction<number> {
    readonly type = EmployeeActionTypes.DeleteEmployeesTimeInSuccess;
  }

  export class SearchEmployee extends PayloadAction<Partial<EmployeeSearchParam>> {
    readonly type = EmployeeActionTypes.SearchEmployee;
  }

  export class SearchEmployeeSuccess extends PayloadAction<Employee[]> {
    readonly type = EmployeeActionTypes.SearchEmployeeSuccess;
  }

  export class SearchEmployeeError extends PayloadAction<string> {
    readonly type = EmployeeActionTypes.SearchEmployeeError;
  }

  export class CreateEmployee extends PayloadAction<Partial<Employee>> {
    readonly type = EmployeeActionTypes.CreateEmployee;
  }

  export class CreateEmployeeSuccess extends PayloadAction<Employee> {
    readonly type = EmployeeActionTypes.CreateEmployeeSuccess;
  }

  export class CreateEmployeeError extends PayloadAction<string> {
    readonly type = EmployeeActionTypes.CreateEmployeeError;
  }

  export class UpdateEmployee extends PayloadAction<Partial<Employee>> {
    readonly type = EmployeeActionTypes.UpdateEmployee;
  }

  export class UpdateEmployeeSuccess extends PayloadAction<Employee> {
    readonly type = EmployeeActionTypes.UpdateEmployeeSuccess;
  }

  export class UpdateEmployeeError extends PayloadAction<string> {
    readonly type = EmployeeActionTypes.UpdateEmployeeError;
  }

}
