
import {MasterDataState} from 'src/app/store/master-data/master-data.reducer';
import {BaseAction} from 'src/app/store/models/base-action';
import {PayloadAction} from 'src/app/store/models/payload-action';
import {Customer} from 'src/app/core/api-models/order';
import {CustomerActions, CustomerActionTypes} from 'src/app/store/customer/customer.actions';

export const CustomerStateKey = 'customer';

export const customerCacheKey: (keyof CustomerState)[] = [];

export interface CustomerState {
  customers: Customer[];
  customerLoading: boolean;
  customerSuccess: boolean;
  customerError: boolean;
  addressLoading: boolean;
  addressUpdateSuccess: boolean;
  addressUpdateError: boolean;
}

const initialCustomerState: CustomerState = {
  customers : [],
  customerLoading: false,
  customerSuccess: false,
  customerError: false,
  addressLoading: false,
  addressUpdateSuccess: false,
  addressUpdateError: false,
};

export function customerReducer(state: CustomerState = initialCustomerState,
                                action: BaseAction | PayloadAction<any>): CustomerState {
  switch (action.type) {
    case CustomerActionTypes.LoadCustomer: {
      return {
        ...state
      };
    }
    case CustomerActionTypes.LoadCustomerSuccess: {
      return {
        ...state,
        customerLoading: false,
        customerSuccess: true,
        customers: (action as CustomerActions.LoadCustomerSuccess).payload
      };
    }
    case CustomerActionTypes.LoadCustomerError: {
      return {
        ...state,
        customerLoading: false,
        customerError: true
      };
    }
    case CustomerActionTypes.AddNewAddress: {
      return {
        ...state
      };
    }
    case CustomerActionTypes.UpdateAddress: {
      return {
        ...state
      };
    }
    case CustomerActionTypes.UpdateAddressError: {
      return {
        ...state,
        addressLoading: false,
        addressUpdateError: true
      };
    }
    case CustomerActionTypes.UpdateAddressSuccess: {
      return {
        ...state,
        addressLoading: false,
        addressUpdateSuccess: true
      };
    }
    case CustomerActionTypes.DeleteAddress: {
      return {
        ...state
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
}

