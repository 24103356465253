export enum PosRole {
  None = '',
  CashRegister = 'cash-register',
  WebStore = 'web-store',
  PhoneOrders = 'phone-orders',
  BakingStation = 'baking-station',
  DecoratingStation = 'decorating-station',
  PickupStation = 'pickup-station',
  Dashboard = 'dashboard',
  Admin = 'admin',
}

export interface PosConfig {
  permissions?: {
    sale?: boolean;
    order?: boolean;
    searchSale?: boolean;
    searchOrder?: boolean;
    closing?: boolean;
    payInOut?: boolean;
    timeIn?: boolean;
    timeAdjustment?: boolean;
    delivery?: boolean;
    pickup?: boolean;
    discount?: boolean;
    void?: boolean;
    refund?: boolean;
    cashPayments?: boolean;
    creditPayments?: boolean;
    selectEmployee?: boolean;
    employeeReport?: boolean;
    orderForm?: boolean;
  };
  showStore?: {
    order?: boolean;
    fulfillment?: boolean;
    notifications?: boolean;
    status?: boolean;
  };
  admin?: {
    showAdminButton: boolean;
  };
}

export const posConfig: { [key: string]: PosConfig } = {
    [PosRole.CashRegister]: {
      permissions: {
        sale: true,
        order: true,
        searchSale: true,
        searchOrder: true,
        closing: true,
        payInOut: true,
        timeIn: true,
        timeAdjustment: true,
        delivery: true,
        discount: true,
        void: true,
        refund: true,
        cashPayments: true,
        creditPayments: true,
        selectEmployee: true,
        employeeReport: true,
        orderForm: true,
      },
      showStore: {
        order: true,
        fulfillment: true,
        notifications: true,
        status: true
      }
    },
    [PosRole.WebStore]: {
      showStore: {
        order: true,
      }
    },
    [PosRole.Dashboard]: {
      admin: {
        showAdminButton: true,
      }
    },
    [PosRole.DecoratingStation]: {
      permissions: {
        searchOrder: true,
      }
    },
    [PosRole.PickupStation]: {
      permissions: {
        searchOrder: true,
        pickup: true,
      }
    },
  }
;

export interface PhysicalStore {
  storeNumber: number;
  storeName: string;
  posType: PosRole;
  address1: string;
  address2: string;
  city: string;
  postalCode: string;
  country: string;
  phoneNumber: string;
  openingBalance: number;
  mondayOpenHours: string;
  mondayCloseHours: string;
  tuesdayOpenHours: string;
  tuesdayCloseHours: string;
  wednesdayOpenHours: string;
  wednesdayCloseHours: string;
  thursdayOpenHours: string;
  thursdayCloseHours: string;
  fridayOpenHours: string;
  fridayCloseHours: string;
  saturdayOpenHours: string;
  saturdayCloseHours: string;
  sundayOpenHours: string;
  sundayCloseHours: string;
  specialDayHours: Days;
  holidays: Holiday[];
}

export interface Days {
  days: SpecialDay[];
}

export interface SpecialDay {
  date: string;
  openHours: string;
  closeHours: string;
  name?: string;
}

export interface Holiday {
  date: string;
  name: string;
}

export interface StoreHours {
  date: string;
  openHours: string;
  closeHours: string;
  storeClosed?: boolean;
  name?: string;
}

export interface StoreInfo {
  storeNumber: number;
  city: string;
}
