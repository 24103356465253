import {Log} from 'src/app/core/api-models/logs';
import {LogActionTypes} from 'src/app/store/log/log.actions';
import {BaseAction} from 'src/app/store/models/base-action';
import {PayloadAction} from 'src/app/store/models/payload-action';

export const LogStateKey = 'log';

export const logCacheKeys = [];

export interface LogState {
  logs: Log[];
}

const initialLogState: LogState = {
  logs: []
};

export function logReducer(state: LogState = initialLogState,
                           action: BaseAction | PayloadAction<any>): LogState {
  switch (action.type) {
    // Log
    case LogActionTypes.SaveLogToServer: {
      return {
        ...state
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
}

