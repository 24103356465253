export enum ProductType {
  Delivery = 'delivery',
  CustomCake = 'custom-cake',
  ReadyCake = 'ready-to-go-cake',
  CupCake = 'cupcake',
  ReadyToGo = 'ready-to-go',
  CakeTopper = 'cake-topper',
  Inactive = 'inactive'
}

export interface ProductConfig {
  inscription: boolean;
  customization: boolean;
  multipleButton: boolean;
  promptForPrice: boolean;
}

export const productConfig: {[key: string]: ProductConfig} = {
  [ProductType.Delivery]: {
    inscription: true,
    customization: false,
    multipleButton: false,
    promptForPrice: true
  },
  [ProductType.CustomCake]: {
    inscription: false,
    customization: true,
    multipleButton: false,
    promptForPrice: true
  },
  [ProductType.ReadyCake]: {
    inscription: true,
    customization: false,
    multipleButton: false,
    promptForPrice: false
  },
  [ProductType.CupCake]: {
    inscription: false,
    customization: true,
    multipleButton: true,
    promptForPrice: false
  },
  [ProductType.ReadyToGo]: {
    inscription: true,
    customization: false,
    multipleButton: true,
    promptForPrice: false
  },
  [ProductType.CakeTopper]: {
    inscription: true,
    customization: false,
    multipleButton: false,
    promptForPrice: true
  },
};

