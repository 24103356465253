import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {from, Observable} from 'rxjs';
import {Product} from 'src/app/core/api-models/menu.model';
import {FulfillmentMethod} from 'src/app/core/api-models/order';
import {Transaction} from 'src/app/core/api-models/transaction';
import {OrderStatusEnum} from 'src/app/core/constants/common.enum';
import {Constants} from 'src/app/core/constants/constants';
import {productConfig} from 'src/app/core/constants/product-config.constants';
import {PhysicalStoreService} from 'src/app/store/master-data/physical-store.service';
import {v4 as uuidv4} from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private router: Router,
    private physicalStore: PhysicalStoreService,
  ) {
  }

  public static roundToCents(amount: number): number {
    return +(Math.round(amount * 100).toFixed(0)) / 100;
  }

  // Temporarily disabling rounding
  public static roundToNickle(amount: number): number {
    return +(Math.round(amount * 100).toFixed(0)) / 100;
  }

  // public static roundToNickle(amount: number): number {
  //   return +(Math.round(amount * 100).toFixed(0)) / 100;
  // }

  public static formatCurrency(amount: number): string {
    return `$${(+(Math.round(amount * 100).toFixed(0)) / 100).toFixed(2).toString()}`;
  }

  public static convertToCurrency(amount: number | string): string {
    if (typeof amount === 'string') {
      return `$${amount.replace('$', '')}`;
    } else {
      return amount ? `$${amount.toFixed(2)}` : '';
    }
  }

  public static TitleCase(sentence: string): string {
    if (!sentence) {
      return '';
    }

    return sentence.toLowerCase().split(' ').map((word) => {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  public static uuid(): string {
    return uuidv4();
  }

  public static randomString(): string {
    // tslint:disable-next-line:no-bitwise
    return (Math.random() * 0xFFFFFF << 0).toString(16);
  }

  public static currentDate(): string {
    return moment().format('YYYY-MM-DD HH:mm:ss SSS');
  }

  public static currentDateWithMilliseconds(): string {
    return moment().format('YYYY-MM-DD HH:mm:ss.SSS');
  }

  public static formatDate(date): string {
    return moment(date).format('LLLL');
  }

  public static stringToDate(stringDate: string): Date {
    const dateArray = stringDate.split('-');
    return new Date(+dateArray[0], +dateArray[1] - 1, +dateArray[2]);
  }

  public static stringsToDateTime(stringDate: string, stringTime: string): Date {
    const dateArray = stringDate.split('-');
    const timeArray = stringTime.split(':');
    return new Date(+dateArray[0], +dateArray[1] - 1, +dateArray[2], +timeArray[0], +timeArray[1], +timeArray[2]);
  }

  public static arrayIntersection<T>(array1: T[], array2: T[]): Array<T> {
    return array1.filter((x) => {
      return (array2.indexOf(x) !== -1);
    });
  }

  public static enumToArray(enumme: any): Array<any> {
    return Object.values(enumme);
  }

  public static transactionNumberSplit(transactionNumber: string | number): string {
    const transactionId = (transactionNumber || '').toString();
    const prefix = transactionId.substr(0, transactionId.length - 3);
    const suffix = transactionId.substr(transactionId.length - 3, 20);
    if (transactionId.length >= 5) {
      return `${prefix}-${suffix}`;
    }
    return transactionId;
  }

  public static shortMomentFromNow(text: string): string {
    return text
      .replace('a few seconds', '')
      .replace('seconds', 's')
      .replace('a minute', ' 1 m')
      .replace('minutes', 'm')
      .replace('an hour', '1 h')
      .replace('hours', 'h')
      .replace('a day', '1 d')
      .replace('days', 'd');
  }

  public static updateProduct(product: Product): Product {
    const images = JSON.parse(product.images);

    if (images[0] && images[0].src?.indexOf('.jpg') >= 0) {
      product.smallImage = images[0].src.replace('.jpg', '_large.jpg');
    } else if (images[0] && images[0].src?.indexOf('.jpeg') >= 0) {
      product.smallImage = images[0].src.replace('.jpeg', '_large.jpeg');
    } else {
      product.smallImage = images[0]?.src || '';
    }

    product.productConfig = productConfig[product.posProductType];

    return product;
  }

  public static isFulfillmentToday(fulfillmentDate: string): boolean {
    const today = new Date();

    return +fulfillmentDate?.substr(0, 4) === today.getFullYear() &&
      +fulfillmentDate?.substr(5, 2) === (today.getMonth() + 1) &&
      +fulfillmentDate?.substr(8, 2) === today.getDate();
  }

  public static daysFromToday(date: Date | string): number {
    const today = new Date();
    if (typeof date === 'string') {
      date = new Date(date);
    } else if (!date) {
      date = new Date();
    }
    // Add 5 hours to GMT time to get to our timezone, otherwise it shows a date earlier than actual date
    const mDate = moment([date.getFullYear(), date.getMonth(), date.getDate()]);
    const mToday = moment([today.getFullYear(), today.getMonth(), today.getDate()]);
    return mDate.diff(mToday, 'days') + 1;
  }

  public allowReminder(transaction: Transaction, allowDuplicate: boolean = false): boolean {
    if (!allowDuplicate && transaction?.order?.orderStatus === OrderStatusEnum.REMINDER_SENT) {
      return false;
    }

    const storeOpenHours = this.physicalStore.getCurrentStoreHours().openHours;
    const openHour = +storeOpenHours.substring(0, storeOpenHours.indexOf(':'));
    const currentHour = (new Date()).getHours();

    // console.log('IsFullfillmentToday:', UtilsService.isFulfillmentToday(transaction?.order?.fulfillmentDate),
    //   'Fulfillment store:', transaction?.order?.fulfillmentStoreNumber === this.physicalStore?.currentStore?.storeNumber,
    //   'Delivery:', transaction?.order?.fulfillmentMethod !== FulfillmentMethod.delivery,
    //   'Open hours:', currentHour >= openHour,
    //   'Completed:', transaction?.order?.orderStatus !== OrderStatusEnum.COMPLETED,
    //   'Cancelled:', transaction?.order?.orderStatus !== OrderStatusEnum.CANCELLED,
    //   'Hold:', transaction?.order?.orderStatus !== OrderStatusEnum.HOLD,
    //   'Delivery started:', transaction?.order?.orderStatus !== OrderStatusEnum.DELIVERY_STARTED);

    return UtilsService.isFulfillmentToday(transaction?.order?.fulfillmentDate) &&
      transaction?.order?.fulfillmentStoreNumber === this.physicalStore?.currentStore?.storeNumber &&
      transaction?.order?.fulfillmentMethod !== FulfillmentMethod.delivery &&
      currentHour >= openHour &&
      transaction?.order?.orderStatus !== OrderStatusEnum.COMPLETED &&
      transaction?.order?.orderStatus !== OrderStatusEnum.CANCELLED &&
      transaction?.order?.orderStatus !== OrderStatusEnum.HOLD &&
      transaction?.order?.orderStatus !== OrderStatusEnum.DELIVERY_STARTED;
  }

  public redirectToSetupRegister(referrer = ''): Observable<boolean> {
    if (referrer) {
      return from(this.router.navigate([Constants.routes.setupRegister.self], {state: {referrer}}));
    }
    return from(this.router.navigate([Constants.routes.setupRegister.self]));
  }

  public redirectToPos(): Observable<boolean> {
    return from(this.router.navigate([Constants.routes.pos.self]));
  }

  public redirectToQueueCreate(store?: string): Observable<boolean> {
    const url = `${Constants.routes.customer.self}/${Constants.routes.customer.queue.create}`;
    if (store) {
      return from(this.router.navigate([url], {queryParams: {s: store}}));
    } else {
      return from(this.router.navigate([url]));
    }
  }

  public redirectToQueue(token?: string, message?: boolean): Observable<boolean> {
    let url = `${Constants.routes.customer.self}/${Constants.routes.customer.queue.self}`;

    if (token) {
      url = `${url}/${token}`;
    }

    if (message) {
      return from(this.router.navigate([url], {queryParams: {m: '1'}}));
    } else {
      return from(this.router.navigate([url]));
    }
  }

  public openCustomerReceiptTab(transactionId: number, shortCode: string, print?: boolean) {
    let url = `${Constants.routes.customer.self}/${Constants.routes.customer.transaction.self}/${transactionId}/${shortCode}`;
    if (print) {
      url = `${url}?print=1`;
    }
    this.openLinkInTab(url);
  }

  private openLinkInTab(url: string) {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = url;
    link.setAttribute('visibility', 'hidden');
    link.click();
  }

  getToday(): NgbDateStruct {
    const today = new Date();
    const date = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    return this.dateToNgbStruct(date);
  }

  getTomorrow(): NgbDateStruct {
    const time: moment.Moment = moment().add(1, 'days');
    const date = new Date(time.year(), time.month(), time.date());
    return this.dateToNgbStruct(date);
  }

  getYesterday(): NgbDateStruct {
    const time: moment.Moment = moment().subtract(1, 'days');
    const date = new Date(time.year(), time.month(), time.date());
    return this.dateToNgbStruct(date);
  }

  getTwoDays(): NgbDateStruct {
    const time: moment.Moment = moment().add(2, 'days');
    const date = new Date(time.year(), time.month(), time.date());
    return this.dateToNgbStruct(date);
  }

  getThisWeek(): { from: NgbDateStruct, to: NgbDateStruct } {
    const weekStart = moment().startOf('isoWeek');
    const weekEnd = moment().endOf('isoWeek');
    const dateFrom = new Date(weekStart.year(), weekStart.month(), weekStart.date());
    const dateTo = new Date(weekEnd.year(), weekEnd.month(), weekEnd.date());
    return {from: this.dateToNgbStruct(dateFrom), to: this.dateToNgbStruct(dateTo)};
  }

  getNextWeek(): { from: NgbDateStruct, to: NgbDateStruct } {
    const weekStart = moment().add(1, 'weeks').startOf('isoWeek');
    const weekEnd = moment().add(1, 'weeks').endOf('isoWeek');
    const dateFrom = new Date(weekStart.year(), weekStart.month(), weekStart.date());
    const dateTo = new Date(weekEnd.year(), weekEnd.month(), weekEnd.date());
    return {from: this.dateToNgbStruct(dateFrom), to: this.dateToNgbStruct(dateTo)};
  }

  getLastWeek(): { from: NgbDateStruct, to: NgbDateStruct } {
    const weekStart = moment().subtract(1, 'weeks').startOf('isoWeek');
    const weekEnd = moment().subtract(1, 'weeks').endOf('isoWeek');
    const dateFrom = new Date(weekStart.year(), weekStart.month(), weekStart.date());
    const dateTo = new Date(weekEnd.year(), weekEnd.month(), weekEnd.date());
    return {from: this.dateToNgbStruct(dateFrom), to: this.dateToNgbStruct(dateTo)};
  }

  getTwoYearsDate(): NgbDateStruct {
    const time: moment.Moment = moment().add(2, 'years');
    const date = new Date(time.year(), time.month(), time.date());
    return this.dateToNgbStruct(date);
  }

  getPreviousMonthDate(): NgbDateStruct {
    const time: moment.Moment = moment().subtract(1, 'months');
    const date = new Date(time.year(), time.month(), time.date());
    return this.dateToNgbStruct(date);
  }

  getPreviousYearDate(): NgbDateStruct {
    const time: moment.Moment = moment().subtract(1, 'years');
    const date = new Date(time.year(), time.month(), time.date());
    return this.dateToNgbStruct(date);
  }

  dateToNgbStruct(date: Date): NgbDateStruct {
    // getMonth is index of 0
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }

  ngbStructToDate(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  stringToNgbStruct(date: string): NgbDateStruct {
    const array = date.split('-');
    return {
      year: parseInt(array[0], 0),
      month: parseInt(array[1], 0),
      day: parseInt(array[2], 0)
    };
  }

  compareDates(date1: NgbDateStruct, date2: NgbDateStruct): boolean {
    if (date1 && date2) {
      return date1.year === date2.year && date1.month === date2.month && date1.day === date2.day;
    }
    return false;
  }

  toProperCase(name): string {
    return name.toLowerCase().replace(/^(.)|\s(.)/g,
      (lower) => lower.toUpperCase());
  }

  adjustTime(date) {
    if (date.getMinutes() <= 30) {
      return moment(date).minute(30);
    } else {
      return moment(date).minute(60);
    }
  }

  get12Hr(timeString) {
    if (timeString === null || timeString === '') {
      return '';
    } else {
      const timeString12hr = new Date('1970-01-01T' + timeString + 'Z')
        .toLocaleTimeString('en-US',
          {timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric'}
        );
      return timeString12hr;
    }
  }

  get24Hr(timeString) {
    const PM = timeString.match('PM') ? true : false;
    timeString = timeString.split(':');
    const min = timeString[1];
    let hour;

    if (PM) {
      hour = 12 + parseInt(timeString[0], 10);
      hour = hour === 24 ? '12' : hour;
    } else {
      hour = timeString[0];
      hour = hour < 10 ? '0' + hour :
        (hour === '12' ? '00' : hour);
    }
    return hour + ':' + min + ':' + '00';
  }

}
