import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {HttpClientWrapper} from 'src/app/core/api/http-wrapper.service';
import {AppStore} from 'src/app/store/index.reducer';
import {catchError, first, flatMap, map, tap} from 'rxjs/operators';
import {Constants} from 'src/app/core/constants/constants';
import {Observable, of} from 'rxjs';
import {NavigationService} from 'src/app/core/services/navigation.service';
import {EmployeeActionTypes, EmployeeActions} from 'src/app/store/employee/employee.actions';
import {Employee, EmployeeTimeInOut} from 'src/app/core/models/common.model';
import {HttpParams} from '@angular/common/http';
import * as moment from 'moment';

@Injectable()
export class EmployeeEffects {

  constructor(private actions$: Actions,
              private store: Store<AppStore>,
              private navigation: NavigationService,
              private httpClientWrapper: HttpClientWrapper
              ) {
  }

  @Effect()
  employeeTimeIn = this.actions$.pipe(
    ofType(EmployeeActionTypes.EmployeeTimeIn),
    flatMap((action: EmployeeActions.EmployeeTimeIn) => {
      return this.getEmployeesTimeIn().pipe(
        first(),
        map((data: EmployeeTimeInOut[]) =>
          new EmployeeActions.LoadEmployeesTimeInSuccess(data)),
        catchError((error: string) =>
          of(new EmployeeActions.LoadEmployeesTimeInError(error))
        )
      );
    }));

  @Effect()
  createEmployeeTimeIn = this.actions$.pipe(
    ofType(EmployeeActionTypes.CreateEmployeeTimeIn),
    flatMap((action: EmployeeActions.CreateEmployeeTimeIn) => {
      return this.createEmployeesTimeIn(action.payload).pipe(
        first(),
        tap((data: EmployeeTimeInOut) =>
          this.store.dispatch(
            new EmployeeActions.CreateEmployeesTimeInSuccess(data))),
        map(() => new EmployeeActions.EmployeeTimeIn()),
        catchError((error: string) =>
          of(new EmployeeActions.CreateEmployeesTimeInError( error)))
      );
    }));

  @Effect()
  updateEmployeeTimeIn = this.actions$.pipe(
    ofType(EmployeeActionTypes.UpdateEmployeeTimeIn),
    flatMap((action: EmployeeActions.UpdateEmployeeTimeIn) => {
      return this.updateEmployeesTimeIn(action.payload).pipe(
        first(),
        tap((data: EmployeeTimeInOut) =>
          this.store.dispatch(
            new EmployeeActions.UpdateEmployeesTimeInSuccess(data))),
        map(() => new EmployeeActions.EmployeeTimeIn()),
        catchError((error: string) =>
          of(new EmployeeActions.UpdateEmployeesTimeInError(error)))
      );
    }));

  @Effect()
  searchEmployeesTimeIn = this.actions$.pipe(
    ofType(EmployeeActionTypes.SearchEmployeesTimeIn),
    flatMap((action: EmployeeActions.SearchEmployeesTimeIn) => {
      return this.searchEmployeeTimeIn(action.payload).pipe(
        first(),
        map((data: EmployeeTimeInOut[]) =>
          new EmployeeActions.SearchEmployeesTimeInSuccess(data)
        ),
        catchError((error: string) =>
          of(new EmployeeActions.SearchEmployeesTimeInError(error)))
      );
    }));

  @Effect()
  deleteEmployeesTimeIn = this.actions$.pipe(
    ofType(EmployeeActionTypes.DeleteEmployeesTimeIn),
    flatMap((action: EmployeeActions.DeleteEmployeesTimeIn) => {
      const deletedId = action.payload;
      return this.deleteEmployeeTimeIn(action.payload).pipe(
        first(),
        map((data: string) =>
          new EmployeeActions.DeleteEmployeesTimeInSuccess(deletedId)
        ),
        catchError((error: string) =>
          of(new EmployeeActions.LoadEmployeesTimeInError(error)))
      );
    }));

  @Effect()
  searchEmployee = this.actions$.pipe(
    ofType(EmployeeActionTypes.SearchEmployee),
    flatMap((action: EmployeeActions.SearchEmployee) => {
      const deletedId = action.payload;
      return this.searchEmployees(action.payload).pipe(
        first(),
        map((data: Employee[]) =>
          new EmployeeActions.SearchEmployeeSuccess(data)
        ),
        catchError((error: string) =>
          of(new EmployeeActions.SearchEmployeeError(error)))
      );
    }));

  @Effect()
  createEmployee = this.actions$.pipe(
    ofType(EmployeeActionTypes.CreateEmployee),
    flatMap((action: EmployeeActions.CreateEmployee) => {
      return this.getCreatedEmployee(action.payload).pipe(
        first(),
        map((data: Employee) =>
          new EmployeeActions.CreateEmployeeSuccess(data)
        ),
        catchError((error: string) =>
          of(new EmployeeActions.CreateEmployeeError(error)))
      );
    }));

  @Effect()
  updateEmployee = this.actions$.pipe(
    ofType(EmployeeActionTypes.UpdateEmployee),
    flatMap((action: EmployeeActions.UpdateEmployee) => {
      return this.updateEmployeeById(action.payload).pipe(
        first(),
        map((data: Employee) =>
          new EmployeeActions.UpdateEmployeeSuccess(data)
        ),
        catchError((error: string) =>
          of(new EmployeeActions.UpdateEmployeeError(error)))
      );
    }));

  searchEmployees(searchParam): Observable<Employee[]> {
    return this.httpClientWrapper.get<Employee[]>(Constants.apiPaths.getEmployees, searchParam);
  }

  updateEmployeeById(employee: Partial<Employee>): Observable<Employee> {
    return this.httpClientWrapper.put(employee.id.toString(), `${Constants.apiPaths.updateEmployees}/${employee.id}`, employee);
  }

  private getCreatedEmployee(newEmp: Partial<Employee>): Observable<Employee> {
    return this.httpClientWrapper.post(newEmp.id.toString(), Constants.apiPaths.getEmployees, newEmp);
  }

  searchEmployeeTimeIn(searchParam): Observable<EmployeeTimeInOut[]> {
    let params: HttpParams = new HttpParams();
    if (searchParam?.dateRange?.from) {
      params = params.append('from', moment(searchParam?.dateRange?.from).format('YYYY-MM-DD'));
    }

    if (searchParam?.dateRange?.to) {
      params = params.append('to', moment(searchParam?.dateRange?.to).format('YYYY-MM-DD 23:59:59'));
    } else if (searchParam?.dateRange?.from) {
      params = params.append('to', moment(searchParam?.dateRange?.from).format('YYYY-MM-DD 23:59:59'));
    }
    if (searchParam?.store) {
      params = params.append('store', searchParam.store);
    }
    if (searchParam?.name) {
      params = params.append('name', searchParam.name);
    }
    return this.httpClientWrapper.get<EmployeeTimeInOut[]>(Constants.apiPaths.employeeTimeIn, params);
  }

  getEmployeesTimeIn(): Observable<EmployeeTimeInOut[]> {
    let params: HttpParams = new HttpParams();
    const dateTo = moment(new Date().toString()).format('YYYY-MM-DD 23:59:59');
    const dateFrom = moment(new Date().toString()).format('YYYY-MM-DD');
    params = params.append('to', dateTo);
    params = params.append('from', dateFrom);
    return this.httpClientWrapper.get<EmployeeTimeInOut[]>(Constants.apiPaths.employeeTimeIn, params);
  }

  createEmployeesTimeIn(timeIn: EmployeeTimeInOut): Observable<EmployeeTimeInOut> {
    const employee = (this.navigation?.currentEmployee?.id || timeIn.employeeId).toString();
    const empArrayData = [timeIn];
    return this.httpClientWrapper.post(employee, `${Constants.apiPaths.employeeTimeIn}`, empArrayData);
  }

  updateEmployeesTimeIn(timeIn: Partial<EmployeeTimeInOut>): Observable<EmployeeTimeInOut> {
    const employee = (this.navigation?.currentEmployee?.id || timeIn.employeeId).toString();
    const empArrayData = [timeIn];
    return this.httpClientWrapper.put(employee, `${Constants.apiPaths.employeeTimeIn}`, empArrayData);
  }

  deleteEmployeeTimeIn(param: number) {
    return this.httpClientWrapper.delete(param.toString(), `${Constants.apiPaths.employeeTimeIn}/${param}`);
  }
 }

