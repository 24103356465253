import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {flatMap} from 'rxjs/operators';
import {Log} from 'src/app/core/api-models/logs';
import {HttpClientWrapper} from 'src/app/core/api/http-wrapper.service';
import {Constants} from 'src/app/core/constants/constants';
import {LogActionTypes, LogActions} from 'src/app/store/log/log.actions';

@Injectable()
export class LogEffects {

  constructor(private actions$: Actions,
              private httpClientWrapper: HttpClientWrapper) {
  }

  @Effect({dispatch: false})
  saveLogToServer = this.actions$.pipe(
    ofType(LogActionTypes.SaveLogToServer),
    flatMap((action: LogActions.SaveLogToServer) =>
      this.saveLog(action.payload)
    ));

  saveLog(logs: Log[]): Observable<{ status: string }> {
    const employeeName = logs[0] && logs[0].employeeName;
    return this.httpClientWrapper.post<Log[], { status: string }>(employeeName,
      (Constants.apiPaths.saveLogs), logs);
  }

}
